import { useMutation } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { useToast } from "@/components/ui/use-toast";
import { auth } from "@/lib/firebase/app";

import { useApi } from "../../../hooks/useApi";

export const useDeleteAccount = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  const api = useApi();

  return useMutation({
    mutationFn: async () => {
      await api.account.delete();
      await signOut(auth);
    },
    onSuccess: () => {
      toast({
        title: "Account deleted",
        description: "Your account was deleted successfully",
      });
      navigate("/");
    },
  });
};
