import { DetailedHTMLProps, HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export const CenterPage = ({
  children,
  className,
  isScreenHeight = false,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  isScreenHeight?: boolean;
}) => (
  <div
    className={cn("flex h-auto flex-grow items-center justify-center p-4", isScreenHeight ? "min-h-screen" : "h-full")}
    {...props}
  >
    <div className={cn("w-full max-w-md px-4 text-center", className)}>{children}</div>
  </div>
);
