import { OauthIntegration, OauthServiceType } from "@fyxer-ai/shared";
import { ReactElement } from "react";

import gmailLogo from "@/assets/company-logos/gmail.png";
import googleCalendarLogo from "@/assets/company-logos/google-calendar.png";
import hubspotLogo from "@/assets/company-logos/hubspot.png";
import outlookCalendarLogo from "@/assets/company-logos/outlook.png";
import outlookEmailLogo from "@/assets/company-logos/outlook.png";
import slackLogo from "@/assets/company-logos/slack.png";

export type IntegrationDatum = {
  title: string;
  logoUrl: string;
  integration: OauthIntegration;
  serviceType: OauthServiceType;
  disclosure?: () => ReactElement;
};

export const GoogleDisclosure = () => (
  <p className="text-xs text-slate-400">
    Fyxer's use and transfer of information received from Google's APIs to other apps required to operate our service
    will adhere to the{" "}
    <a
      href="https://developers.google.com/terms/api-services-user-data-policy"
      target="_blank"
      className="font-semibold hover:text-slate-500"
    >
      Google API Services User Data Policy
    </a>
    , including the Limited Use requirements.
  </p>
);

export const integrationData: IntegrationDatum[] = [
  {
    integration: OauthIntegration.GMAIL,
    serviceType: OauthServiceType.EMAIL,
    logoUrl: gmailLogo,
    title: "Gmail",
    disclosure: GoogleDisclosure,
  },
  {
    integration: OauthIntegration.GOOGLE_CALENDAR,
    serviceType: OauthServiceType.CALENDAR,
    logoUrl: googleCalendarLogo,
    title: "Google",
    disclosure: GoogleDisclosure,
  },
  {
    integration: OauthIntegration.MICROSOFT_OUTLOOK_EMAIL,
    serviceType: OauthServiceType.EMAIL,
    logoUrl: outlookEmailLogo,
    title: "Outlook",
  },
  {
    integration: OauthIntegration.MICROSOFT_OUTLOOK_CALENDAR,
    serviceType: OauthServiceType.CALENDAR,
    logoUrl: outlookCalendarLogo,
    title: "Outlook",
  },
  {
    integration: OauthIntegration.SLACK,
    serviceType: OauthServiceType.INSTANT_MESSAGING,
    logoUrl: slackLogo,
    title: "Slack",
  },
  {
    integration: OauthIntegration.HUBSPOT,
    serviceType: OauthServiceType.CRM,
    logoUrl: hubspotLogo,
    title: "HubSpot",
  },
];
