import {
  CalendarEvent,
  CalendarResponseStatus,
  getAttendeeCount,
  getIsUrlAMeetingUrl,
  groupBy,
  unzip,
} from "@fyxer-ai/shared";
import { format, formatISO, parseISO, startOfDay } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { QueryDocumentSnapshot, updateDoc } from "firebase/firestore";
import { useState } from "react";

import { Switch } from "@/components/ui/switch";
import { useCalendarEvents } from "@/context/CalendarEventsContext/state/useCalendarEvents";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { unwrap } from "@/lib/firebase/unwrap";

export type TimeInterval = { startsAt: Date; endsAt: Date };

export const formatTimeInterval = (timeInterval: TimeInterval, timeZone: string, includeDate = true) => {
  const { startsAt: startsAtUtc, endsAt: endsAtUtc } = timeInterval;
  const startsAtLocal = toZonedTime(startsAtUtc, timeZone);
  const endsAtLocal = toZonedTime(endsAtUtc, timeZone);
  const formattedTime = `${format(startsAtLocal, "HH:mm")} - ${format(endsAtLocal, "HH:mm")}`;
  return includeDate ? `${format(startsAtLocal, "do MMM yyyy")} ${formattedTime}` : formattedTime;
};

enum IneligibleMeetingReason {
  NO_MEETING_LINK = "NO_MEETING_LINK",
  UNSUPPORTED_MEETING_LINK = "UNSUPPORTED_MEETING_LINK",
  NOT_ENOUGH_ATTENDEES = "NOT_ENOUGH_ATTENDEES",
  NOT_ACCEPTED = "NOT_ACCEPTED",
}

const getCalendarEventBotEligibility = ({
  calendarEvent,
  calendarEmail,
}: {
  calendarEvent: CalendarEvent;
  calendarEmail: string;
}) => {
  const { meetingUrl, attendees, organiserEmail } = calendarEvent;
  if (!meetingUrl) return { isEligible: false, reason: IneligibleMeetingReason.NO_MEETING_LINK } as const;
  if (!getIsUrlAMeetingUrl(meetingUrl))
    return { isEligible: false, reason: IneligibleMeetingReason.UNSUPPORTED_MEETING_LINK } as const;
  const attendeeCount = getAttendeeCount(calendarEvent);
  if (attendeeCount < 2) return { isEligible: false, reason: IneligibleMeetingReason.NOT_ENOUGH_ATTENDEES } as const;
  const responseStatus = attendees?.find((attendee) => attendee.email === calendarEmail)?.responseStatus;
  const isOrganiser = organiserEmail && organiserEmail.toLowerCase() === calendarEmail.toLowerCase();
  if (responseStatus !== CalendarResponseStatus.ACCEPTED && !isOrganiser)
    return { isEligible: false, reason: IneligibleMeetingReason.NOT_ACCEPTED } as const;

  return { isEligible: true } as const;
};

const getExplanationForIneligibleMeetingReason = (reason: IneligibleMeetingReason) => {
  switch (reason) {
    case IneligibleMeetingReason.NO_MEETING_LINK:
      return "No meeting link";
    case IneligibleMeetingReason.UNSUPPORTED_MEETING_LINK:
      return "Unsupported meeting link";
    case IneligibleMeetingReason.NOT_ENOUGH_ATTENDEES:
      return "Only one attendee";
    case IneligibleMeetingReason.NOT_ACCEPTED:
      return "Meeting not accepted";
  }
};

const UpcomingCalendarEventRow = ({
  calendarEventDoc,
  timeZone,
  calendarEmail,
}: {
  calendarEventDoc: QueryDocumentSnapshot<CalendarEvent>;
  timeZone: string;
  calendarEmail: string;
}) => {
  const calendarEventId = calendarEventDoc.id;
  const calendarEvent = calendarEventDoc.data();
  const { title, isBotInvited } = calendarEvent;
  const formattedTime = formatTimeInterval(calendarEvent, timeZone, false);
  const [isSwitchOn, setIsSwitchOn] = useState<boolean>(isBotInvited);
  const { isEligible, reason } = getCalendarEventBotEligibility({ calendarEvent, calendarEmail });
  return (
    <div
      key={calendarEventId}
      className="rounded-2xl border border-slate-500 bg-slate-50 p-4 max-sm:space-y-2 sm:flex sm:items-center sm:gap-x-4"
    >
      <div className="space-y-1">
        <h4>{title}</h4>
        <p className="text-sm text-slate-500">{formattedTime}</p>
      </div>
      <div className="flex-grow max-sm:hidden"></div>
      <div className="flex items-center gap-x-4">
        {isEligible ? (
          <Switch
            checked={isSwitchOn}
            onCheckedChange={async (checked) => {
              setIsSwitchOn(checked);
              await updateDoc(calendarEventDoc.ref, { isBotInvited: checked });
            }}
          />
        ) : (
          <>
            <p className="text-xs font-bold">{getExplanationForIneligibleMeetingReason(reason)}</p>
            <Switch checked={false} disabled />
          </>
        )}
      </div>
    </div>
  );
};

export const UpcomingCallsTab = () => {
  const { calendarEvents } = useCalendarEvents();
  const { timeZone, calendarConnections } = useOrganisation();
  const calendarConnectionIdToEmailMap = calendarConnections.reduce<Record<string, string | undefined>>(
    (acc, connection) => {
      const connectionId = connection.id;
      const email = connection.data().metadata.email as string | undefined;
      return { ...acc, [connectionId]: email };
    },
    {},
  );

  const undeclinedCalendarEvents = calendarEvents.filter(
    unwrap((calendarEvent) => {
      const { connectionId, attendees } = calendarEvent;
      const calendarEmail = calendarConnectionIdToEmailMap[connectionId];
      if (!calendarEmail) return false;
      const wasDeclined =
        attendees?.find((attendee) => attendee.email === calendarEmail)?.responseStatus ===
        CalendarResponseStatus.DECLINED;

      return !wasDeclined;
    }),
  );

  const groupsOfCalendarEventsByDay = unzip(
    groupBy(
      undeclinedCalendarEvents,
      unwrap((calendarEvent) => formatISO(startOfDay(toZonedTime(calendarEvent.startsAt, timeZone)))),
    ),
  );

  return (
    <div className="space-y-8">
      {groupsOfCalendarEventsByDay.length === 0 ? (
        <p>No events yet</p>
      ) : (
        groupsOfCalendarEventsByDay.map(({ key: startOfDayIsoString, value: calendarEvents }) => {
          const startOfDayLocal = parseISO(startOfDayIsoString);
          const formattedStartOfDay = format(startOfDayLocal, "EEEE, d MMMM");

          return (
            <div className="space-y-4" key={startOfDayIsoString}>
              <p className="box-border px-2 text-sm text-slate-500">{formattedStartOfDay}</p>
              <div className="space-y-2">
                {calendarEvents.map((calendarEventDoc) => {
                  const { connectionId } = calendarEventDoc.data();
                  const calendarEmail = calendarConnectionIdToEmailMap[connectionId];

                  if (!calendarEmail) return null;

                  return (
                    <UpcomingCalendarEventRow
                      key={calendarEventDoc.id}
                      calendarEventDoc={calendarEventDoc}
                      timeZone={timeZone}
                      calendarEmail={calendarEmail}
                    />
                  );
                })}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
