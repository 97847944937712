import { EventPage, useLogPageView } from "@/hooks/useAnalytics";
import { useQueryParams } from "@/hooks/useQueryParams";

import { AuthPageShell } from "./AuthPageShell";
import { AuthType } from "./types/AuthType";

export const LogInPage = () => {
  const { return_path } = useQueryParams();
  const returnPath = return_path ?? "/";
  useLogPageView(EventPage.LOG_IN, { returnPath });

  return <AuthPageShell authType={AuthType.LOG_IN} />;
};
