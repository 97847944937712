import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { Collection } from "@/lib/firebase/Collection";
import { useMutation } from "@tanstack/react-query";
import { doc, updateDoc } from "firebase/firestore";
import { useEffect } from "react";

export const StopMeetingFailureNotificationsPage = () => {
  const { userMembership } = useOrganisation();
  const userMembershipId = userMembership?.id;
  if (!userMembershipId) throw new Error("Can't find your membership to this organisation");

  const stopSendingFailureEmails = useMutation({
    mutationKey: ["stopSendingFailureEmails", userMembershipId],
    mutationFn: async (userMembershipId: string) =>
      updateDoc(doc(Collection.Membership, userMembershipId), { shouldSendMeetingRecordingFailureEmails: false }),
  });

  useEffect(() => {
    stopSendingFailureEmails.mutate(userMembershipId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userMembershipId]);

  if (stopSendingFailureEmails.error) return <p>{stopSendingFailureEmails.error.message}</p>;
  if (stopSendingFailureEmails.isSuccess) return <p>Unsubscribed from meeting recording failure emails</p>;

  return <LoadingDashboard />;
};
