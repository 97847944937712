import { removeEmptyProperties } from "@fyxer-ai/shared";
import { useEffect } from "react";

import { LocalStorageStringKey, storage } from "@/lib/storage";

import { useQueryParams } from "./useQueryParams";

export const useUtmParams = () => {
  const { utm_source, utm_medium, utm_campaign, utm_term, utm_content } = useQueryParams();
  const utmParams = removeEmptyProperties({ utm_source, utm_medium, utm_campaign, utm_term, utm_content });
  return utmParams;
};

export const useSaveUtmParametersIfPresent = () => {
  const utmParams = useUtmParams();
  const utmParamsString = JSON.stringify(utmParams);

  useEffect(() => {
    storage.local.string(LocalStorageStringKey.UTM_PARAMS).set(utmParamsString);
  }, [utmParamsString]);
};

export const retrieveSavedUtmParameters = () => {
  const utmParamsString = storage.local.string(LocalStorageStringKey.UTM_PARAMS).get();
  if (!utmParamsString) return {};

  try {
    return JSON.parse(utmParamsString) as {
      utm_source?: string;
      utm_medium?: string;
      utm_campaign?: string;
      utm_term?: string;
      utm_content?: string;
    };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (error) {
    return {};
  }
};
