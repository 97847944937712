import {
  EmailSignUp,
  first,
  FreeTrial,
  Membership,
  RemoteConfig,
  sleep,
  sort,
  User,
  UserReferral,
  parseFullName,
} from "@fyxer-ai/shared";
import { useQuery } from "@tanstack/react-query";
import { addMinutes, isEqual } from "date-fns";
import { getToken } from "firebase/app-check";
import { onAuthStateChanged, onIdTokenChanged, User as AuthUser } from "firebase/auth";
import { doc, limit, onSnapshot, orderBy, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { createContext, ReactNode, useEffect } from "react";

import { EventName, useAnalytics } from "@/hooks/useAnalytics";
import { retrieveSavedUtmParameters } from "@/hooks/useSaveUtmParametersIfPresent";
import { useUpdateState } from "@/hooks/useUpdateState";
import { appCheck, auth } from "@/lib/firebase/app";
import { Collection } from "@/lib/firebase/Collection";
import { unwrap } from "@/lib/firebase/unwrap";
import { pushToDataLayer } from "@/lib/pushToDataLayer";

import { emptyLoadedValue, LoadedValue } from "../../types/LoadedValue";

type BaseState = {
  authUser: LoadedValue<AuthUser>;
  authIdToken: LoadedValue<string>;
  appCheckToken: LoadedValue<string>;
  remoteConfig: LoadedValue<RemoteConfig>;
  user: LoadedValue<User>;
  memberships: LoadedValue<QueryDocumentSnapshot<Membership>[]>;
  userReferrals: LoadedValue<QueryDocumentSnapshot<UserReferral>[]>;
  emailSignUp: LoadedValue<QueryDocumentSnapshot<EmailSignUp>>;
  freeTrial: LoadedValue<QueryDocumentSnapshot<FreeTrial>>;
};

const initialBaseState: BaseState = {
  authUser: emptyLoadedValue,
  authIdToken: emptyLoadedValue,
  appCheckToken: emptyLoadedValue,
  remoteConfig: emptyLoadedValue,
  user: emptyLoadedValue,
  memberships: emptyLoadedValue,
  userReferrals: emptyLoadedValue,
  emailSignUp: emptyLoadedValue,
  freeTrial: emptyLoadedValue,
};

export const BaseContext = createContext<BaseState>(initialBaseState);

export const getIsNewSignUp = (authUser: AuthUser) => {
  const { creationTime: createdAtString, lastSignInTime: lastSignedInAtString } = authUser.metadata;

  if (!createdAtString || !lastSignedInAtString) return false;
  const createdAt = new Date(createdAtString);
  const lastSignedInAt = new Date(lastSignedInAtString);
  const now = new Date();

  const signedUpOnLastSignIn = isEqual(createdAt, lastSignedInAt);
  const fiveMinutesAgo = addMinutes(now, -5);
  const justSignedUp = createdAt > fiveMinutesAgo;
  const shouldCountAsSignUp = justSignedUp && signedUpOnLastSignIn;

  return shouldCountAsSignUp;
};

export const BaseProvider = ({ children }: { children: ReactNode }) => {
  const [state, updateState] = useUpdateState<BaseState>(initialBaseState);

  const { identify, logEvent } = useAnalytics();

  const userId = state.authUser.value?.uid;
  const email = state.authUser.value?.email;

  const { data: appCheckToken, isLoading: isAppCheckTokenLoading } = useQuery({
    queryKey: ["appCheckToken"],
    queryFn: async () => {
      let token: string;

      try {
        const result = await getToken(appCheck);
        token = result.token;
      } catch (err) {
        console.error(err);
        await sleep(2000);
        try {
          const result = await getToken(appCheck);
          token = result.token;
        } catch (err) {
          console.error(err);
          await sleep(2000);
          const result = await getToken(appCheck);
          token = result.token;
        }
      }
      return token;
    },
    refetchInterval: 1000 * 60 * 60 * 24 - 1000 * 60,
  });

  useEffect(() => {
    const authUserUnsubscribe = onAuthStateChanged(auth, (authUser) => {
      updateState({
        authUser: { value: authUser ?? undefined, isLoading: false },
        authIdToken: { value: undefined, isLoading: false },
      });

      if (!authUser) return;

      try {
        if (!getIsNewSignUp(authUser)) return;

        const utmParams = retrieveSavedUtmParameters();

        logEvent(EventName.SIGN_UP, { ...utmParams, userId: authUser.uid });

        const { email, uid: userId, displayName } = authUser;
        const { firstName, lastName } = displayName
          ? parseFullName(displayName)
          : { firstName: undefined, lastName: undefined };

        pushToDataLayer({
          event: "signUp",
          event_id: userId,
          user_props: {
            email,
            external_id: userId,
            first_name: firstName,
            last_name: lastName,
          },
        });
      } catch (err) {
        console.log(err);
      }
    });

    const authIdTokenUnsubscribe = onIdTokenChanged(auth, async (authUser) => {
      if (!authUser) {
        updateState({ authIdToken: { value: undefined, isLoading: false } });
        return;
      }

      const { token: authIdToken } = await authUser.getIdTokenResult();

      updateState({ authIdToken: { value: authIdToken, isLoading: false } });
    });

    const remoteConfigUnsubscribe = onSnapshot(Collection.RemoteConfig, (snapshot) => {
      const remoteConfigs = snapshot.docs.map((doc) => doc.data());
      const remoteConfig = first(remoteConfigs);
      updateState({ remoteConfig: { value: remoteConfig, isLoading: false } });
    });

    return () => {
      authUserUnsubscribe();
      remoteConfigUnsubscribe();
      authIdTokenUnsubscribe();
    };
  }, [updateState, logEvent]);

  useEffect(() => {
    identify(userId);
  }, [identify, userId]);

  useEffect(() => {
    updateState({ user: emptyLoadedValue, memberships: emptyLoadedValue });

    if (!userId) return;

    const userUnsubscribe = onSnapshot(doc(Collection.User, userId), (userDoc) => {
      const user = userDoc.data();
      updateState({ user: { value: user, isLoading: false } });
    });

    const membershipsUnsubscribe = onSnapshot(query(Collection.Membership, where("userId", "==", userId)), (snapshot) =>
      updateState({ memberships: { value: snapshot.docs, isLoading: false } }),
    );

    const userReferralsUnsubscribe = onSnapshot(
      query(
        Collection.UserReferral,
        where("referringUser.userId", "==", userId),
        orderBy("createdAt", "desc"),
        limit(5),
      ),
      (snapshot) => updateState({ userReferrals: { value: snapshot.docs, isLoading: false } }),
    );

    return () => {
      userUnsubscribe();
      membershipsUnsubscribe();
      userReferralsUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);

  useEffect(() => {
    updateState({ emailSignUp: emptyLoadedValue });

    if (!email) return;

    const emailSignUpUnsubscribe = onSnapshot(
      query(Collection.EmailSignUp, where("email", "==", email)),
      (snapshot) => {
        const emailSignUp = first(snapshot.docs);
        updateState({ emailSignUp: { value: emailSignUp, isLoading: false } });
      },
    );

    const freeTrialUnsubscribe = onSnapshot(query(Collection.FreeTrial, where("email", "==", email)), (snapshot) => {
      const freeTrials = snapshot.docs;
      const now = new Date();
      const freeTrialsWhichHaveStarted = freeTrials.filter(unwrap((freeTrial) => freeTrial.startsAt < now));
      const freeTrial = first(
        sort(
          freeTrialsWhichHaveStarted,
          unwrap((freeTrial) => freeTrial.endsAt),
          "desc",
        ),
      );
      updateState({ freeTrial: { value: freeTrial, isLoading: false } });
    });

    return () => {
      emailSignUpUnsubscribe();
      freeTrialUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  const value = {
    ...state,
    appCheckToken: { value: appCheckToken, isLoading: isAppCheckTokenLoading },
  };

  return <BaseContext.Provider value={value}>{children}</BaseContext.Provider>;
};
