import { Copy } from "lucide-react";
import { forwardRef, MouseEvent, MouseEventHandler } from "react";

import { Button, ButtonProps } from "../ui/button";
import { useToast } from "../ui/use-toast";
import { cn } from "@/lib/utils";

const DEFAULT_CONFIRMATION_MESSAGE = "Copied to clipboard";

export const useGetCopyProps = () => {
  const { toast } = useToast();

  const getCopyProps = ({
    onClick,
    value,
    confirmationMessage = DEFAULT_CONFIRMATION_MESSAGE,
  }: {
    onClick?: MouseEventHandler;
    value: string;
    confirmationMessage?: string;
  }) => ({
    onClick: (event: MouseEvent) => {
      navigator.clipboard.writeText(value);
      toast({ title: confirmationMessage });
      onClick?.(event);
    },
  });

  return { getCopyProps };
};

export const CopyUtil = forwardRef<HTMLButtonElement, ButtonProps & { value: string; confirmationMessage?: string }>(
  ({ onClick, value, confirmationMessage = DEFAULT_CONFIRMATION_MESSAGE, ...props }, ref) => {
    const { getCopyProps } = useGetCopyProps();
    return (
      <Button
        {...props}
        {...getCopyProps({ onClick, value, confirmationMessage })}
        variant={props.variant ?? "secondary"}
        ref={ref}
      />
    );
  },
);

export const CopyIconButton = forwardRef<HTMLButtonElement, Omit<ButtonProps, "children" | "size"> & { value: string }>(
  ({ ...props }, ref) => (
    <CopyUtil {...props} ref={ref} size="icon">
      <Copy size={16} />
    </CopyUtil>
  ),
);

export const CopyTextButton = forwardRef<
  HTMLButtonElement,
  Omit<ButtonProps, "children"> & { value: string; title?: string; includeLink?: boolean }
>(({ includeLink = true, title = "Copy link", ...props }, ref) => {
  const variant = props.variant ?? "secondary";
  const textColor = ["default", "destructive"].includes(variant) ? "white" : "black";
  return (
    <CopyUtil {...props} ref={ref} className={cn("flex gap-x-1", props.className)}>
      {includeLink ? <Copy className="flex-shrink-0" size={16} color={textColor} /> : null}
      <p className="flex-shrink-0" style={{ color: textColor }}>
        {title}
      </p>
    </CopyUtil>
  );
});
