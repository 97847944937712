import * as SliderPrimitive from "@radix-ui/react-slider";
import * as React from "react";

import { cn } from "@/lib/utils";

const SliderTrack = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Track>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Track>
>(({ className, ...props }, ref) => (
  <SliderPrimitive.Track
    ref={ref}
    className={cn("relative h-2 w-full grow overflow-hidden rounded-full bg-slate-100 dark:bg-slate-800", className)}
    {...props}
  >
    <SliderPrimitive.Range className="absolute h-full bg-slate-900 dark:bg-slate-50" />
  </SliderPrimitive.Track>
));

SliderTrack.displayName = SliderPrimitive.Track.displayName;

const SliderThumb = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Thumb>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Thumb> & {
    label?: string;
  }
>(({ className, label, ...props }, ref) => (
  <SliderPrimitive.Thumb
    ref={ref}
    className={cn(
      "relative block h-5 w-5 rounded-full border-2 border-slate-900 bg-white ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:border-slate-50 dark:bg-slate-950 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-800",
      className,
    )}
    {...props}
  >
    {label ? (
      <div className="absolute bottom-full left-1/2 -translate-x-1/2 -translate-y-1 text-xs text-gray-500">{label}</div>
    ) : null}
  </SliderPrimitive.Thumb>
));

SliderThumb.displayName = SliderPrimitive.Thumb.displayName;

const SliderRoot = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root> & {
    render?: (value?: number[]) => React.ReactNode;
  }
>(({ className, children, render, value, ...props }, ref) => (
  <SliderPrimitive.Root
    ref={ref}
    className={cn("relative flex w-full touch-none select-none items-center", className)}
    {...props}
  >
    {render ? render(value) : children}
  </SliderPrimitive.Root>
));

SliderRoot.displayName = SliderPrimitive.Root.displayName;

const Slider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>((props, ref) => (
  <SliderRoot {...props} ref={ref}>
    <SliderTrack />
    <SliderThumb />
  </SliderRoot>
));
Slider.displayName = "Slider";

const RangeSlider = React.forwardRef<
  React.ElementRef<typeof SliderPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof SliderPrimitive.Root>
>((props, ref) => (
  <SliderRoot {...props} ref={ref}>
    <SliderTrack />
    <SliderThumb />
    <SliderThumb />
  </SliderRoot>
));

RangeSlider.displayName = "RangeSlider";

export { Slider, RangeSlider, SliderRoot, SliderThumb, SliderTrack };
