import { Loader2, LucideProps } from "lucide-react";
import { forwardRef, RefAttributes } from "react";

import { cn } from "@/lib/utils";

import { CenterPage } from "./CenterPage";

export const Spinner = forwardRef<
  SVGSVGElement,
  Omit<LucideProps, "ref"> & RefAttributes<SVGSVGElement> & { diameter?: number }
>(({ diameter = 24, className, ...props }, ref) => (
  <Loader2
    {...props}
    ref={ref}
    className={cn("m-auto animate-spin", className)}
    style={{ height: diameter, width: diameter }}
  />
));

export const SpinnerPage = ({ title }: { title?: string }) => {
  return (
    <CenterPage>
      <Spinner />
      {title && <h4 className="m-auto">{title}</h4>}
    </CenterPage>
  );
};
