import { Link, useRouteError } from "react-router-dom";
import * as Sentry from "@sentry/react";

import { Button } from "@/components/ui/button";
import { EventPage, useLogPageView } from "@/hooks/useAnalytics";

import { CenterPage } from "../components/layout/CenterPage";
import { TitleBody } from "../components/layout/TitleBody";
import { useEffect } from "react";

export const ErrorPage = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const error = useRouteError() as any;

  useLogPageView(EventPage.ERROR);

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  return (
    <CenterPage>
      <TitleBody title="An error has occured" titleSize="h1" bodyLines={[error.statusText || error.message]} />
      <Link to="/">
        <Button>Go back</Button>
      </Link>
    </CenterPage>
  );
};
