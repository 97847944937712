import { isSubscriptionActive } from "@fyxer-ai/shared";

import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";

import { FreePlanView } from "./FreePlanView";
import { InactiveSubscriptionView } from "./InactiveSubscriptionView";
import { SubscriptionView } from "./SubscriptionView";

export const BillingTab = () => {
  const { subscription } = useOrganisation();
  if (!subscription) return <FreePlanView />;
  const isActive = isSubscriptionActive(subscription.data());
  if (!isActive) return <InactiveSubscriptionView />;
  return <SubscriptionView />;
};
