import { pick, sort } from "@fyxer-ai/shared";
import { getDocs, query, where } from "firebase/firestore";

import { Collection } from "@/lib/firebase/Collection";
import { unwrap } from "@/lib/firebase/unwrap";

export const fetchSingleSignOnDetails = async (domain: string) => {
  const { docs: singleSignOnDetailsDocs } = await getDocs(
    query(Collection.SingleSignOnDetails, where("matchedDomains", "array-contains", domain)),
  );

  if (singleSignOnDetailsDocs.length === 0) return undefined;

  const singleSignOnDetailsDoc = sort(
    singleSignOnDetailsDocs,
    unwrap((doc) => doc.createdAt),
    "desc",
  )[0];

  return pick(singleSignOnDetailsDoc.data(), ["providerId", "ssoType", "organisationId"]);
};
