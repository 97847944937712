import { date, isSubscriptionActive, Organisation, Subscription, SubscriptionPlanType } from "@fyxer-ai/shared";
import { QueryDocumentSnapshot } from "firebase/firestore";

export enum PlanTypeSource {
  PRIVATE_BETA = "PRIVATE_BETA",
  SUBSCRIPTION = "SUBSCRIPTION",
  FREE_TRIAL = "FREE_TRIAL",
  NONE = "NONE",
}

const getPlanTypeSource = ({
  subscription,
  organisation,
  freeTrialEndsAt,
}: {
  organisation: Organisation;
  subscription: QueryDocumentSnapshot<Subscription> | undefined;
  freeTrialEndsAt: Date;
}) => {
  if (organisation.isPrivateBeta) return PlanTypeSource.PRIVATE_BETA;
  if (subscription && isSubscriptionActive(subscription.data())) return PlanTypeSource.SUBSCRIPTION;
  if (date.now() < freeTrialEndsAt) return PlanTypeSource.FREE_TRIAL;
  return PlanTypeSource.NONE;
};

const getPlanTypeFromSource = ({
  subscription,
  planTypeSource,
}: {
  planTypeSource: PlanTypeSource;
  subscription: QueryDocumentSnapshot<Subscription> | undefined;
}) => {
  switch (planTypeSource) {
    case PlanTypeSource.PRIVATE_BETA:
    case PlanTypeSource.FREE_TRIAL:
      return SubscriptionPlanType.STANDARD;
    case PlanTypeSource.SUBSCRIPTION:
      const { planType } = subscription!.data();
      if ([SubscriptionPlanType.STARTER, SubscriptionPlanType.GROWTH].includes(planType))
        return SubscriptionPlanType.STANDARD; // legacy plans get resolved to standard
      return planType;
    case PlanTypeSource.NONE:
      return "FREE" as const;
  }
};

export const getPlanType = ({
  subscription,
  organisation,
  freeTrialEndsAt,
}: {
  organisation: Organisation;
  subscription: QueryDocumentSnapshot<Subscription> | undefined;
  freeTrialEndsAt: Date;
}) => {
  const planTypeSource = getPlanTypeSource({ subscription: subscription, organisation, freeTrialEndsAt });
  const planType = getPlanTypeFromSource({ subscription: subscription, planTypeSource });
  const planTypeFromSubscription = subscription?.data().planType;

  return {
    planType,
    planTypeSource,
    planTypeFromSubscription,
  };
};
