import { useBase } from "./useBase";

export const useAuth = () => {
  const {
    authUser: { value: authUser, isLoading: isAuthUserLoading },
    authIdToken: { value: authIdToken, isLoading: isAuthIdTokenLoading },
  } = useBase();

  if (isAuthUserLoading) throw new Error("auth user is still loading");
  if (isAuthIdTokenLoading) throw new Error("auth id token is still loading");
  if (!authUser) throw new Error("auth user is missing");
  if (!authIdToken) throw new Error("auth id token is missing");

  const userId = authUser.uid;

  return { authUser, userId, authIdToken };
};
