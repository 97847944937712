import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { TabsUtil } from "@/components/controls/TabsUtil";
import { Container } from "@/components/layout/Container";
import { EventPage, useAnalytics } from "@/hooks/useAnalytics";

import { OnboardingBanner } from "./OnboardingBanner";
import { CallRecordingsTab } from "./tabs/CallRecordingsTab";
import { UpcomingCallsTab } from "./tabs/UpcomingCallsTab";

export enum MeetingAssistantTabName {
  UPCOMING_CALLS = "upcoming",
  CALL_RECORDINGS = "recordings",
}

export const MeetingAssistantPage = () => {
  const { hash } = useLocation();
  const { logPageView } = useAnalytics();
  const hashParams = new URLSearchParams(hash.substring(1));
  const tab = hashParams.get("tab") ?? MeetingAssistantTabName.UPCOMING_CALLS;

  useEffect(() => {
    logPageView(EventPage.MEETING_ASSISTANT, { tab });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tab]);

  return (
    <Container className="flex h-full flex-col space-y-8">
      <OnboardingBanner />
      <TabsUtil
        className="flex-shrink flex-grow sm:overflow-scroll"
        tabs={[
          { value: MeetingAssistantTabName.UPCOMING_CALLS, label: "Upcoming calls", content: <UpcomingCallsTab /> },
          {
            value: MeetingAssistantTabName.CALL_RECORDINGS,
            label: "Call recordings",
            content: <CallRecordingsTab />,
          },
        ]}
      />
    </Container>
  );
};
