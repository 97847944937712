import { DetailedHTMLProps, HTMLAttributes } from "react";

import { cn } from "@/lib/utils";

export const Container = ({
  children,
  className,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
  <div className={cn("m-auto w-full max-w-4xl px-4", className)} {...props}>
    {children}
  </div>
);
