import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { Container } from "@/components/layout/Container";
import { Input } from "@/components/ui/input";
import { useUserActions } from "@/context/BaseContext/actions/useUserActions";
import { EventForm, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";
import { useQueryParams } from "@/hooks/useQueryParams";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

import { FormUtil } from "../components/controls/FormUtil";

export const CompleteProfilePage = () => {
  const { return_path } = useQueryParams();
  const returnPath = return_path ?? "/";
  useLogPageView(EventPage.COMPLETE_PROFILE, { returnPath });
  const { logFormSubmit } = useAnalytics();

  const { update } = useUserActions();
  const navigate = useComplexNavigate();

  return (
    <Container className="space-y-4 py-12">
      <h1>Complete your profile</h1>
      <FormUtil
        schema={z.object({
          name: makeSchemaSafe(z.string().min(1).max(320)),
        })}
        defaultValues={{ name: "" }}
        onSubmit={async (data) => {
          logFormSubmit(EventForm.COMPLETE_PROFILE);
          await update(data);
          navigate(returnPath);
        }}
        submitTitle="Save"
        successMessage="Profile completed"
        render={(form) => (
          <FormFieldUtil control={form.control} name="name" render={({ field }) => <Input {...field} />} />
        )}
      />
    </Container>
  );
};
