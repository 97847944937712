import { AutoJoinMeetingConfiguration, configDefaults, constructUrl, OauthServiceType } from "@fyxer-ai/shared";
import { doc, updateDoc } from "firebase/firestore";
import { useCallback } from "react";
import { Link } from "react-router-dom";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { Collection } from "@/lib/firebase/Collection";
import { unwrap } from "@/lib/firebase/unwrap";

const ConnectCalendarCard = () => {
  const { organisationId } = useOrganisation();

  return (
    <Card>
      <CardHeader>
        <CardTitle>Connect your calendar</CardTitle>
        <CardDescription>
          To record your calls, we need to be able to send our Meeting Assistant to your meeting links. To do that, we
          need to be connected to your calendar, so we can see what events you have.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <Link to={constructUrl({ path: `/org/${organisationId}/settings`, hash: { tab: "integrations" } })}>
          <Button>Connect calendar</Button>
        </Link>
      </CardFooter>
    </Card>
  );
};

const AutoJoinMeetingCard = () => {
  const { userMembership } = useOrganisation();
  const membershipId = userMembership.id;

  const updateAutoJoinMeetingConfiguration = useCallback(
    (autoJoinMeetingConfiguration: AutoJoinMeetingConfiguration) =>
      updateDoc(doc(Collection.Membership, membershipId), { autoJoinMeetingConfiguration }),
    [membershipId],
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex items-center gap-x-4">
          Auto record meetings<Badge variant="secondary">Recommended</Badge>
        </CardTitle>
        <CardDescription>
          Fyxer AI will join all calendar events with a meeting link by default. You can turn it off for specified
          meetings here.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <Button onClick={() => updateAutoJoinMeetingConfiguration(AutoJoinMeetingConfiguration.ALWAYS)}>Enable</Button>
      </CardFooter>
    </Card>
  );
};

export const OnboardingBanner = () => {
  const { connections, userMembership } = useOrganisation();
  const autoJoinMeetingConfiguration =
    userMembership.data().autoJoinMeetingConfiguration ?? configDefaults.autoJoinMeetingConfiguration;
  const isAutoJoinDisabled = autoJoinMeetingConfiguration !== AutoJoinMeetingConfiguration.ALWAYS;
  const shouldShowAutoJoinBanner = isAutoJoinDisabled; //&& !hasDismissedAutoJoinBanner;
  const hasCalendarConnection = connections.some(
    unwrap((connection) => connection.serviceType === OauthServiceType.CALENDAR),
  );

  if (!hasCalendarConnection) return <ConnectCalendarCard />;
  if (shouldShowAutoJoinBanner) return <AutoJoinMeetingCard />;
  return null;
};
