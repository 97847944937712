import { ReactNode } from "react";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";

import { useCallRecordingNullable } from "../state/useCallRecordingNullable";

export const CallRecordingGuard = ({ children }: { children: ReactNode }) => {
  const { callRecording, callAnalysis, callSummary, transcriptSegments, callRecordingSnippets } =
    useCallRecordingNullable();

  if (
    callRecording.isLoading ||
    callAnalysis.isLoading ||
    callSummary.isLoading ||
    transcriptSegments.isLoading ||
    callRecordingSnippets.isLoading
  )
    return <LoadingDashboard />;

  if (callRecording.value === undefined) throw new Error("call recording is missing");
  if (transcriptSegments.value === undefined) throw new Error("transcript segments are missing");
  if (callRecordingSnippets.value === undefined) throw new Error("call recording snippets are missing");

  return children;
};
