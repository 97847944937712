import { CenterPage } from "@/components/layout/CenterPage";
import { StorageVideoPlayer } from "@/components/StorageVideoPlayer";
import { useCallRecordingSnippet } from "@/context/CallRecordingSnippetContext/state/useCallRecordingSnippet";
import { OnboardingBannerIfNeeded } from "@/routes/call-recordings/[callRecordingId]";

export const CallRecordingSnippetPage = () => {
  const { callRecordingSnippet } = useCallRecordingSnippet();
  const { storagePath } = callRecordingSnippet;

  return (
    <>
      <OnboardingBannerIfNeeded />
      <CenterPage className="max-w-4xl">
        <StorageVideoPlayer {...{ storagePath }} className="w-full" />
      </CenterPage>
    </>
  );
};
