"use client";

import { last } from "@fyxer-ai/shared";
import { toHeaderCase } from "js-convert-case";
import { ControllerProps, FieldPath, FieldValues } from "react-hook-form";

import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";

export const FormFieldUtil = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  name,
  render,
  title = toHeaderCase(last(name.split(".")) ?? name),
  description,
  isInline = false,
  isLabelHidden = false,
  className,
  labelClassName = "",
  ...props
}: ControllerProps<TFieldValues, TName> & {
  title?: string;
  description?: string;
  isInline?: boolean;
  className?: string;
  isLabelHidden?: boolean;
  labelClassName?: string;
}) => {
  return (
    <FormField
      name={name}
      {...props}
      render={({ field, fieldState, formState }) => (
        <FormItem className={className}>
          {isInline ? (
            <div className="space-y-2">
              <div className="flex items-center gap-x-2">
                <FormControl>{render({ field, fieldState, formState })}</FormControl>
                <FormLabel className={isLabelHidden ? "m-0 hidden" : labelClassName}>{title}</FormLabel>
              </div>
              {description ? <FormDescription>{description}</FormDescription> : null}
              <FormMessage />
            </div>
          ) : (
            <>
              <FormLabel className={isLabelHidden ? "m-0 hidden" : labelClassName}>{title}</FormLabel>
              <div className="space-y-2">
                <FormControl className={isLabelHidden ? "" : "mt-1"}>
                  {render({ field, fieldState, formState })}
                </FormControl>
                {description ? <FormDescription>{description}</FormDescription> : null}
                <FormMessage />
              </div>
            </>
          )}
        </FormItem>
      )}
    />
  );
};
