import { GoogleAuthProvider, OAuthProvider } from "firebase/auth";

import { AuthProviderType } from "./types/AuthProviderType";

export const getAuthProvider = (providerType: AuthProviderType) => {
  switch (providerType) {
    case AuthProviderType.GOOGLE: {
      const provider = new GoogleAuthProvider();
      provider.setCustomParameters({ prompt: "select_account" });
      provider.addScope("email");
      return provider;
    }
    case AuthProviderType.MICROSOFT: {
      const provider = new OAuthProvider("microsoft.com");
      provider.setCustomParameters({ prompt: "select_account" });
      return provider;
    }
  }
};
