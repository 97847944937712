import { format } from "date-fns";
import { toHeaderCase } from "js-convert-case";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Skeleton } from "@/components/ui/skeleton";
import { Table, TableCell, TableRow } from "@/components/ui/table";
import { useBillingPeriodEndDate } from "@/context/OrganisationContext/state/useBillingPeriodEndDate";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useClickProps } from "@/hooks/useClickProps";
import { redirectExternally } from "@/lib/redirectExternally";
import { Card, CardDescription, CardFooter, CardHeader, CardTitle } from "@/components/ui/card";
import { SubscriptionPlanType } from "@fyxer-ai/shared";
import { useToast } from "@/components/ui/use-toast";

const ProPlanUpgradeCard = () => {
  const { organisationId, subscription } = useOrganisation();
  const { logButtonPress } = useAnalytics();
  const { toast } = useToast();
  const api = useApi();

  if (!subscription) throw new Error("Must have a subscription to view this page");

  const { seatCount, billingCycle } = subscription.data();
  const upgradeClickProps = useClickProps({
    onClick: async () => {
      logButtonPress(EventButton.UPGRADE_TO_PRO, { organisationId, subscriptionId: subscription.id });
      await api.organisations.id(organisationId).subscriptions.id(subscription.id).update({
        billingCycle,
        planType: SubscriptionPlanType.PRO,

        seatCount,
      });

      toast({ title: "Upgrade successful", description: "Your team is now on the Pro Plan!" });
    },
    buttonText: "Upgrade",
  });

  return (
    <Card>
      <CardHeader>
        <CardTitle>Upgrade to the Pro Plan</CardTitle>
        <CardDescription>
          Teams on the Pro Plan get a custom branded Meeting Notetaker, multiple email and calendar accounts per user,
          and access to the Hubspot and Salesforce integrations.
        </CardDescription>
      </CardHeader>
      <CardFooter>
        <Button {...upgradeClickProps} />
      </CardFooter>
    </Card>
  );
};

export const SubscriptionView = () => {
  const { organisationId, subscription } = useOrganisation();
  const { logButtonPress } = useAnalytics();
  const api = useApi();

  if (!subscription) throw new Error("Must have a subscription to view this page");

  const { seatCount, billingCycle, planType, willCancelAtEndOfCurrentBillingPeriod } = subscription.data();
  const willCancel = willCancelAtEndOfCurrentBillingPeriod ?? false;

  const { data: billingPeriodEndDate, isLoading: isBillingPeriodEndDateLoading } = useBillingPeriodEndDate(
    subscription.id,
  );

  const handleGoToStripe = async () => {
    logButtonPress(EventButton.GO_TO_STRIPE, {
      subscriptionId: subscription.id,
    });
    const portalUrl = await api.organisations.id(organisationId).portalSessions.create();
    redirectExternally(portalUrl);
  };

  const goToStripeClickProps = useClickProps({
    onClick: handleGoToStripe,
    buttonText: "Manage subscription",
  });

  return (
    <div className="space-y-4">
      <h2>Your subscription</h2>
      <Table className="max-w-96">
        <TableRow>
          <TableCell>Plan</TableCell>
          <TableCell>
            <Badge>{toHeaderCase(planType)}</Badge>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Seats</TableCell>
          <TableCell>{seatCount}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Billing cycle</TableCell>
          <TableCell>{toHeaderCase(billingCycle)}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell>{willCancel ? "Cancels on" : "Next billing date"}</TableCell>
          <TableCell>
            {isBillingPeriodEndDateLoading || !billingPeriodEndDate ? (
              <Skeleton className="h-4 w-16 rounded" />
            ) : (
              format(billingPeriodEndDate, "dd-MM-yyyy")
            )}
          </TableCell>
        </TableRow>
      </Table>
      {planType === SubscriptionPlanType.STANDARD && <ProPlanUpgradeCard />}

      <Button {...goToStripeClickProps} variant="secondary" />
    </div>
  );
};
