import { parseCustomEmailDomain, User, parseFullName } from "@fyxer-ai/shared";
import { toHeaderCase } from "js-convert-case";
import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { Container } from "@/components/layout/Container";
import { Input } from "@/components/ui/input";
import { useMembershipsActions } from "@/context/BaseContext/actions/useMembershipsActions";
import { useUser } from "@/context/BaseContext/state/useUser";
import { EventForm, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

import { FormUtil } from "../../components/controls/FormUtil";
import { BackLink } from "../../components/layout/BackLink";

export const getSuggestedOrganisationName = (user: User) => {
  const { name, email } = user;
  const customEmailDomain = parseCustomEmailDomain(email);
  const { firstName } = parseFullName(name);
  return customEmailDomain ? toHeaderCase(customEmailDomain.split(".")[0]) : `${firstName}'s Space`;
};

export const CreateOrganisationPage = () => {
  useLogPageView(EventPage.CREATE_ORGANISATION);
  const { logFormSubmit } = useAnalytics();

  const navigate = useComplexNavigate();
  const { createOrg } = useMembershipsActions();
  const { user } = useUser();

  return (
    <Container className="space-y-8 py-12">
      <BackLink to="/org">Back</BackLink>
      <h1>Create your Organisation</h1>
      <p>
        If you're signing up as a company, an Organisation represents your company, and you'll be able to add your team
        to it. Otherwise, you can just name it "My Space" or similar.
      </p>
      <FormUtil
        schema={z.object({
          name: makeSchemaSafe(z.string().min(1).max(320)),
        })}
        defaultValues={{ name: getSuggestedOrganisationName(user) }}
        submitTitle="Create Organisation"
        successMessage="Organisation created successfully"
        onSubmit={async (data) => {
          logFormSubmit(EventForm.CREATE_ORGANISATION);
          const organisationId = await createOrg(data);
          navigate({
            path: `/org/${organisationId}/settings`,
            hash: { tab: "integrations" },
          });
        }}
        render={(form) => (
          <FormFieldUtil control={form.control} name="name" render={({ field }) => <Input {...field} />} />
        )}
      />
    </Container>
  );
};
