import { constructUrl } from "@fyxer-ai/shared";

import { Container } from "@/components/layout/Container";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";

import { BreadcrumbUtil } from "../../../../../../components/controls/BreadcrumbUtil";
import { MeetingAssistantTabName } from "../..";
import { CallRecordingView } from "./CallRecordingView";

export const CallRecordingPage = () => {
  const { organisationId, timeZone } = useOrganisation();
  const {
    callRecording: { calendarEvent },
  } = useCallRecording();

  const title = calendarEvent?.title ?? "Call recording";

  return (
    <Container className="flex h-full flex-col space-y-8">
      <BreadcrumbUtil
        currentPageTitle={title}
        parentPages={[
          {
            title: "Call recordings",
            href: constructUrl({
              path: `/org/${organisationId}/meeting-assistant`,
              hash: { tab: MeetingAssistantTabName.CALL_RECORDINGS },
            }),
          },
        ]}
      />
      <CallRecordingView timeZone={timeZone} />
    </Container>
  );
};
