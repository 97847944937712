import { CheckoutSession, constructUrl, PaymentProvider } from "@fyxer-ai/shared";
import { onSnapshot, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { z } from "zod";

import { SpinnerPage } from "@/components/layout/SpinnerPage";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventPage, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useQueryParams } from "@/hooks/useQueryParams";
import { Collection } from "@/lib/firebase/Collection";
import { LoadedValue } from "@/types/LoadedValue";

const useStripeUrl = () => {
  const { status, session_id: stripeCheckoutSessionId } = useQueryParams();
  if (!stripeCheckoutSessionId) throw new Error("Stripe URL missing checkout session id");

  const checkoutSessionStatusSchema = z.enum(["cancelled", "success"]);

  try {
    const stripeCheckoutStatus = checkoutSessionStatusSchema.parse(status);
    return { stripeCheckoutStatus, stripeCheckoutSessionId };
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
  } catch (err) {
    throw new Error(`Invalid Stripe checkout status: ${status}`);
  }
};

const useCheckoutSessions = () => {
  const { organisationId } = useOrganisation();
  const { stripeCheckoutSessionId } = useStripeUrl();

  const [{ isLoading, value: checkoutSessions }, setCheckoutSessions] = useState<
    LoadedValue<QueryDocumentSnapshot<CheckoutSession>[]>
  >({
    isLoading: true,
    value: undefined,
  });

  useEffect(() => {
    setCheckoutSessions({ isLoading: true, value: undefined });

    if (!organisationId || !stripeCheckoutSessionId) return;

    const unsubscribe = onSnapshot(
      query(
        Collection.CheckoutSession,
        where("organisationId", "==", organisationId),
        where("paymentProvider", "==", PaymentProvider.STRIPE),
        where("providerId", "==", stripeCheckoutSessionId),
      ),
      (snapshot) => {
        setCheckoutSessions({ isLoading: false, value: snapshot.docs });
      },
    );

    return () => {
      unsubscribe();
    };
  }, [organisationId, stripeCheckoutSessionId]);

  return { isLoading, checkoutSessions };
};

export const CheckoutSessionPage = () => {
  const { stripeCheckoutSessionId, stripeCheckoutStatus } = useStripeUrl();
  const { organisationId } = useOrganisation();
  const api = useApi();
  const { checkoutSessions } = useCheckoutSessions();

  const checkoutSession = checkoutSessions?.[0];

  useLogPageView(EventPage.CHECKOUT_SESSION, { stripeCheckoutSessionId, status: stripeCheckoutStatus });

  useEffect(() => {
    (async () => {
      if (!checkoutSession || stripeCheckoutStatus !== "cancelled") return;
      await api.organisations.id(organisationId).checkoutSessions.id(checkoutSession.id).cancel();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutSession, stripeCheckoutStatus, organisationId]);

  if (!checkoutSession) return <SpinnerPage />;

  return (
    <Navigate
      to={constructUrl({
        path: `/org/${organisationId}/settings`,
        hash: {
          tab: "billing",
        },
      })}
    />
  );
};
