import { constructUrl } from "@fyxer-ai/shared";
import { ReactNode } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";

import { useBase } from "../state/useBase";
import { useQueryParams } from "@/hooks/useQueryParams";

export const AuthGuard = ({ children }: { children: ReactNode }) => {
  const { authUser, authIdToken } = useBase();
  const location = useLocation();
  const { existing_user } = useQueryParams();
  const isExistingUserAccordingToUrl = existing_user === "true";

  const isAuthedUserWaitingForAuthToken = authUser.value && !authIdToken.value;
  const isLoading = authUser.isLoading || authIdToken.isLoading || isAuthedUserWaitingForAuthToken;
  if (isLoading) return <LoadingDashboard />;

  if (!authUser.value)
    return (
      <Navigate
        to={constructUrl({
          path: isExistingUserAccordingToUrl ? "/auth/log-in" : "/auth/sign-up",
          search: {
            return_path: location.pathname,
          },
        })}
      />
    );

  return children;
};
