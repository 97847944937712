import { PricingView } from "@/routes/org/PricingView";

export const FreePlanView = () => {
  return (
    <div className="space-y-4">
      <h2 className="text-center">Start a paid plan</h2>
      <PricingView />
    </div>
  );
};
