import { User } from "@fyxer-ai/shared";
import { doc, updateDoc } from "firebase/firestore";

import { Collection } from "@/lib/firebase/Collection";

import { useAuth } from "./useAuth";
import { useBase } from "./useBase";

export const useUser = () => {
  const { userId } = useAuth();
  const {
    user: { value: user, isLoading: isUserLoading },
    emailSignUp: { value: emailSignUp, isLoading: isEmailSignUpLoading },
    userReferrals: { value: userReferrals, isLoading: areUserReferralsLoading },
    freeTrial: { value: freeTrial, isLoading: isFreeTrialLoading },
  } = useBase();

  if (isUserLoading) throw new Error("user is still loading");
  if (isEmailSignUpLoading) throw new Error("email sign up is still loading");
  if (areUserReferralsLoading) throw new Error("user referrals are still loading");
  if (isFreeTrialLoading) throw new Error("free trial is still loading");

  if (!user) throw new Error("user is missing");
  if (userReferrals === undefined) throw new Error("user referrals are missing");
  if (!emailSignUp) throw new Error("email sign up is missing");

  const update = (update: Partial<User>) => updateDoc(doc(Collection.User, userId), update);

  const { createdAt: firstSignUpDate } = emailSignUp.data();
  const freeTrialEndsAt = freeTrial?.data().endsAt ?? emailSignUp.data().freeTrialEndsAt;

  return { userId, user, emailSignUp, freeTrial, freeTrialEndsAt, firstSignUpDate, update, userReferrals };
};
