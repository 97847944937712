import { constructUrl, MarketingConsentStatus, SignupSource } from "@fyxer-ai/shared";
import { doc, updateDoc } from "firebase/firestore";
import { Loader2 } from "lucide-react";
import { ReactNode, useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import { TopBar } from "@/components/layout/TopBar";
import { Progress } from "@/components/ui/progress";
import { Collection } from "@/lib/firebase/Collection";
import { LocalStorageBooleanKey, LocalStorageEnumKey, LocalStorageStringKey, storage } from "@/lib/storage";

import { getIsNewSignUp } from "../BaseContext";
import { useAuth } from "../state/useAuth";
import { useBase } from "../state/useBase";

export const UserGuard = ({
  children,
  allowMissingName = false,
}: {
  children: ReactNode;
  allowMissingName?: boolean;
}) => {
  const { userId, authUser } = useAuth();
  const {
    user: { value: user, isLoading: isUserLoading },
    emailSignUp: { value: emailSignUp, isLoading: isEmailSignUpLoading },
    userReferrals: { value: userReferrals, isLoading: areUserReferralsLoading },
  } = useBase();

  const location = useLocation();
  const wasUserCreated = !!user?.createdAt;

  useEffect(() => {
    if (!getIsNewSignUp(authUser) || !wasUserCreated || !userId) return;

    const allowedMarketingOnSignup = storage.local.boolean(LocalStorageBooleanKey.ALLOWED_MARKETING_ON_SIGN_UP);
    const marketingConsentStatus = allowedMarketingOnSignup
      ? MarketingConsentStatus.ACCEPTED
      : MarketingConsentStatus.DECLINED;
    const referrerSlug = storage.local.string(LocalStorageStringKey.REFERRER).get();
    const signupSource =
      storage.local.enum<SignupSource>(LocalStorageEnumKey.SIGNUP_SOURCE).get() ?? SignupSource.ORGANIC;
    updateDoc(doc(Collection.User, userId), { referrerSlug, marketingConsentStatus, signupSource });
  }, [authUser, wasUserCreated, userId]);

  const isLoading = isUserLoading || isEmailSignUpLoading || areUserReferralsLoading;

  if (isLoading) return <LoadingDashboard />;

  if (!user || !emailSignUp || userReferrals === undefined)
    return (
      <div className="flex h-full items-center justify-center">
        <div className="w-128 space-y-8">
          <Loader2 className="m-auto animate-spin" size={50} />
          <div className="space-y-2">
            <h2 className="text-center">Setting up your account</h2>
            <p className="text-center text-slate-500">This usually takes 10 seconds</p>
          </div>
          <Progress className="m-auto w-96" value={5} />
        </div>
      </div>
    );

  const userName = user.name;

  if (userName === "" && !allowMissingName)
    return (
      <Navigate
        to={constructUrl({
          path: "/complete-profile",
          search: {
            return_path: location.pathname,
          },
        })}
      />
    );

  return (
    <div className="flex h-full flex-col">
      <TopBar />
      <div className="flex-grow overflow-scroll">{children}</div>
    </div>
  );
};
