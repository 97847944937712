import { useBase } from "./useBase";

export const useRemoteConfig = () => {
  const { value: remoteConfig, isLoading } = useBase().remoteConfig;

  if (isLoading) throw new Error("remote config is still loading");
  if (!remoteConfig) throw new Error("remote config is missing");

  return remoteConfig;
};
