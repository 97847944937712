// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DataLayer = Record<string, any>[];

declare global {
  interface Window {
    dataLayer: DataLayer;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const pushToDataLayer = (data: Record<string, any>) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);
};
