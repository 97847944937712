import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { PostHogProvider } from "posthog-js/react";
import { Outlet } from "react-router-dom";
import { IntercomProvider } from "react-use-intercom";

import { Toaster } from "@/components/ui/toaster";
import { TooltipProvider } from "@/components/ui/tooltip";
import { BaseProvider } from "@/context/BaseContext/BaseContext";
import { BaseGuard } from "@/context/BaseContext/guards/BaseGuard";
import { OrganisationProvider } from "@/context/OrganisationContext/OrganisationContext";
import { useRedirectToSystemDefaultBrowserIfInWebView } from "@/hooks/useRedirectToSystemDefaultBrowserIfInWebView";
import { useSaveParamsToLocalStorageIfNeeded } from "@/hooks/useSaveParamsToLocalStorageIfNeeded";
import { useSaveUtmParametersIfPresent } from "@/hooks/useSaveUtmParametersIfPresent";
import { config } from "@/lib/config";

import { Intercom } from "../components/Intercom";

const queryClient = new QueryClient();

export const Root = () => {
  useSaveUtmParametersIfPresent();
  useSaveParamsToLocalStorageIfNeeded();
  useRedirectToSystemDefaultBrowserIfInWebView();

  return (
    <QueryClientProvider client={queryClient}>
      <PostHogProvider apiKey={config().POSTHOG_TOKEN} options={{ api_host: "https://eu.posthog.com" }}>
        <TooltipProvider>
          <Toaster />
          <IntercomProvider appId={config().INTERCOM_APP_ID}>
            <Intercom />
            <BaseProvider>
              <OrganisationProvider>
                <BaseGuard>
                  <Outlet />
                </BaseGuard>
              </OrganisationProvider>
            </BaseProvider>
          </IntercomProvider>
        </TooltipProvider>
      </PostHogProvider>
    </QueryClientProvider>
  );
};
