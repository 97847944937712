import { useCallRecordingsNullable } from "./useCallRecordingsNullable";

export const useCallRecordings = () => {
  const {
    callRecordings: { value: callRecordings, isLoading: areCallRecordingsLoading },
    callRecordingsSeenByUser: { value: callRecordingsSeenByUser, isLoading: areCallRecordingsSeenByUserLoading },
    callRecordingsSharedWithUser: {
      value: callRecordingsSharedWithUser,
      isLoading: areCallRecordingsSharedWithUserLoading,
    },
  } = useCallRecordingsNullable();

  if (areCallRecordingsLoading) throw new Error("call recordings are still loading");
  if (areCallRecordingsSeenByUserLoading) throw new Error("call recordings seen by user are still loading");
  if (areCallRecordingsSharedWithUserLoading) throw new Error("call recordings shared with user are still loading");

  if (callRecordings === undefined) throw new Error("call recordings are missing");
  if (callRecordingsSeenByUser === undefined) throw new Error("call recordings seen by user are missing");
  if (callRecordingsSharedWithUser === undefined) throw new Error("call recordings shared with user are missing");

  return { callRecordings, callRecordingsSeenByUser, callRecordingsSharedWithUser };
};
