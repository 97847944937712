import { User } from "@fyxer-ai/shared";
import { doc, updateDoc } from "firebase/firestore";
import { useCallback } from "react";

import { Collection } from "@/lib/firebase/Collection";

import { useUser } from "../state/useUser";

export const useUserActions = () => {
  const { userId } = useUser();

  const update = useCallback((data: Partial<User>) => updateDoc(doc(Collection.User, userId), data), [userId]);
  return {
    update,
  };
};
