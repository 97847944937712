import { useLocation } from "react-router-dom";

export const useHashParams = () => {
  const { hash } = useLocation();
  const hashValues = hash.slice(1);
  return hashValues
    .split("&")
    .map((valueString) => valueString.split("="))
    .reduce<{ [key: string]: string }>((acc, [key, value]) => ({ ...acc, [key]: value }), {});
};
