import { ReactNode } from "react";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";

import { useCallRecordingSnippetNullable } from "../state/useCallRecordingSnippetNullable";

export const CallRecordingSnippetGuard = ({ children }: { children: ReactNode }) => {
  const { callRecordingSnippet } = useCallRecordingSnippetNullable();

  if (callRecordingSnippet.isLoading) return <LoadingDashboard />;

  if (callRecordingSnippet.value === undefined) throw new Error("call recording is missing");

  return children;
};
