import { toHeaderCase } from "js-convert-case";
import { Check, ChevronDown, X } from "lucide-react";
import { useMemo } from "react";

import { Badge } from "@/components/ui/badge";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

export const MultiSelectUtil = <Value extends string>({
  value: selectedValues,
  onValueChange: onSelectedValuesChange,
  items: unformattedItems,
  placeholder = "Select options",
  emptyMessage = "Not found",
  displayLimit = 5,
  className,
}: {
  value: Value[];
  onValueChange: (value: Value[]) => void;
  items: ({ value: Value; label: string } | Value)[];
  placeholder?: string;
  emptyMessage?: string;
  displayLimit?: number;
  className?: string;
}) => {
  const items = useMemo(
    () =>
      unformattedItems.map((item) => {
        return typeof item === "string" ? { value: item, label: toHeaderCase(item) } : item;
      }),
    [unformattedItems],
  );

  const selectedItems = useMemo(
    () => items.filter((item) => selectedValues.includes(item.value)),
    [selectedValues, items],
  );

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className={cn("flex items-center rounded-md border border-slate-200", className)}>
          {selectedValues.length === 0 ? (
            <div className="h-full flex-grow cursor-pointer px-4">
              <span className="text-sm text-slate-500">{placeholder}</span>
            </div>
          ) : (
            <>
              <div className="flex flex-grow flex-wrap gap-2 py-2 pl-2">
                {selectedItems.map((item) => (
                  <Badge variant="secondary" key={item.value} className="gap-x-2">
                    <span>{item.label}</span>
                    <Button
                      variant="ghost"
                      size="icon"
                      className="h-4 w-4"
                      onClick={(event) => {
                        onSelectedValuesChange(selectedValues.filter((value) => value !== item.value));
                        event.stopPropagation();
                      }}
                    >
                      <X className="h-4 w-4 stroke-slate-500" />
                    </Button>
                  </Badge>
                ))}
              </div>

              <Button
                variant="ghost"
                size="icon"
                className="flex-shrink-0"
                onClick={(event) => {
                  onSelectedValuesChange([]);
                  event.stopPropagation();
                }}
              >
                <X className="h-4 w-4 stroke-slate-500" />
              </Button>
            </>
          )}

          <Separator orientation="vertical" className="min-h-6 bg-slate-200" />
          <Button variant="ghost" size="icon" className="flex-shrink-0">
            <ChevronDown className="h-4 w-4 stroke-slate-500" />
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="w-[400px] p-0">
        <Command>
          <CommandInput placeholder={placeholder} />
          <CommandList>
            <CommandEmpty>{emptyMessage}</CommandEmpty>
            <CommandGroup>
              {items.map((item) => {
                const isSelected = selectedValues.includes(item.value);
                return (
                  <CommandItem
                    key={item.value}
                    value={item.value}
                    onSelect={(currentValue) =>
                      onSelectedValuesChange(
                        isSelected
                          ? selectedValues.filter((value) => value !== currentValue)
                          : ([...selectedValues, currentValue] as Value[]),
                      )
                    }
                  >
                    <Check className={cn("mr-2 h-4 w-4", isSelected ? "opacity-100" : "opacity-0")} />
                    {item.label}
                  </CommandItem>
                );
              })}
              {items.length > displayLimit && (
                <p className="text-sm text-slate-500">+{items.length - displayLimit} more</p>
              )}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
