import * as TabsPrimitive from "@radix-ui/react-tabs";
import * as React from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useUpdateState } from "@/hooks/useUpdateState";
import { cn } from "@/lib/utils";

const Tabs = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Root>
>((props, ref) => {
  const { hash } = useLocation();
  const hashParams = new URLSearchParams(hash.substring(1));
  const urlTab = hashParams.get("tab");
  const initialActiveTab = urlTab ?? props.defaultValue;
  const [state, updateState] = useUpdateState({
    activeTab: initialActiveTab,
  });

  const navigate = useNavigate();

  const activeTabRef = React.useRef(initialActiveTab);

  React.useEffect(() => {
    if (!state.activeTab) return;
    const hasChangedActiveTab = activeTabRef.current !== state.activeTab;
    activeTabRef.current = state.activeTab;

    if (hasChangedActiveTab) {
      const newHash = new URLSearchParams(hash.substring(1));
      newHash.set("tab", state.activeTab);
      navigate(`#${newHash.toString()}`, { replace: true });
    } else {
      const newHash = new URLSearchParams(hash.substring(1));
      const newTab = newHash.get("tab");
      if (!newTab) return;

      updateState({ activeTab: newTab });
    }
  }, [state.activeTab, hash, navigate, updateState]);

  return (
    <TabsPrimitive.Root
      ref={ref}
      value={state.activeTab}
      onValueChange={(tab) => updateState({ activeTab: tab })}
      {...props}
    />
  );
});

const TabsList = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.List>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.List>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.List
    ref={ref}
    className={cn(
      "inline-flex h-12 items-center justify-center rounded-md border border-black bg-slate-50 px-1.5 py-1 text-slate-500 dark:bg-slate-800 dark:text-slate-400",
      className,
    )}
    {...props}
  />
));
TabsList.displayName = TabsPrimitive.List.displayName;

const TabsTrigger = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Trigger
    ref={ref}
    className={cn(
      "border-box box-border inline-flex items-center justify-center whitespace-nowrap rounded-md px-3 py-1.5 text-sm font-medium text-slate-800 ring-offset-white transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[state=active]:border data-[state=active]:border-black data-[state=active]:bg-white data-[state=active]:text-black dark:ring-offset-slate-950 dark:focus-visible:ring-slate-800 dark:data-[state=active]:bg-slate-950 dark:data-[state=active]:text-slate-50",
      className,
    )}
    {...props}
  />
));
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName;

const TabsContent = React.forwardRef<
  React.ElementRef<typeof TabsPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content
    ref={ref}
    className={cn(
      "mt-2 ring-offset-white focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-400 focus-visible:ring-offset-2 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-800",
      className,
    )}
    {...props}
  />
));
TabsContent.displayName = TabsPrimitive.Content.displayName;

export { Tabs, TabsList, TabsTrigger, TabsContent };
