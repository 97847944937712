import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { useClickProps } from "@/hooks/useClickProps";

export const ConfirmAlertDialog = ({
  title,
  warningText,
  fn,
  open,
  onOpenChange,
}: {
  title: string;
  warningText: string;
  fn: () => Promise<void>;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}) => {
  const clickProps = useClickProps({
    onClick: fn,
    buttonText: title,
  });

  return (
    <AlertDialog open={open} onOpenChange={onOpenChange}>
      <AlertDialogTrigger asChild>
        <Button className="block max-sm:w-full" variant="destructive">
          {title}
        </Button>
      </AlertDialogTrigger>
      <AlertDialogContent>
        <AlertDialogHeader>
          <AlertDialogTitle>Are you sure?</AlertDialogTitle>
          <AlertDialogDescription>{warningText}</AlertDialogDescription>
        </AlertDialogHeader>
        <AlertDialogFooter>
          <AlertDialogCancel>Cancel</AlertDialogCancel>
          <AlertDialogAction {...clickProps} className="border border-red-500 bg-white text-red-500 hover:bg-red-50" />
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialog>
  );
};
