import { useMutation } from "@tanstack/react-query";
import { AuthProvider, signInWithPopup } from "firebase/auth";

import { auth } from "@/lib/firebase/app";

export const useSignInWithPopup = () => {
  return useMutation({
    mutationFn: (provider: AuthProvider) => {
      return signInWithPopup(auth, provider);
    },
  });
};
