import { useMutation } from "@tanstack/react-query";
import { addDoc } from "firebase/firestore";
import { useEffect } from "react";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import { useQueryParams } from "@/hooks/useQueryParams";
import { Collection } from "@/lib/firebase/Collection";

export const EmailUnsubscribePage = () => {
  const { email, template } = useQueryParams();

  if (!email) throw new Error("No email provided");

  const createUnsubscribe = useMutation({
    mutationFn: async (email: string) => {
      await addDoc(Collection.EmailUnsubscribe, { email, template: template ?? "missing", createdAt: new Date() });
    },
  });

  useEffect(() => {
    createUnsubscribe.mutate(email);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email]);

  if (createUnsubscribe.error) return <p>{createUnsubscribe.error.message}</p>;
  if (createUnsubscribe.isSuccess) return <p>Unsubscribed {email}</p>;

  return <LoadingDashboard />;
};
