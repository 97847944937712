import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { SpinnerPage } from "@/components/layout/SpinnerPage";
import { useValidateOrganisationId } from "@/context/OrganisationContext/useValidateOrganisationId";

import { useCallRecordingsNullable } from "../state/useCallRecordingsNullable";

export const CallRecordingsGuard = ({ children }: { children: ReactNode }) => {
  const { organisationId } = useValidateOrganisationId();
  const { callRecordings, callRecordingsSeenByUser, callRecordingsSharedWithUser } = useCallRecordingsNullable();

  if (!organisationId) return <Navigate to="/org" />;

  if (callRecordings.isLoading) return <SpinnerPage />;
  if (callRecordingsSeenByUser.isLoading) return <SpinnerPage />;
  if (callRecordingsSharedWithUser.isLoading) return <SpinnerPage />;

  if (callRecordings.value === undefined) throw new Error("call recordings are missing");
  if (callRecordingsSeenByUser.value === undefined) throw new Error("call recordings seen by user are missing");
  if (callRecordingsSharedWithUser.value === undefined) throw new Error("call recordings shared with user are missing");

  return children;
};
