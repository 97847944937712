import { Environment } from "@fyxer-ai/shared";
import { initializeApp } from "firebase/app";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, initializeFirestore } from "firebase/firestore";
import { getPerformance } from "firebase/performance";
import { connectStorageEmulator, getStorage } from "firebase/storage";

import { config } from "../config";
import { getEnvironment } from "../getEnvironment";

const app = initializeApp(config().FIREBASE_CONFIG);

const auth = getAuth(app);
const storage = getStorage(app);
const db = initializeFirestore(app, { ignoreUndefinedProperties: true });

const performance = getPerformance(app);

if (getEnvironment() === Environment.LOCAL) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (self as any).FIREBASE_APPCHECK_DEBUG_TOKEN = true;
  connectAuthEmulator(auth, "http://localhost:9099");
  connectFirestoreEmulator(db, "localhost", 8080);
  connectStorageEmulator(storage, "localhost", 9199);
}

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(config().RECAPTCHA_V3_SITE_KEY),
  isTokenAutoRefreshEnabled: true,
});

export { auth, storage, db, appCheck, performance };
