import { Container } from "@/components/layout/Container";

export const PrivacyPolicyPage = () => (
  <Container className="space-y-6 py-12">
    <h1>FYXER AI PRIVACY POLICY</h1>

    <p>
      At FYXER AI, we respect your privacy and are committed to protecting your personal data. This privacy policy tells
      you about how we look after your personal data when you visit our website or otherwise work or communicate with us
      and it tells you about your privacy rights and how the law protects you.
    </p>

    <h2>1. Important information and who we are</h2>

    <h3>1.1 Purpose of this privacy policy</h3>
    <p>
      This privacy policy aims to give you information on how Fyxer AI collects and processes your personal data through
      your use of our website, our Fyxer services or otherwise when you communicate or interact with us in the course of
      business.
    </p>

    <h3>1.2 Controller</h3>
    <p>
      If you are a registered customer of Fyxer AI, we act as the ‘data controller’ of personal data about you and your
      use of Fyxer AI, but as the ‘data processor’ of personal data in the information you submit to Fyxer AI to use
      Fyxer AI’s product and services (like information about your calendar, emails, email accounts and so on). If we
      are the data processor of your personal data (i.e., not the data controller), please contact the controller party
      in the first instance to address your rights with respect to such data.
    </p>
    <p>We are registered as a data controller at the UK Information Commissioner’s Office under number ZB626835.</p>

    <p>
      If you have any questions about this privacy policy, including any requests to exercise your legal rights referred
      to below, please contact us using the details set out below.
    </p>

    <h3>1.3 Contact details</h3>
    <p>
      We are: Fyxer AI Limited of 85 Great Portland Street, First Floor, London, W1W 7LT. If you need to get hold of us
      for any reason in connection with your personal data, please email us at info@fyxer.com.
    </p>
    <p>
      You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the UK
      supervisory authority for data protection issues (<a href="http://www.ico.org.uk">www.ico.org.uk</a>). We would,
      however, appreciate the chance to deal with your concerns about privacy and data protection before you approach
      the ICO so please contact us in the first instance.
    </p>

    <h3>1.4 Changes to the privacy policy and your duty to inform us of changes</h3>
    <p>
      This version was last updated on 30th September 2024. If you use our website or the Fyxer services after any
      changes to this privacy policy have been posted, that means you agree to all of the changes. It is important that
      the personal data we hold about you is accurate and current. Please keep us informed if your personal data changes
      during your relationship with us.
    </p>

    <h2>2. The data we collect about you</h2>
    <p>
      We may create aggregated, de-identified or anonymized data from the personal data we collect, including by
      removing information that makes the data personally identifiable to a particular user. We may use such aggregated,
      de-identified or anonymized data and disclose it with third parties for our lawful business purposes, including to
      analyze, build and improve the Fyxer services and promote our business, provided that we will not disclose such
      data in a manner that could identify you.
    </p>
    <p>
      We may collect, use, store and transfer different kinds of personal data about you which we have grouped together
      as follows:
    </p>

    <ul>
      <li>
        <strong>Contact Data</strong> includes name, role at business, email address, phone number.
      </li>
      <li>
        <strong>Financial Data</strong> includes billing information, bank card details.
      </li>
      <li>
        <strong>Correspondence Data</strong> includes email correspondence, notes of conversations where these express
        an opinion.
      </li>
      <li>
        <strong>Usage Data</strong> includes information about how you use our website or services.
      </li>
      <li>
        <strong>Technical Data</strong> includes internet protocol (IP) address, your login data, browser type and
        version, time zone setting and location, browser plug-in types and versions, operating system and platform and
        other technology on the devices you use to access this website or our services.
      </li>
    </ul>

    <h2>3. How is your personal data collected?</h2>

    <p>We use different methods to collect data from and about you including through:</p>

    <ol>
      <li>
        <strong>Direct interactions:</strong> We collect the majority of your data through our Fyxer AI products and
        services on our website, by email, over the phone, in person at meetings or otherwise.
      </li>
      <li>
        <strong>Automated technologies or interactions:</strong> As you interact with our website, we may automatically
        collect Technical Data about your browsing actions and patterns. We collect this personal data by using cookies,
        and other similar technologies. We may also receive Technical Data about you if you visit other websites
        employing our cookies. You can set your browser to refuse all or some browser cookies, or to alert you when
        websites set or access cookies. For more information about the cookies we use, and how to disable them, please
        see our <a href="https://www.fyxer.com/cookie-policy">Cookie Policy</a>.
      </li>
      <li>
        <strong>Third parties or publicly available sources:</strong> We may receive Technical Data about you from
        analytics providers such as Google or Hubspot with servers based outside of Europe (as governed by our{" "}
        <a href="https://www.fyxer.com/cookie-policy">Cookie Policy</a>).
      </li>
    </ol>

    <h2>4. How we use your personal data</h2>

    <p>
      We will only use your personal data when the law allows us to. Most commonly, we will use your personal data in
      the following circumstances:
    </p>

    <ul>
      <li>In anticipation of or in accordance with the terms of use or service agreement that we have with you;</li>
      <li>
        Where it is necessary for our legitimate interests (or those of a third party) in the operation of our business
        and we have made an objective assessment that your interests and fundamental rights do not override those
        interests (for example to manage our relationship with you, to improve the service that we offer or to answer an
        enquiry you make to us); or
      </li>
      <li>Where we need to comply with a legal or regulatory obligation.</li>
    </ul>

    <p>
      Please contact us if you need details about the specific legal ground we are relying on to process your personal
      data.
    </p>

    <h2>5. Data shared with third-party AI models</h2>

    <h3>5.1 Data Shared with AI Models</h3>
    <p>
      In order to provide categorization and draft reply functionalities and analyze and transcribe audio recordings
      generated from audio and video conferences our service employs machine learning models using third party AI
      models. We require our AI service providers to use your information only for the purpose of providing our Service.
      We do not allow those providers to train their AI models using your data.
    </p>
    <p>The following data types may be shared with these AI models:</p>

    <ul>
      <li>Email Content and Data: Subject lines, email body text, sender and recipient information.</li>
      <li>Calendar Data: Event titles, dates, and times.</li>
      <li>
        Audio Recording Data: conversation participants, dates, times, the content of audio recordings generated by
        Users which may contain personal data, including personal information of third parties. Before providing Fyxer
        with Audio Recording Data please confirm you have the secured the necessary permissions or consents from those
        third parties before sharing such personal data with Fyxer or referring them to us.
      </li>
    </ul>

    <p>
      This data is processed for the sole purpose of delivering the services described and is not used for any other
      functions within the AI models.
    </p>

    <h3>5.2 User Consent for Data Sharing with AI Models</h3>
    <p>
      Before sharing any of your data with our AI models, we seek explicit user consent. Upon initial setup or
      significant changes to this privacy policy, a prompt will appear within the app requiring users to consent to data
      sharing for AI processing.
    </p>

    <h3>5.3 Third Party Data Retention</h3>
    <p>
      We have a Zero Data Retention agreement with our AI service provider in which our provider does not store customer
      API data on their servers.
    </p>

    <h2>6. Disclosures of your personal data</h2>

    <p>
      We will need to share your personal data with the parties set out below for the purposes set out in paragraph 4
      above:
    </p>

    <ul>
      <li>
        Our service providers acting as processors who may be based in the US or elsewhere outside the EEA and who
        provide us with account management, IT support, IT backup, data storage, email management, system
        administration, and language model services.
      </li>
      <li>
        Analytics and advertising service providers which may have servers based in the US for the purpose of analysing
        user behaviour.
      </li>
      <li>
        Third parties to whom we may choose to sell, transfer, or merge parts of our business or our assets.
        Alternatively, we may seek to acquire other businesses or merge with them. If a change happens to our business,
        then the new owners may use your personal data in the same way as set out in this privacy policy.
      </li>
    </ul>

    <p>
      We require all third parties to respect the security of your personal data and to treat it in accordance with the
      law. We do not allow our third-party service providers to use your personal data for their own purposes and only
      permit them to process your personal data for specified purposes and in accordance with our instructions.
    </p>

    <h2>7. International transfers</h2>

    <p>
      A number of our account managers and other IT service companies processing your personal data on our behalf are
      based in the US or elsewhere outside the EEA so their processing of your personal data will involve a transfer of
      data outside the EEA.
    </p>

    <p>
      Whenever we transfer your personal data out of the EEA, we ensure a similar degree of protection is afforded to it
      by ensuring that either:
    </p>

    <ul>
      <li>
        We have a specific contract with that processor in a form approved by the European Commission which ensures that
        service provider gives your personal data the same protection it has in Europe; or
      </li>
      <li>
        If the provider is based in the US, it is a member of the EU-US Data Privacy Framework which requires them to
        provide similar protection to personal data shared between Europe and the US.
      </li>
    </ul>

    <p>
      Please contact us if you want further information on the specific mechanism used by us when transferring your
      personal data out of the EEA.
    </p>

    <h2>8. Data security</h2>

    <p>
      We have put in place appropriate security measures to prevent your personal data from being accidentally lost,
      used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to your personal data
      to those employees, agents, contractors, and other third parties who have a business need to know. They will only
      process your personal data on our instructions and they are subject to a duty of confidentiality.
    </p>

    <p>
      We have put in place procedures to deal with any suspected personal data breach and will notify you and any
      applicable regulator of a breach where we are legally required to do so.
    </p>

    <h2>9. How long will you use my personal data for?</h2>

    <ul>
      <li>
        We will only retain your personal data for as long as necessary to fulfil the purposes we collected it for,
        including for the purposes of satisfying any legal, accounting, or reporting requirements.
      </li>
      <li>
        To determine the appropriate retention period for personal data, we consider the amount, nature, and sensitivity
        of the personal data, the potential risk of harm from unauthorised use or disclosure of your personal data, the
        purposes for which we process your personal data and whether we can achieve those purposes through other means,
        and the applicable legal requirements.
      </li>
      <li>In some circumstances, you can ask us to delete your data (see below for further information).</li>
      <li>
        In some circumstances we may anonymise your personal data (so that it can no longer be associated with you) for
        research or statistical purposes in which case we may use this information indefinitely without further notice
        to you.
      </li>
    </ul>

    <h2>10. Your legal rights</h2>

    <p>You have the right in certain circumstances to:</p>

    <ul>
      <li>Request access to your personal data (a "data subject access request").</li>
      <li>Request correction of the personal data that we hold about you.</li>
      <li>Request erasure of your personal data.</li>
      <li>
        Object to processing of your personal data where we are relying on a legitimate interest (or those of a third
        party) and there is something about your particular situation which makes you want to object to processing on
        this ground as you feel it impacts on your fundamental rights and freedoms.
      </li>
      <li>Request restriction of processing of your personal data.</li>
      <li>Request the transfer of your personal data to you or to a third party.</li>
    </ul>

    <p>
      More information on these rights and when they apply is available here:{" "}
      <a href="https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/">
        https://ico.org.uk/for-organisations/guide-to-the-general-data-protection-regulation-gdpr/individual-rights/
      </a>
    </p>

    <p>
      You will not have to pay a fee to access your personal data (or to exercise any of the other rights). We may need
      to request specific information from you to help us confirm your identity and ensure your right to access your
      personal data (or to exercise any of your other rights). This is a security measure to ensure that personal data
      is not disclosed to any person who has no right to receive it. We may also contact you to ask you for further
      information in relation to your request to speed up our response.
    </p>

    <p>
      We try to respond to all legitimate requests within one month. Occasionally it may take us longer than a month if
      your request is particularly complex or you have made a number of requests. In this case, we will notify you and
      keep you updated.
    </p>
  </Container>
);
