import { OauthIntegration, OauthProvider } from "@fyxer-ai/shared";
import { useState } from "react";
import { v4 as uuid } from "uuid";

import { useApi } from "@/hooks/useApi";
import { redirectExternally } from "@/lib/redirectExternally";
import { LocalStorageStringKey, storage } from "@/lib/storage";

export const useOauthRedirect = () => {
  const api = useApi();
  const [redirectingIntegration, setRedirectingIntegration] = useState<OauthIntegration>();

  const handleOauthRedirect = async ({
    organisationId,
    ...rest
  }: {
    organisationId: string;
  } & ({ type: "provider"; provider: OauthProvider } | { type: "integration"; integration: OauthIntegration })) => {
    const stateId = uuid();
    storage.local.string(LocalStorageStringKey.OAUTH_STATE_ID).set(stateId);

    if (rest.type === "integration") {
      setRedirectingIntegration(rest.integration);
    }

    const authUrl = await api.oauth.getAuthUrl({
      organisationId,
      stateId,
      ...rest,
    });

    redirectExternally(authUrl);
  };

  return { handleOauthRedirect, redirectingIntegration };
};
