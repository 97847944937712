import { isNull } from "@fyxer-ai/shared";
import { useEffect } from "react";
import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { CenterPage } from "@/components/layout/CenterPage";
import { Input } from "@/components/ui/input";
import { EventForm, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useQueryParams } from "@/hooks/useQueryParams";
import { useUpdateState } from "@/hooks/useUpdateState";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";
import { LocalStorageStringKey, storage } from "@/lib/storage";
import { useSignInWithEmailLink } from "@/routes/auth/actions/useSignInWithEmailLink";

import { FormUtil } from "../../components/controls/FormUtil";
import { LoadingDashboard } from "../../components/layout/LoadingDashboard";

interface Data {
  email: string | undefined;
}

export const EmailLinkPage = () => {
  const localStorageEmail = storage.local.string(LocalStorageStringKey.EMAIL_FOR_SIGN_IN).get();
  useLogPageView(EventPage.EMAIL_LINK, {
    hasLocalStorageEmail: !isNull(localStorageEmail),
  });
  const { logFormSubmit } = useAnalytics();
  const { return_path } = useQueryParams();
  const returnPath = return_path ?? "/";
  const signInWithEmailLink = useSignInWithEmailLink();

  const [state, updateState] = useUpdateState<Data>({
    email: localStorageEmail,
  });

  useEffect(() => {
    if (!state.email) return;
    signInWithEmailLink.mutate({
      email: state.email,
      emailLink: window.location.href,
      returnPath,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.email]);

  if (signInWithEmailLink.isPending) return <LoadingDashboard />;

  return (
    <CenterPage className="space-y-6 text-left">
      <div className="space-y-3">
        <h2>Confirm your email</h2>
        <p>You appear to be on a different browser. Please confirm your email.</p>
      </div>
      <FormUtil
        schema={z.object({
          email: makeSchemaSafe(z.string().email().min(1).max(320)),
        })}
        defaultValues={{ email: "" }}
        onSubmit={({ email }) => {
          logFormSubmit(EventForm.EMAIL_LINK_CONFIRMATION);
          updateState({ email });
        }}
        render={(form) => (
          <FormFieldUtil control={form.control} name="email" render={({ field }) => <Input {...field} />} />
        )}
      />
    </CenterPage>
  );
};
