import { constructUrl, OauthProvider } from "@fyxer-ai/shared";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { Link, Navigate, useParams } from "react-router-dom";
import { z } from "zod";

import { CenterPage } from "@/components/layout/CenterPage";
import { SpinnerPage } from "@/components/layout/SpinnerPage";
import { Button } from "@/components/ui/button";
import { EventName, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useQueryParams } from "@/hooks/useQueryParams";
import { LocalStorageStringKey, storage } from "@/lib/storage";

const useProviderThrows = () => {
  const { provider } = useParams();
  try {
    return z.nativeEnum(OauthProvider).parse(provider);
  } catch {
    throw new Error(`Invalid provider ${provider}`);
  }
};

export const OauthProviderRedirectPage = () => {
  const provider = useProviderThrows();
  const { logEvent } = useAnalytics();
  const { code, state } = useQueryParams();
  if (!code || !state) throw new Error("missing code or state");
  if (storage.local.string(LocalStorageStringKey.OAUTH_STATE_ID).get() !== state)
    throw new Error("state doesn't match");
  const api = useApi();

  const [error, setError] = useState<string | null>(null);

  const exchangeAuthCode = useMutation({
    mutationFn: () =>
      api.oauth.exchangeAuthCode({
        type: "provider",
        provider,
        code,
        state,
      }),
    onError: (error) => {
      if (error instanceof AxiosError) {
        setError(error.response?.data?.message ?? error.message);
      } else {
        setError((error as { message: string }).message);
      }
    },
  });

  const organisationId = exchangeAuthCode.data;

  useLogPageView(EventPage.OAUTH_PROVIDER, { provider });

  useEffect(() => {
    exchangeAuthCode.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    logEvent(EventName.COMPLETE_OAUTH_CONNECTION, { provider, organisationId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId, provider]);

  if (organisationId)
    return (
      <Navigate
        to={constructUrl({
          path: `/org/${organisationId}/settings`,
          hash: { tab: "integrations" },
        })}
      />
    );

  if (error) {
    return (
      <CenterPage>
        <div className="max-w-[500px] space-y-4 text-center">
          <h1 className="mb-4">Connection failed</h1>
          <p>{error}</p>
          <Link
            to={
              organisationId
                ? constructUrl({
                    path: `/org/${organisationId}/settings`,
                    hash: { tab: "integrations" },
                  })
                : constructUrl({ path: "/" })
            }
          >
            <Button>Go back</Button>
          </Link>
        </div>
      </CenterPage>
    );
  }

  return <SpinnerPage />;
};
