import { OrganisationRole } from "@fyxer-ai/shared";
import { Link } from "lucide-react";
import { useCallback, useMemo, useState } from "react";
import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";
import { OrDivider } from "@/components/ui/or-divider";
import { useToast } from "@/components/ui/use-toast";
import { useOrganisationActions } from "@/context/OrganisationContext/actions/useOrganisationActions";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventForm, useAnalytics } from "@/hooks/useAnalytics";
import { useClickProps } from "@/hooks/useClickProps";
import { useQueryParams } from "@/hooks/useQueryParams";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

import { ArrayField } from "../../../../../../components/controls/ArrayField";

const inviteItemSchema = z.object({
  role: z.nativeEnum(OrganisationRole),
  email: makeSchemaSafe(z.string().email().max(320)),
});

const createInvitesSchema = z.object({
  invites: z.array(inviteItemSchema),
});

export type CreateInvite = z.infer<typeof createInvitesSchema>;

export const InvitesForm = ({
  afterSubmit,
  showRole = true,
  initialInviteEmails,
}: {
  afterSubmit: (count: number) => void;
  showRole?: boolean;
  initialInviteEmails?: string[];
}) => {
  const { toast } = useToast();
  const { isAdmin } = useOrganisation();

  const initialOrganisationRole = useMemo(
    () => (isAdmin ? OrganisationRole.ADMIN : OrganisationRole.MEMBER),
    [isAdmin],
  );

  const eligibleOrganisationRoles = useMemo(
    () => (isAdmin ? Object.values(OrganisationRole) : [OrganisationRole.MEMBER]),
    [isAdmin],
  );

  const generateDefaultInviteItem = useCallback(
    () => ({
      role: isAdmin ? OrganisationRole.ADMIN : OrganisationRole.MEMBER,
      email: "",
    }),
    [isAdmin],
  );

  const { sendInvites } = useOrganisationActions();

  return (
    <FormUtil
      schema={createInvitesSchema}
      submitTitle="Invite users"
      defaultValues={{
        invites: initialInviteEmails
          ? initialInviteEmails.map((email) => ({ role: initialOrganisationRole, email }))
          : [
              {
                role: initialOrganisationRole,
                email: "",
              },
            ],
      }}
      onSubmit={async (data) => {
        await sendInvites(data.invites);
        toast({ title: "Invites sent" });
        afterSubmit(data.invites.length);
      }}
      render={(form) => (
        <ArrayField
          form={form}
          name="invites"
          generateEmptyDatum={generateDefaultInviteItem}
          minLength={1}
          maxLength={10}
          render={(index) => (
            <>
              <FormFieldUtil
                control={form.control}
                name={`invites.${index}.email`}
                className="flex-grow"
                render={({ field }) => <Input {...field} />}
                isLabelHidden
              />
              {showRole && (
                <FormFieldUtil
                  control={form.control}
                  name={`invites.${index}.role`}
                  className="w-40 flex-shrink-0"
                  render={({ field }) => <SelectUtil {...field} items={eligibleOrganisationRoles} />}
                  isLabelHidden
                />
              )}
            </>
          )}
        />
      )}
    />
  );
};

export const InvitesDialog = () => {
  const { createOrganisationInvite } = useOrganisationActions();
  const shouldInvite = !!useQueryParams().shouldInvite;
  const [open, setOpen] = useState(shouldInvite);
  const { logFormSubmit } = useAnalytics();
  const { toast } = useToast();

  const buttonProps = useClickProps({
    onClick: async () => {
      const inviteLink = await createOrganisationInvite();
      navigator.clipboard.writeText(inviteLink);
      toast({ title: "Invite link copied" });
    },
    buttonText: (
      <div className="flex items-center gap-x-2">
        <Link />
        Copy invite link
      </div>
    ),
    spinnerColor: "black",
  });

  return (
    <Dialog onOpenChange={setOpen} open={open}>
      <DialogTrigger>
        <Button>Invite</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Invite teammates</DialogTitle>
          <DialogDescription>
            We'll send each teammate an email with a link to join your organisation. They'll have to sign up using the
            email you specify. The link will expire in a week.
          </DialogDescription>
        </DialogHeader>
        <InvitesForm
          afterSubmit={(count) => {
            setOpen(false);
            logFormSubmit(EventForm.SEND_INVITES, {
              count,
            });
          }}
        />
        <OrDivider />
        <Button variant="outline" className="flex items-center gap-x-2" {...buttonProps} />
      </DialogContent>
    </Dialog>
  );
};
