import { OauthServiceType } from "@fyxer-ai/shared";
import { DropdownMenuContent } from "@radix-ui/react-dropdown-menu";
import { format } from "date-fns";
import { deleteDoc } from "firebase/firestore";
import { MoreHorizontal, Trash } from "lucide-react";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";

import zoomLogo from "@/assets/company-logos/zoom.png";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { DropdownMenu, DropdownMenuItem, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { useApi } from "@/hooks/useApi";
import { unwrap } from "@/lib/firebase/unwrap";
import { redirectExternally } from "@/lib/redirectExternally";
import { LocalStorageStringKey, storage } from "@/lib/storage";

export const useRecallZoomConnectionRedirect = () => {
  const api = useApi();

  const redirectToAuthUrl = useCallback(async (organisationId: string) => {
    const stateId = uuid();
    storage.local.string(LocalStorageStringKey.OAUTH_STATE_ID).set(stateId);
    const authorizationUrl = await api.recall.getZoomAuthorizationUrl({ organisationId, stateId });
    redirectExternally(authorizationUrl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { redirectToAuthUrl };
};

export const ZoomIntegrationsSection = () => {
  const { organisationId, connections, recallZoomConnections } = useOrganisation();
  const { redirectToAuthUrl } = useRecallZoomConnectionRedirect();

  const hasCalendarConnection = connections.some(
    unwrap((connection) => connection.serviceType === OauthServiceType.CALENDAR),
  );

  if (!hasCalendarConnection) return null;

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-lg">Zoom</CardTitle>
        <CardDescription>
          For our meeting assistant to be able to join Zoom meetings you attend or host, you need to link your Zoom
          account to our meeting assistant provider, Recall.ai. For any other meeting software (Google Meet, Microsoft
          Teams etc) there's no need to connect it, it'll just work.
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        {recallZoomConnections.map((recallZoomConnection) => {
          const { createdAt } = recallZoomConnection.data();
          return (
            <div key={recallZoomConnection.id} className="flex items-center gap-x-4">
              <div className="flex items-center gap-x-2">
                <img src={zoomLogo} alt="Zoom" className="h-8 w-8 rounded-sm" />
                <h4>Zoom</h4>
              </div>
              <div className="flex-grow" />
              <p>Connected {format(createdAt, "do MMM yyyy")}</p>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" className="h-9 w-9 p-0">
                    <MoreHorizontal className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent className="cursor-pointer hover:bg-slate-100">
                  <DropdownMenuItem onClick={async () => deleteDoc(recallZoomConnection.ref)}>
                    <Trash className="mr-2 h-4 w-4" />
                    Delete integration
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        })}
        <Button
          onClick={async () => {
            await redirectToAuthUrl(organisationId);
          }}
          variant={recallZoomConnections.length > 0 ? "secondary" : "default"}
        >
          {recallZoomConnections.length > 0 ? "Connect another" : "Connect"}
        </Button>
      </CardContent>
    </Card>
  );
};
