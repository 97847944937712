import { constructUrl } from "@fyxer-ai/shared";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

import { CenterPage } from "@/components/layout/CenterPage";
import { SpinnerPage } from "@/components/layout/SpinnerPage";
import { TitleBody } from "@/components/layout/TitleBody";
import { useApi } from "@/hooks/useApi";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";
import { useQueryParams } from "@/hooks/useQueryParams";
import { LocalStorageStringKey, storage } from "@/lib/storage";

export const RecallZoomRedirectPage = () => {
  const { code, state } = useQueryParams();
  if (!code || !state) throw new Error("missing code or state");
  if (storage.local.string(LocalStorageStringKey.OAUTH_STATE_ID).get() !== state)
    throw new Error("state doesn't match");
  const api = useApi();
  const registerZoomCredentials = useMutation({
    mutationFn: async () => api.recall.registerZoomOauthCredentials({ code, state }),
  });
  const navigate = useComplexNavigate();

  useEffect(() => {
    registerZoomCredentials.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const zoomRegistrationResponse = registerZoomCredentials.data;

  if (!zoomRegistrationResponse) return <SpinnerPage />;

  const { organisationId, success } = zoomRegistrationResponse;

  if (!success)
    return (
      <CenterPage>
        <TitleBody
          title="Zoom account conflict"
          bodyLines={["The Zoom connection failed because you appear to have already connected this Zoom account."]}
          actions={[
            {
              title: "Go back",
              fn: () => navigate({ path: `/org/${organisationId}/settings`, hash: { tab: "integrations" } }),
            },
          ]}
        />
      </CenterPage>
    );

  return (
    <Navigate
      to={constructUrl({
        path: `/org/${organisationId}/settings`,
        hash: { tab: "integrations" },
      })}
    />
  );
};
