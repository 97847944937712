import { LabelName } from "@fyxer-ai/shared";

// import { Textarea } from "@/components/ui/textarea";
export const labelData = [
  {
    labelName: LabelName.TO_RESPOND,
    criteria: "Emails you need to respond to",
    color: "bg-red-300",
  },
  {
    labelName: LabelName.FYI,
    criteria: "Emails that don't require your response, but are important",
    color: "bg-orange-300",
  },
  {
    labelName: LabelName.COMMENT,
    criteria: "Team chats in tools like Google Docs or Microsoft Office",
    color: "bg-yellow-200",
  },
  {
    labelName: LabelName.NOTIFICATION,
    criteria: "Automated updates from tools you use",
    color: "bg-green-300",
  },
  {
    labelName: LabelName.MEETING_UPDATE,
    criteria: "Calendar updates from Zoom, Google Meet, etc",
    color: "bg-blue-300",
  },
  {
    labelName: LabelName.AWAITING_REPLY,
    criteria: "Emails you've sent that you're expecting a reply to",
    color: "bg-indigo-300",
  },
  {
    labelName: LabelName.ACTIONED,
    criteria: "Emails you've sent that you're not expecting a reply to",
    color: "bg-purple-300",
  },
  {
    labelName: LabelName.MARKETING,
    criteria: "Marketing or cold emails",
    color: "bg-pink-300",
  },
];
