import { repeat } from "@fyxer-ai/shared";

import { Skeleton } from "@/components/ui/skeleton";

export const LoadingDashboard = () => (
  <div className="flex h-full w-full">
    <div className="w-64 border-r-2 border-gray-100">
      <div className="mb-4 box-border h-12 rounded-xl px-4 py-2">
        <Skeleton className="h-full" />
      </div>
      <div className="space-y-3 px-4">
        {repeat(4, (i) => (
          <Skeleton key={i} className="h-6 rounded-xl" />
        ))}
      </div>
    </div>
    <div className="flex flex-grow flex-col">
      <div className="flex h-12 items-center gap-2 border-b-2 border-gray-100 px-4">
        <Skeleton className="h-6 w-20 rounded-full" />
        <div className="flex-grow"></div>
        <Skeleton className="h-9 w-9 rounded-full" />
      </div>
      <div className="flex-grow px-8 py-12">
        <div className="space-y-8">
          <div className="flex gap-3">
            {repeat(3, (i) => (
              <Skeleton key={i} className="h-4 w-10 rounded-full" />
            ))}
          </div>
          <Skeleton className="h-12 w-[300px] rounded-full" />
          <div className="grid grid-cols-3 gap-8">
            {repeat(6, (i) => (
              <Skeleton key={i} className="h-40 rounded-3xl" />
            ))}
          </div>
        </div>
      </div>
    </div>
  </div>
);
