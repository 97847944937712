import { useCallRecordingNullable } from "./useCallRecordingNullable";

export const useCallRecording = () => {
  const {
    callRecordingId: { value: callRecordingId, isLoading: isCallRecordingIdLoading },
    callRecording: { value: callRecording, isLoading: isCallRecordingLoading },
    callAnalysis: { value: callAnalysis, isLoading: isCallAnalysisLoading },
    callSummary: { value: callSummary, isLoading: isCallSummaryLoading },
    transcriptSegments: { value: transcriptSegments, isLoading: areTranscriptSegmentsLoading },
    callRecordingSnippets: { value: callRecordingSnippets, isLoading: areCallRecordingSnippetsLoading },
  } = useCallRecordingNullable();

  if (isCallRecordingIdLoading) throw new Error("call recording id is still loading");
  if (isCallRecordingLoading) throw new Error("call recording is still loading");
  if (isCallAnalysisLoading) throw new Error("call analysis is still loading");
  if (isCallSummaryLoading) throw new Error("call summary is still loading");
  if (areTranscriptSegmentsLoading) throw new Error("transcript segments are still loading");
  if (areCallRecordingSnippetsLoading) throw new Error("call recording snippets are still loading");

  if (callRecordingId === undefined) throw new Error("call recording id is missing");
  if (callRecording === undefined) throw new Error("call recording is missing");
  if (transcriptSegments === undefined) throw new Error("transcript segments are missing");
  if (callRecordingSnippets === undefined) throw new Error("call recording snippets are missing");
  // call analysis and summary can be missing if the call analysis wasn't completed - eg if there's no speech
  // we should probably have a flag on call recordings to make them not show up if the processing failed?

  const title = callRecording.calendarEvent?.title ?? "Untitled";
  const startsAt = callRecording.calendarEvent?.startsAt;
  const endsAt = callRecording.calendarEvent?.endsAt;
  const attendees = callRecording.calendarEvent?.attendees;

  return {
    callRecordingId,
    callRecording,
    callAnalysis,
    callSummary,
    transcriptSegments,
    callRecordingSnippets,
    title,
    startsAt,
    endsAt,
    attendees,
  };
};
