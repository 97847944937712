import { sort } from "@fyxer-ai/shared";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { doc, updateDoc } from "firebase/firestore";
import { Edit, MoreHorizontal, Trash } from "lucide-react";
import { useState } from "react";
import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { FormUtil } from "@/components/controls/FormUtil";
import { TabsUtil } from "@/components/controls/TabsUtil";
import { Spinner } from "@/components/layout/SpinnerPage";
import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { useToast } from "@/components/ui/use-toast";
import { useBase } from "@/context/BaseContext/state/useBase";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useApi } from "@/hooks/useApi";
import { Collection } from "@/lib/firebase/Collection";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";

import { formatTimeInterval } from "../../tabs/UpcomingCallsTab";
import { EditAccessSettingDialog } from "./EditAccessSettingDialog";
import { RecordingTab } from "./tabs/recordings/RecordingsTab";
import { SummaryTab } from "./tabs/SummaryTab";
import { TranscriptTab } from "./tabs/transcript/TranscriptTab";

export enum CallRecordingTabName {
  TRANSCRIPT = "transcript",
  SUMMARY = "summary",
  RECORDING = "recording",
}

const EditCallRecordingTitleDialog = () => {
  const [open, setOpen] = useState(false);
  const { callRecordingId, title } = useCallRecording();
  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="ghost" size="icon">
          <Edit color="black" size={16} />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Edit call recording title</DialogTitle>
        </DialogHeader>
        <FormUtil
          schema={z.object({ title: makeSchemaSafe(z.string().min(1).max(600)) })}
          defaultValues={{ title }}
          render={(form) => (
            <FormFieldUtil control={form.control} name="title" render={({ field }) => <Input {...field} />} />
          )}
          onSubmit={async ({ title }) => {
            await updateDoc(doc(Collection.CallRecording, callRecordingId), { "calendarEvent.title": title });
            setOpen(false);
          }}
          submitTitle="Update title"
        />
      </DialogContent>
    </Dialog>
  );
};

const CalendarInfoView = ({ timeZone = "Europe/London" }: { timeZone?: string }) => {
  const { attendees, title, startsAt, endsAt } = useCallRecording();
  const attendeeNames = sort(
    attendees?.flatMap(({ name, email }) => (name ? [name] : [email])) ?? [],
    (name) => name,
    "asc",
  );

  return (
    <div className="flex-shrink flex-grow space-y-4">
      <div className="space-y-1">
        <div className="flex items-center gap-x-2">
          <h2 className="overflow-hidden text-ellipsis whitespace-nowrap text-lg">{title}</h2>
          <EditCallRecordingTitleDialog />
        </div>

        {startsAt && endsAt && (
          <p className="text-sm text-slate-500">{formatTimeInterval({ startsAt, endsAt }, timeZone)}</p>
        )}
      </div>

      {attendeeNames.length > 0 ? (
        <div className="relative -mx-1 h-8 w-full">
          <div className="hide-scrollbar absolute left-0 right-0 z-0 flex w-full items-center gap-2 overflow-scroll px-1">
            {attendeeNames.map((name) => (
              <p key={name} className="rounded-2xl bg-slate-100 px-2 py-1.5 text-sm">
                {name}
              </p>
            ))}
          </div>
          <div className="to-clear absolute left-0 top-0 z-10 h-full w-4 bg-gradient-to-r from-white" />
          <div className="to-clear absolute right-0 top-0 z-10 h-full w-4 bg-gradient-to-l from-white" />
        </div>
      ) : null}
    </div>
  );
};

const useCanDeleteCallRecording = () => {
  const { callRecording } = useCallRecording();
  const { emailsWithAccess } = callRecording;
  const { user } = useBase();
  const userEmail = user.value?.email;
  return userEmail && emailsWithAccess.includes(userEmail);
};

const DeleteCallRecordingOption = () => {
  const api = useApi();
  const { toast } = useToast();
  const { callRecordingId } = useCallRecording();

  const deleteCallRecording = useMutation({
    mutationFn: async ({ callRecordingId }: { callRecordingId: string }) => {
      await api.callRecordings.id(callRecordingId).delete();
    },
    onSuccess: () => {
      toast({ title: "Call recording deleted" });
    },
    onError: (err) => {
      if (err instanceof AxiosError && err.response?.status === 403) {
        toast({ title: "You do not have permission to delete this call recording" });
        return;
      }
      toast({ title: "Failed to delete call recording" });
    },
  });

  return (
    <DropdownMenuItem
      className="flex items-center gap-x-2"
      onClick={() => deleteCallRecording.mutate({ callRecordingId })}
    >
      {deleteCallRecording.isPending ? <Spinner size={16} /> : <Trash size={16} />}
      <span>{deleteCallRecording.isPending ? "Deleting..." : "Delete"}</span>
    </DropdownMenuItem>
  );
};

export const CallRecordingView = ({ timeZone = "Europe/London" }: { timeZone?: string }) => {
  const canDelete = useCanDeleteCallRecording();

  return (
    <>
      <div className="relative flex w-full items-center gap-x-4">
        <CalendarInfoView timeZone={timeZone} />
        <EditAccessSettingDialog />
        {canDelete && (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon">
                <MoreHorizontal size={24} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DeleteCallRecordingOption />
            </DropdownMenuContent>
          </DropdownMenu>
        )}
      </div>

      <TabsUtil
        className="flex-shrink flex-grow overflow-scroll"
        tabs={[
          { value: CallRecordingTabName.RECORDING, content: <RecordingTab /> },
          { value: CallRecordingTabName.SUMMARY, content: <SummaryTab /> },
          { value: CallRecordingTabName.TRANSCRIPT, content: <TranscriptTab /> },
        ]}
      />
    </>
  );
};
