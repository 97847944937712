import { CallRecording, deduplicate, groupBy, sort, unzip } from "@fyxer-ai/shared";
import { format, formatISO, parseISO, startOfDay } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { QueryDocumentSnapshot } from "firebase/firestore";
import { CaseSensitive, Eye, EyeOff, X } from "lucide-react";
import { useId, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { MultiSelectUtil } from "@/components/controls/MultiSelectUtil";
import { SelectUtil } from "@/components/controls/SelectUtil";
import { Button } from "@/components/ui/button";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { Toggle } from "@/components/ui/toggle";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { useCallRecordings } from "@/context/CallRecordingsContext/state/useCallRecordings";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { useUpdateState } from "@/hooks/useUpdateState";
import { unwrap } from "@/lib/firebase/unwrap";

import { formatTimeInterval } from "./UpcomingCallsTab";

enum CallRecordingDisplayType {
  SEEN = "Seen",
  SHARED = "Shared",
  ALL = "All",
}

type CallRecordingsFilterState = {
  shouldFilterTitle: boolean;
  shouldFilterAttendees: boolean;
  isTitleFilterCaseSensitive: boolean;
  attendeeEmailToFilterFor: string[];
  titleToFilterFor: string;
  displayType: CallRecordingDisplayType;
};

const initialCallRecordingsFilterState: CallRecordingsFilterState = {
  shouldFilterTitle: false,
  shouldFilterAttendees: false,
  isTitleFilterCaseSensitive: false,
  attendeeEmailToFilterFor: [],
  titleToFilterFor: "",
  displayType: CallRecordingDisplayType.SEEN,
};

const CallRecordingsFilterView = ({
  state,
  updateState,
  allAttendeeEmails,
}: {
  state: CallRecordingsFilterState;
  updateState: (update: Partial<CallRecordingsFilterState>) => void;
  allAttendeeEmails: string[];
}) => {
  const titleInputId = useId();
  const attendeesInputId = useId();
  const [isHidden, setIsHidden] = useState(false);
  const VisibilityIcon = isHidden ? EyeOff : Eye;
  return (
    <div className="space-y-4">
      <div className="flex items-center gap-x-2">
        <span className="text-sm text-slate-500">Filter on</span>
        <Toggle
          pressed={state.shouldFilterTitle}
          onPressedChange={(pressed) => {
            updateState({ shouldFilterTitle: pressed });
            setIsHidden(false);
          }}
        >
          Title
        </Toggle>
        <Toggle
          pressed={state.shouldFilterAttendees}
          onPressedChange={(pressed) => {
            updateState({ shouldFilterAttendees: pressed });
            setIsHidden(false);
          }}
        >
          Attendees
        </Toggle>
        <SelectUtil
          className="w-[200px]"
          value={state.displayType}
          onChange={(value) => updateState({ displayType: value })}
          items={[
            { value: CallRecordingDisplayType.SEEN, label: "Calls I attended" },
            { value: CallRecordingDisplayType.SHARED, label: "Calls shared with me" },
            { value: CallRecordingDisplayType.ALL, label: "All calls" },
          ]}
        />
        <div className="flex-grow" />
        {state.shouldFilterTitle || state.shouldFilterAttendees ? (
          <Tooltip>
            <TooltipTrigger asChild>
              <Toggle pressed={isHidden} onPressedChange={setIsHidden}>
                <VisibilityIcon className="h-4 w-4 stroke-slate-500" />
              </Toggle>
            </TooltipTrigger>
            <TooltipContent>
              <p>{isHidden ? "Show" : "Hide"} filters</p>
            </TooltipContent>
          </Tooltip>
        ) : null}
      </div>

      <div className="max-w-xl space-y-4">
        {state.shouldFilterTitle && !isHidden && (
          <div className="flex items-center gap-x-2">
            <Label htmlFor={titleInputId} className="w-[72px] flex-shrink-0 text-sm text-slate-500">
              Title
            </Label>
            <Input
              id={titleInputId}
              value={state.titleToFilterFor}
              onChange={(event) => updateState({ titleToFilterFor: event.target.value })}
            />
            <Tooltip>
              <TooltipTrigger>
                <Toggle
                  pressed={state.isTitleFilterCaseSensitive}
                  onPressedChange={(pressed) => updateState({ isTitleFilterCaseSensitive: pressed })}
                >
                  <CaseSensitive className="h-4 w-4 stroke-slate-500" />
                </Toggle>
              </TooltipTrigger>
              <TooltipContent>
                <p>Case sensitive</p>
              </TooltipContent>
            </Tooltip>

            <Button
              variant="ghost"
              size="icon"
              className="flex-shrink-0"
              onClick={() => updateState({ titleToFilterFor: "", shouldFilterTitle: false })}
            >
              <X className="h-4 w-4 stroke-slate-500" />
            </Button>
          </div>
        )}

        {state.shouldFilterAttendees && !isHidden && (
          <div className="flex items-center gap-x-2">
            <Label htmlFor={attendeesInputId} className="w-[72px] flex-shrink-0 text-sm text-slate-500">
              Attendees
            </Label>
            <MultiSelectUtil
              className="flex-grow"
              value={state.attendeeEmailToFilterFor}
              onValueChange={(value) => updateState({ attendeeEmailToFilterFor: value })}
              placeholder="Select attendees"
              items={allAttendeeEmails.map((email) => ({ value: email, label: email }))}
            />
            <Button
              variant="ghost"
              size="icon"
              className="flex-shrink-0"
              onClick={() => updateState({ attendeeEmailToFilterFor: [], shouldFilterAttendees: false })}
            >
              <X className="h-4 w-4 stroke-slate-500" />
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

const filterCallRecordings = ({
  callRecordings,
  shouldFilterAttendees: enabledAttendeesFilter,
  shouldFilterTitle: enabledTitleFilter,
  attendeeEmailToFilterFor,
  titleToFilterFor,
  isTitleFilterCaseSensitive,
}: Omit<CallRecordingsFilterState, "displayType"> & {
  callRecordings: QueryDocumentSnapshot<CallRecording>[];
}) => {
  const shouldFilterTitle = enabledTitleFilter && titleToFilterFor.length > 0;
  const shouldFilterAttendees = enabledAttendeesFilter && attendeeEmailToFilterFor.length > 0;
  if (!shouldFilterTitle && !shouldFilterAttendees) return callRecordings;
  const titleSearchTerm = isTitleFilterCaseSensitive ? titleToFilterFor : titleToFilterFor.toLowerCase();
  const filterTitle = isTitleFilterCaseSensitive
    ? (callRecording: CallRecording) => callRecording.calendarEvent?.title?.includes(titleSearchTerm)
    : (callRecording: CallRecording) => callRecording.calendarEvent?.title?.toLowerCase()?.includes(titleSearchTerm);

  const filterAttendees = (callRecording: CallRecording) =>
    attendeeEmailToFilterFor.every((attendeeEmail) =>
      callRecording.calendarEvent?.attendeeEmails?.map((email) => email.toLowerCase()).includes(attendeeEmail),
    );
  if (shouldFilterTitle && shouldFilterAttendees)
    return callRecordings.filter(
      unwrap((callRecording) => filterAttendees(callRecording) && filterTitle(callRecording)),
    );
  if (shouldFilterTitle) return callRecordings.filter(unwrap(filterTitle));
  if (shouldFilterAttendees) return callRecordings.filter(unwrap(filterAttendees));
  throw new Error("Filter permutation unaccounted for");
};

export const CallRecordingsTab = () => {
  const { callRecordings, callRecordingsSeenByUser, callRecordingsSharedWithUser } = useCallRecordings();
  const { timeZone, organisationId } = useOrganisation();
  const [callRecordingsFilterState, updateCallRecordingsFilterState] = useUpdateState<CallRecordingsFilterState>(
    initialCallRecordingsFilterState,
  );

  const callRecordingsToDisplay =
    callRecordingsFilterState.displayType === "Seen"
      ? callRecordingsSeenByUser
      : callRecordingsFilterState.displayType === "Shared"
        ? callRecordingsSharedWithUser
        : callRecordings;

  const {
    shouldFilterAttendees,
    shouldFilterTitle,
    titleToFilterFor,
    attendeeEmailToFilterFor,
    isTitleFilterCaseSensitive,
  } = callRecordingsFilterState;

  const filteredCallRecordings = useMemo(
    () =>
      filterCallRecordings({
        shouldFilterAttendees,
        shouldFilterTitle,
        titleToFilterFor,
        attendeeEmailToFilterFor,
        isTitleFilterCaseSensitive,
        callRecordings: callRecordingsToDisplay,
      }),
    [
      callRecordingsToDisplay,
      shouldFilterAttendees,
      shouldFilterTitle,
      titleToFilterFor,
      attendeeEmailToFilterFor,
      isTitleFilterCaseSensitive,
    ],
  );

  const groupsOfCallRecordingDataByDay = unzip(
    groupBy(filteredCallRecordings, (callRecording) => {
      const referenceDateUtc = callRecording.data().calendarEvent?.startsAt ?? callRecording.data().createdAt;
      const referenceDateLocal = toZonedTime(referenceDateUtc, timeZone);
      return formatISO(startOfDay(referenceDateLocal));
    }),
  );

  const allAttendeeEmails = useMemo(
    () =>
      sort(
        deduplicate(
          callRecordings
            .flatMap((callRecording) => callRecording.data().calendarEvent?.attendeeEmails ?? [])
            .map((email) => email.toLowerCase()),
        ),
        (x) => x,
        "asc",
      ),
    [callRecordings],
  );

  return (
    <div className="relative h-full space-y-8">
      <CallRecordingsFilterView
        state={callRecordingsFilterState}
        updateState={updateCallRecordingsFilterState}
        allAttendeeEmails={allAttendeeEmails}
      />
      {groupsOfCallRecordingDataByDay.length === 0 ? (
        callRecordings.length ? (
          <p>No recordings yet</p>
        ) : (
          <p>No results found</p>
        )
      ) : (
        groupsOfCallRecordingDataByDay.map(({ key: startOfDayIsoString, value: data }) => {
          const startOfDayLocal = parseISO(startOfDayIsoString);
          const formattedStartOfDay = format(startOfDayLocal, "EEEE, d MMMM");

          return (
            <div key={startOfDayIsoString} className="space-y-4">
              <p className="box-border px-2 text-sm text-slate-500">{formattedStartOfDay}</p>
              <div className="space-y-2">
                {data.map((callRecording) => {
                  const title = callRecording.data().calendarEvent?.title ?? "Untitled";
                  const startsAt = callRecording.data().calendarEvent?.startsAt;
                  const endsAt = callRecording.data().calendarEvent?.endsAt;
                  const formattedTime =
                    startsAt && endsAt
                      ? formatTimeInterval({ startsAt, endsAt }, timeZone, false)
                      : "Created " + format(callRecording.data().createdAt, "HH:mm");
                  return (
                    <Link
                      key={callRecording.id}
                      className="cursor-pointed block space-y-1 rounded-2xl border border-slate-500 bg-slate-50 p-4 hover:bg-slate-100"
                      to={`/org/${organisationId}/meeting-assistant/call-recordings/${callRecording.id}`}
                    >
                      <h4>{title}</h4>
                      <p className="text-sm text-slate-500">{formattedTime}</p>
                    </Link>
                  );
                })}
              </div>
            </div>
          );
        })
      )}
    </div>
  );
};
