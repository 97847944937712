import { toHeaderCase } from "js-convert-case";
import { ArrowDown } from "lucide-react";
import { ReactNode } from "react";

import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { useBreakpoint } from "@/hooks/useWindowSize";
import { cn } from "@/lib/utils";

import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from "../ui/dropdown-menu";

type TabDatum = { value: string; label?: string; content: ReactNode };

const getTabName = (datum: TabDatum) => datum.label ?? toHeaderCase(datum.value);

export const TabsUtil = ({
  tabs,
  tab,
  className,
  children,
}: {
  tabs: { value: string; label?: string; content: ReactNode }[];
  tab?: string;
  className?: string;
  children?: ReactNode;
}) => {
  const breakpoint = useBreakpoint();

  const strongTab = tab ?? tabs[0].value;

  return (
    <Tabs className={cn("relative flex w-full flex-col", className)} defaultValue={tabs[0].value}>
      <div className="to-clear absolute left-0 top-12 h-8 w-full bg-gradient-to-b from-white max-sm:hidden"></div>
      <TabsList
        className="grid w-full flex-shrink-0 flex-grow-0"
        style={{
          gridTemplateColumns: breakpoint === "xs" ? "1fr" : `repeat(${tabs.length}, minmax(0, 1fr))`,
        }}
      >
        {breakpoint === "xs" ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild className="w-full">
              <div className="block flex h-full w-full items-center justify-center gap-x-2 bg-white">
                {getTabName(tabs.find((t) => t.value === strongTab) as TabDatum)}
                <ArrowDown size={16} />
              </div>
            </DropdownMenuTrigger>
            <DropdownMenuContent asChild className="w-full">
              <div className="w-full">
                {tabs.map((tab) => (
                  <DropdownMenuItem key={`tab-${tab.value}`}>
                    <TabsTrigger
                      className="w-full data-[state=active]:bg-slate-100 data-[state=active]:shadow-none"
                      value={tab.value}
                    >
                      {getTabName(tab)}
                    </TabsTrigger>
                  </DropdownMenuItem>
                ))}
              </div>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : (
          tabs.map((tab) => (
            <TabsTrigger key={tab.value} value={tab.value}>
              {getTabName(tab)}
            </TabsTrigger>
          ))
        )}
      </TabsList>

      {tabs.map(({ content, value }) => (
        <TabsContent key={value} value={value} className="mt-0 flex-shrink flex-grow py-8 sm:overflow-scroll">
          {content}
          {children}
        </TabsContent>
      ))}
    </Tabs>
  );
};
