import { CallRecordingSnippet, constructUrl } from "@fyxer-ai/shared";
import { doc, QueryDocumentSnapshot, updateDoc } from "firebase/firestore";
import { Play } from "lucide-react";
import { useState } from "react";
import { Link } from "react-router-dom";

import { CopyIconButton } from "@/components/controls/CopyUtil";
import { Button } from "@/components/ui/button";
import { Dialog, DialogTrigger } from "@/components/ui/dialog";
import { useBase } from "@/context/BaseContext/state/useBase";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useQueryParams } from "@/hooks/useQueryParams";
import { config } from "@/lib/config";
import { Collection } from "@/lib/firebase/Collection";
import { unwrap } from "@/lib/firebase/unwrap";

import { StorageVideoPlayer } from "../../../../../../../../components/StorageVideoPlayer";
// import { CallRecordingTabName } from "../../CallRecordingView";
import { EditAccessSettingDialogContent } from "../../EditAccessSettingDialog";
import { formatMilliseconds } from "../transcript/formatMilliseconds";
// import { SwitchTabLink } from "../transcript/SwitchTabLink";
import { InsightsView } from "./InsightsView";

const constructCallRecordingSnippetLink = (callRecordingSnippetId: string) =>
  constructUrl({ base: config().CLIENT_URL, path: `/call-recording-snippets/${callRecordingSnippetId}` });

const SnippetRow = ({
  callRecordingSnippet,
  setCallRecordingSnippetIdToEdit,
}: {
  callRecordingSnippet: QueryDocumentSnapshot<CallRecordingSnippet>;
  setCallRecordingSnippetIdToEdit: (snippetId: string) => void;
}) => {
  const { title, startMs, endMs } = callRecordingSnippet.data();
  const timeFormatted = `${formatMilliseconds(startMs)} - ${formatMilliseconds(endMs)}`;

  const link = constructCallRecordingSnippetLink(callRecordingSnippet.id);

  return (
    <div className="flex items-center gap-x-4">
      <h4>{title}</h4>
      <div className="flex-grow" />
      <p className="text-sm text-slate-500">{timeFormatted}</p>
      <DialogTrigger asChild>
        <Button variant="secondary" onClick={() => setCallRecordingSnippetIdToEdit(callRecordingSnippet.id)}>
          Edit permissions
        </Button>
      </DialogTrigger>
      <CopyIconButton value={link} />
      <Link to={link} target="_blank" rel="noreferrer">
        <Button size="icon">
          <Play size={16} color="white" />
        </Button>
      </Link>
    </div>
  );
};

const SnippetsView = () => {
  const { user } = useBase();
  const { callRecordingSnippets, callRecording } = useCallRecording();
  const { shouldEditSnippetId } = useQueryParams();
  const [snippetIdToEditSharingPermissionsFor, setSnippetIdToEditSharingPermissionsFor] = useState(shouldEditSnippetId);
  const eligibleSnippets = callRecordingSnippets.filter(unwrap((snippet) => snippet.progress === 1));
  if (eligibleSnippets.length === 0) return null;

  const callRecordingSnippetToEdit = callRecordingSnippets.find(
    (snippet) => snippet.id === snippetIdToEditSharingPermissionsFor,
  );
  const accessSetting = callRecordingSnippetToEdit?.data().accessSetting ?? callRecording.accessSetting;
  const emailsWithAccess =
    callRecordingSnippetToEdit?.data().emailsWithAccess ??
    callRecording.emailsWithAccess ??
    (user.value?.email ? [user.value.email] : []);

  return (
    <Dialog
      open={!!snippetIdToEditSharingPermissionsFor}
      onOpenChange={(open) => {
        if (!open) setSnippetIdToEditSharingPermissionsFor(undefined);
      }}
    >
      <div className="space-y-4">
        <h3>Snippets</h3>
        {eligibleSnippets.map((callRecordingSnippet) => (
          <SnippetRow
            key={callRecordingSnippet.id}
            callRecordingSnippet={callRecordingSnippet}
            setCallRecordingSnippetIdToEdit={setSnippetIdToEditSharingPermissionsFor}
          />
        ))}
      </div>
      <EditAccessSettingDialogContent
        title="Share call recording snippet"
        defaultValues={{ accessSetting, emailsWithAccess }}
        onSubmit={async (data) => {
          if (!snippetIdToEditSharingPermissionsFor) return;
          await updateDoc(doc(Collection.CallRecordingSnippet, snippetIdToEditSharingPermissionsFor), data);
          setSnippetIdToEditSharingPermissionsFor(undefined);
        }}
        shareLink={
          snippetIdToEditSharingPermissionsFor
            ? constructCallRecordingSnippetLink(snippetIdToEditSharingPermissionsFor)
            : ""
        }
      />
    </Dialog>
  );
};

export const RecordingTab = () => {
  const { callRecording } = useCallRecording();
  const { storagePath } = callRecording;
  // const { authUser } = useBase();
  // const isAuthenticated = !!authUser.value;

  return (
    <>
      <div className="space-y-12 pb-12">
        <StorageVideoPlayer storagePath={storagePath} className="w-full" />
        <SnippetsView />
        <InsightsView />
      </div>
      {/* {isAuthenticated && (
        <div className="absolute bottom-0 left-0 flex items-center gap-x-2 rounded-t-2xl border border-b-0 border-black bg-white p-2">
          <SwitchTabLink
            tab={CallRecordingTabName.TRANSCRIPT}
            search={{ shouldCreateSnippet: true }}
            className="flex-shrink-0"
          >
            <Button variant="secondary">Create call snippet</Button>
          </SwitchTabLink>
        </div>
      )} */}
    </>
  );
};
