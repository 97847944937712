import { useMutation } from "@tanstack/react-query";
import { addDoc } from "firebase/firestore";
import { useEffect } from "react";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import { useQueryParams } from "@/hooks/useQueryParams";
import { Collection } from "@/lib/firebase/Collection";

export const MeetingAssistantVetoPage = () => {
  const { email, providerEventId, title } = useQueryParams();

  if (!email) throw new Error("No email provided");
  if (!title) throw new Error("No title provided");
  if (!providerEventId) throw new Error("Could not identify meeting to veto");

  const createVeto = useMutation({
    mutationFn: async ({ email, providerEventId }: { email: string; providerEventId: string }) => {
      await addDoc(Collection.MeetingAssistantVeto, { email, providerEventId, createdAt: new Date(), title });
    },
  });

  useEffect(() => {
    createVeto.mutate({ email, providerEventId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, providerEventId]);

  if (createVeto.error) return <p>{createVeto.error.message}</p>;
  if (createVeto.isSuccess) return <p>Meeting recording vetoed</p>;

  return <LoadingDashboard />;
};
