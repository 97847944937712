import { CallRecordingSnippet, constructUrl } from "@fyxer-ai/shared";
import { useMutation } from "@tanstack/react-query";
import { doc, FirestoreError, onSnapshot } from "firebase/firestore";
import { createContext, ReactNode, useCallback, useEffect, useState } from "react";
import { Navigate, useNavigate, useParams } from "react-router-dom";

import { CenterPage } from "@/components/layout/CenterPage";
import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import { Spinner } from "@/components/layout/SpinnerPage";
import { Button } from "@/components/ui/button";
import { useToast } from "@/components/ui/use-toast";
import { useApi } from "@/hooks/useApi";
import { useUpdateState } from "@/hooks/useUpdateState";
import { Collection } from "@/lib/firebase/Collection";
import { emptyLoadedValue, LoadedValue } from "@/types/LoadedValue";

import { useBase } from "../BaseContext/state/useBase";

type CallRecordingSnippetState = {
  callRecordingSnippetId: LoadedValue<string>;
  callRecordingSnippet: LoadedValue<CallRecordingSnippet>;
};

const initialCallRecordingSnippetState: CallRecordingSnippetState = {
  callRecordingSnippetId: emptyLoadedValue,
  callRecordingSnippet: emptyLoadedValue,
};

export const CallRecordingSnippetContext = createContext<CallRecordingSnippetState>(initialCallRecordingSnippetState);

const AuthedNoPermissionView = ({ callRecordingSnippetId }: { callRecordingSnippetId: string }) => {
  const api = useApi();
  const { toast } = useToast();
  const navigate = useNavigate();

  const sendAccessRequest = useMutation({
    mutationFn: async () => api.callRecordingSnippets.id(callRecordingSnippetId).sendAccessRequest(),
    onSuccess: () => toast({ title: "Access request sent" }),
    onError: (error) => toast({ title: "Failed to send access request", description: error.message }),
  });

  return (
    <CenterPage>
      <div className="space-y-8">
        <h2>Permisson denied</h2>
        <div className="space-y-2">
          <p className="text-slate-500">You don't have permission to view this recording.</p>
          <p className="text-slate-500">If you know the organiser, send them an access request.</p>
        </div>

        <div className="space-y-2">
          <Button onClick={() => sendAccessRequest.mutate()} className="w-full">
            {sendAccessRequest.isPending ? <Spinner /> : "Send access request"}
          </Button>
          <Button variant="secondary" onClick={() => navigate("/")} className="w-full">
            Go back
          </Button>
        </div>
      </div>
    </CenterPage>
  );
};

export const CallRecordingSnippetProvider = ({ children }: { children: ReactNode }) => {
  const { callRecordingSnippetId } = useParams();

  if (!callRecordingSnippetId) throw new Error("call recording snippet id is missing");

  const [state, updateState] = useUpdateState<CallRecordingSnippetState>({
    ...initialCallRecordingSnippetState,
    callRecordingSnippetId: { value: callRecordingSnippetId, isLoading: false },
  });

  const { authUser } = useBase();
  const [isPermissionDenied, setIsPermissionDenied] = useState(false);

  const setIsPermissionDeniedIfNeeded = useCallback((error: FirestoreError) => {
    if (error.code !== "permission-denied") return;
    setIsPermissionDenied(true);
  }, []);

  useEffect(() => {
    updateState({
      callRecordingSnippetId: { value: callRecordingSnippetId, isLoading: false },
      callRecordingSnippet: emptyLoadedValue,
    });

    const callRecordingSnippetUnsub = onSnapshot(
      doc(Collection.CallRecordingSnippet, callRecordingSnippetId),
      (doc) => updateState({ callRecordingSnippet: { value: doc.data(), isLoading: false } }),
      setIsPermissionDeniedIfNeeded,
    );

    return () => {
      callRecordingSnippetUnsub();
    };
  }, [updateState, callRecordingSnippetId, setIsPermissionDeniedIfNeeded]);

  if (isPermissionDenied) {
    if (authUser.isLoading) return <LoadingDashboard />;
    if (!authUser.value)
      return (
        <Navigate
          to={constructUrl({
            path: "/auth/sign-up",
            search: {
              return_path: location.pathname,
            },
          })}
        />
      );

    return <AuthedNoPermissionView callRecordingSnippetId={callRecordingSnippetId} />;
  }

  return <CallRecordingSnippetContext.Provider value={state}>{children}</CallRecordingSnippetContext.Provider>;
};
