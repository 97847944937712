import "@fontsource-variable/dm-sans";
import "@fontsource/dm-mono/400.css";
import "./index.css";

import ReactDOM from "react-dom/client";
import {
  createRoutesFromChildren,
  matchRoutes,
  RouterProvider,
  useLocation,
  useNavigationType,
} from "react-router-dom";

import { router } from "./router.tsx";
import * as Sentry from "@sentry/react";
import { config } from "./lib/config.ts";
import { Environment } from "@fyxer-ai/shared";
import { getEnvironment } from "./lib/getEnvironment.ts";
import { useEffect } from "react";

Sentry.init({
  dsn: "https://64bca9e988bce2d61c8c0fe9d9eecae9@o4508377882886144.ingest.de.sentry.io/4508377886163024",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", config().SERVER_URL],
  // Session Replay
  replaysSessionSampleRate: getEnvironment() === Environment.PROD ? 0.05 : 1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(<RouterProvider router={router} />);
