export const config = () => ({
  SERVER_URL: import.meta.env.VITE_SERVER_URL,
  CLIENT_URL: import.meta.env.VITE_CLIENT_URL,
  FIREBASE_CONFIG: JSON.parse(import.meta.env.VITE_FIREBASE_CONFIG),
  RECAPTCHA_ENTERPRISE_SITE_KEY: import.meta.env.VITE_RECAPTCHA_ENTERPRISE_SITE_KEY,
  RECAPTCHA_V3_SITE_KEY: import.meta.env.VITE_RECAPTCHA_V3_SITE_KEY,
  INTERCOM_APP_ID: import.meta.env.VITE_INTERCOM_APP_ID,
  POSTHOG_TOKEN: import.meta.env.VITE_POSTHOG_TOKEN,
  MARKETING_URL: import.meta.env.VITE_MARKETING_URL,
  GTM_ID: import.meta.env.VITE_GTM_ID,
});
