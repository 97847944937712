import { CalendarEvent } from "@fyxer-ai/shared";
import { addWeeks } from "date-fns";
import { onSnapshot, orderBy, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { createContext, ReactNode, useEffect } from "react";

import { useUpdateState } from "@/hooks/useUpdateState";
import { Collection } from "@/lib/firebase/Collection";
import { emptyLoadedValue, LoadedValue } from "@/types/LoadedValue";

import { useBase } from "../BaseContext/state/useBase";
import { useValidateOrganisationId } from "../OrganisationContext/useValidateOrganisationId";

type CalendarEventsState = {
  calendarEvents: LoadedValue<QueryDocumentSnapshot<CalendarEvent>[]>;
};

const initialCalendarEventsState: CalendarEventsState = {
  calendarEvents: emptyLoadedValue,
};

export const CalendarEventsContext = createContext<CalendarEventsState>(initialCalendarEventsState);

export const CalendarEventsProvider = ({ children }: { children: ReactNode }) => {
  const userId = useBase().authUser.value?.uid;
  const { organisationId } = useValidateOrganisationId();
  const [state, updateState] = useUpdateState<CalendarEventsState>(initialCalendarEventsState);

  useEffect(() => {
    updateState({ calendarEvents: emptyLoadedValue });

    if (!userId || !organisationId) return;

    const now = new Date();
    const cutoffDate = addWeeks(now, 2);

    const calendarEventsUnsub = onSnapshot(
      query(
        Collection.CalendarEvent,
        where("organisationId", "==", organisationId),
        where("userId", "==", userId),
        where("endsAt", ">=", now),
        where("endsAt", "<=", cutoffDate),
        where("isCancelled", "==", false),
        where("isAllDay", "==", false),
        orderBy("endsAt", "asc"),
      ),
      (snapshot) => {
        const calendarEvents = snapshot.docs;
        updateState({ calendarEvents: { value: calendarEvents, isLoading: false } });
      },
    );

    return () => {
      calendarEventsUnsub();
    };
  }, [userId, organisationId, updateState]);

  const value = state;

  return <CalendarEventsContext.Provider value={value}>{children}</CalendarEventsContext.Provider>;
};
