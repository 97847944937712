import { MarketingConsentStatus } from "@fyxer-ai/shared";
import { useState } from "react";
import { z } from "zod";

import { FormFieldUtil } from "@/components/controls/FormFieldUtil";
import { Container } from "@/components/layout/Container";
import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import {
  AlertDialog,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from "@/components/ui/alert-dialog";
import { Button } from "@/components/ui/button";
import { Checkbox } from "@/components/ui/checkbox";
import { Input } from "@/components/ui/input";
import { Textarea } from "@/components/ui/textarea";
import { useToast } from "@/components/ui/use-toast";
import { useUserActions } from "@/context/BaseContext/actions/useUserActions";
import { useUser } from "@/context/BaseContext/state/useUser";
import { EventButton, EventForm, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { makeSchemaSafe } from "@/lib/makeSchemaSafe";
import { useDeleteAccount } from "@/routes/auth/actions/useDeleteAccount";

import { FormUtil } from "../components/controls/FormUtil";

export const ProfilePage = () => {
  useLogPageView(EventPage.PROFILE);

  const { logButtonPress, logFormSubmit } = useAnalytics();

  const { user, userId } = useUser();
  const { name, email, marketingConsentStatus } = user;
  const { update } = useUserActions();
  const deleteAccount = useDeleteAccount();
  const { toast } = useToast();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  if (deleteAccount.isPending) return <LoadingDashboard />;

  return (
    <Container className="space-y-12 py-12">
      <div className="space-y-4">
        <h1>Your profile</h1>
        <FormUtil
          schema={z.object({
            name: makeSchemaSafe(z.string().min(1).max(320)),
            email: makeSchemaSafe(z.string().email().max(320)),
            marketingConsentStatus: z.nativeEnum(MarketingConsentStatus),
          })}
          onSubmit={async (data) => {
            logFormSubmit(EventForm.UPDATE_PROFILE);
            await update(data);
            toast({
              title: "Profile updated",
              description: "Your edits were saved successfully",
            });
          }}
          defaultValues={{
            name,
            email,
            marketingConsentStatus,
          }}
          submitTitle="Save"
          successMessage="Profile updated"
          render={(form) => (
            <>
              <FormFieldUtil control={form.control} name="name" render={({ field }) => <Input {...field} />} />
              <FormFieldUtil
                control={form.control}
                name="email"
                description="To change your email, create another account and delete this one."
                render={({ field }) => <Input disabled {...field} />}
              />
              <FormFieldUtil
                isInline
                control={form.control}
                name="marketingConsentStatus"
                title="Receive news of product updates and promotions"
                labelClassName="text-xs text-slate-500"
                render={({ field }) => (
                  <Checkbox
                    className="h-3 w-3 border-slate-300 data-[state=checked]:bg-slate-300"
                    checked={field.value === MarketingConsentStatus.ACCEPTED}
                    onCheckedChange={(c) =>
                      field.onChange(
                        (c.valueOf() as boolean) ? MarketingConsentStatus.ACCEPTED : MarketingConsentStatus.DECLINED,
                      )
                    }
                  />
                )}
              />
            </>
          )}
        />
      </div>
      <AlertDialog open={isDeleteModalOpen} onOpenChange={(o) => setIsDeleteModalOpen(o)}>
        <AlertDialogTrigger asChild>
          <Button className="block" variant="destructive">
            Delete account
          </Button>
        </AlertDialogTrigger>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure?</AlertDialogTitle>
            <AlertDialogDescription>
              Deleting your account is non-reversible. You will have to request access to the organisations you have
              access to if you want to return to them.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <FormUtil
            renderSubmitButton={(props) => (
              <>
                <AlertDialogFooter>
                  <AlertDialogCancel>Cancel</AlertDialogCancel>
                  <Button {...props} className="border border-red-500 bg-white text-red-500 hover:bg-red-50" />
                </AlertDialogFooter>
              </>
            )}
            schema={z.object({ deleteReason: makeSchemaSafe(z.string().min(1).max(2000)) })}
            defaultValues={{ deleteReason: "" }}
            render={(form) => (
              <FormFieldUtil
                control={form.control}
                name="deleteReason"
                render={({ field }) => <Textarea {...field} />}
              />
            )}
            onSubmit={async ({ deleteReason }) => {
              logButtonPress(EventButton.DELETE_ACCOUNT, { deleteReason, userId });
              deleteAccount.mutate();
            }}
          />
        </AlertDialogContent>
      </AlertDialog>
    </Container>
  );
};
