import { useCalendarEventsNullable } from "./useCalendarEventsNullable";

export const useCalendarEvents = () => {
  const {
    calendarEvents: { value: calendarEvents, isLoading: areCalendarEventssLoading },
  } = useCalendarEventsNullable();

  if (areCalendarEventssLoading) throw new Error("calendar events are still loading");
  if (calendarEvents === undefined) throw new Error("calendar events are missing");

  return { calendarEvents };
};
