import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";

import { useOrganisationNullable } from "../state/useOrganisationNullable";
import { useValidateOrganisationId } from "../useValidateOrganisationId";

export const OrganisationGuard = ({ children }: { children: ReactNode }) => {
  const { organisationId } = useValidateOrganisationId();
  const {
    organisation,
    memberships,
    invites,
    connections,
    emailConnectionSetupStatuses,
    subscription,
    recallZoomConnections,
  } = useOrganisationNullable();

  if (!organisationId) return <Navigate to="/org" />;

  if (
    organisation.isLoading ||
    memberships.isLoading ||
    invites.isLoading ||
    connections.isLoading ||
    emailConnectionSetupStatuses.isLoading ||
    subscription.isLoading ||
    recallZoomConnections.isLoading
  )
    return <LoadingDashboard />;

  if (organisation.value === undefined) throw new Error("organisation is missing");
  if (memberships.value === undefined) throw new Error("memberships are missing");
  if (invites.value === undefined) throw new Error("invites are missing");
  if (connections.value === undefined) throw new Error("connections are missing");
  if (emailConnectionSetupStatuses.value === undefined) throw new Error("email connection setup statuses are missing");
  if (recallZoomConnections.value === undefined) throw new Error("recall zoom connections are missing");

  return children;
};
