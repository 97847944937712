import { ArrowLeft } from "lucide-react";
import { ReactNode } from "react";
import { Link, To } from "react-router-dom";

export const BackLink = (props: { to: To; children: ReactNode }) => {
  return (
    <Link to={props.to} className="inline-flex gap-2 font-semibold hover:opacity-60">
      <ArrowLeft />
      {props.children}
    </Link>
  );
};
