import { sort } from "@fyxer-ai/shared";
import { Bar, BarChart, CartesianGrid, XAxis, YAxis } from "recharts";

import { ChartConfig, ChartContainer, ChartTooltip, ChartTooltipContent } from "@/components/ui/chart";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";

import { TimelineView } from "./TimelineView";

export const InsightsView = () => {
  const { callAnalysis } = useCallRecording();

  if (!callAnalysis) return null;

  const { speakerStatisticSets, callDurationMs, speakerTimeSegments } = callAnalysis.data();

  const totalSpeakingDurationMs = speakerStatisticSets.reduce((acc, set) => acc + set.durationMs, 0);
  const durationProportionsPerSpeaker = sort(
    speakerStatisticSets.map((set) => ({
      speaker: set.speaker,
      proportion: set.durationMs / totalSpeakingDurationMs,
    })),
    (x) => x.proportion,
    "desc",
  );

  const chartConfig: ChartConfig = {
    proportion: {
      label: "Proportion",
      color: "#222",
    },
  };

  return (
    <>
      <div className="space-y-8">
        <h4>Talk time</h4>
        <ChartContainer config={chartConfig} className="min-h-20 w-full">
          <BarChart accessibilityLayer data={durationProportionsPerSpeaker}>
            <CartesianGrid vertical={false} />
            <YAxis tickFormatter={(value: number) => Math.round(value * 100) + "%"} />
            <XAxis dataKey="speaker" />
            <ChartTooltip
              formatter={(value: number) => Math.round(value * 100) + "%"}
              content={<ChartTooltipContent />}
            />
            <Bar dataKey="proportion" fill="var(--color-proportion)" radius={8} />
          </BarChart>
        </ChartContainer>
      </div>

      <div className="space-y-8">
        <h4>Speaker Timeline</h4>
        <TimelineView {...{ callDurationMs, speakerStatisticSets, speakerTimeSegments }} />
      </div>
    </>
  );
};
