import { ReactNode } from "react";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";

import { useBase } from "../state/useBase";

export const MembershipsGuard = ({ children }: { children: ReactNode }) => {
  const {
    memberships: { value: memberships, isLoading },
  } = useBase();
  if (isLoading) return <LoadingDashboard />;
  if (memberships === undefined) throw new Error("memberships are missing");

  return children;
};
