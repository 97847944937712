import { Environment } from "@fyxer-ai/shared";

export const getEnvironment = () => {
  const envName = import.meta.env.VITE_ENV_NAME;
  if (!Object.keys(Environment).includes(envName)) {
    throw new Error("invalid environment");
  }

  return envName as Environment;
};
