import { CallRecording, CallRecordingAccessSetting, RecallTranscriptStatus } from "@fyxer-ai/shared";
import { onSnapshot, orderBy, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { createContext, ReactNode, useEffect } from "react";

import { useUpdateState } from "@/hooks/useUpdateState";
import { Collection } from "@/lib/firebase/Collection";
import { unwrap } from "@/lib/firebase/unwrap";
import { emptyLoadedValue, LoadedValue } from "@/types/LoadedValue";

import { useBase } from "../BaseContext/state/useBase";
import { useValidateOrganisationId } from "../OrganisationContext/useValidateOrganisationId";

type CallRecordingsState = {
  callRecordings: LoadedValue<QueryDocumentSnapshot<CallRecording>[]>;
  callRecordingsSeenByUser: LoadedValue<QueryDocumentSnapshot<CallRecording>[]>;
  callRecordingsSharedWithUser: LoadedValue<QueryDocumentSnapshot<CallRecording>[]>;
};

const initialCallRecordingsState: CallRecordingsState = {
  callRecordings: emptyLoadedValue,
  callRecordingsSeenByUser: emptyLoadedValue,
  callRecordingsSharedWithUser: emptyLoadedValue,
};

export const CallRecordingsContext = createContext<CallRecordingsState>(initialCallRecordingsState);

export const CallRecordingsProvider = ({ children }: { children: ReactNode }) => {
  const userEmail = useBase().user.value?.email;
  const { organisationId } = useValidateOrganisationId();
  const [state, updateState] = useUpdateState<CallRecordingsState>(initialCallRecordingsState);

  useEffect(() => {
    updateState({
      callRecordings: emptyLoadedValue,
      callRecordingsSeenByUser: emptyLoadedValue,
      callRecordingsSharedWithUser: emptyLoadedValue,
    });

    if (!organisationId || !userEmail) return;

    const callRecordingsSharedWithUserUnsub = onSnapshot(
      query(
        Collection.CallRecording,
        where("organisationId", "==", organisationId),
        where("accessSetting", "in", [
          CallRecordingAccessSetting.ANYONE_WITH_LINK,
          CallRecordingAccessSetting.ORGANISATION,
        ]),
        where("recall.transcriptStatus", "==", RecallTranscriptStatus.SUCCEEDED),
        orderBy("createdAt", "desc"),
      ),
      (snapshot) => {
        const callRecordingsSharedWithUser = snapshot.docs.filter(
          unwrap((callRecording) => !callRecording.emailsWithAccess.includes(userEmail)),
        );
        updateState({ callRecordingsSharedWithUser: { value: callRecordingsSharedWithUser, isLoading: false } });
      },
    );

    const callRecordingsSeenByUserUnsub = onSnapshot(
      query(
        Collection.CallRecording,
        where("organisationId", "==", organisationId),
        where("emailsWithAccess", "array-contains", userEmail),
        where("recall.transcriptStatus", "==", RecallTranscriptStatus.SUCCEEDED),
        orderBy("createdAt", "desc"),
      ),
      (snapshot) => {
        const callRecordingsSeenByUser = snapshot.docs;
        updateState({ callRecordingsSeenByUser: { value: callRecordingsSeenByUser, isLoading: false } });
      },
    );

    return () => {
      callRecordingsSharedWithUserUnsub();
      callRecordingsSeenByUserUnsub();
    };
  }, [userEmail, organisationId, updateState]);

  useEffect(() => {
    const isLoading = state.callRecordingsSeenByUser.isLoading || state.callRecordingsSharedWithUser.isLoading;
    if (isLoading) return updateState({ callRecordings: emptyLoadedValue });
    const callRecordings = [
      ...(state.callRecordingsSeenByUser.value ?? []),
      ...(state.callRecordingsSharedWithUser.value ?? []),
    ];

    updateState({ callRecordings: { value: callRecordings, isLoading: false } });
  }, [
    state.callRecordingsSeenByUser.value,
    state.callRecordingsSharedWithUser.value,
    state.callRecordingsSeenByUser.isLoading,
    state.callRecordingsSharedWithUser.isLoading,
    updateState,
  ]);

  return <CallRecordingsContext.Provider value={state}>{children}</CallRecordingsContext.Provider>;
};
