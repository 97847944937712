"use client";

import * as SelectPrimitive from "@radix-ui/react-select";
import { toHeaderCase } from "js-convert-case";

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../ui/select";

interface Props<T extends string>
  extends Omit<SelectPrimitive.SelectProps, "onValueChange" | "defaultValue" | "value" | "onChange"> {
  placeholder?: string;
  items: ({ value: T; label: string } | T)[];
  className?: string;
  onChange: (value: T) => void;
  value?: T;
}

export const SelectUtil = <T extends string>({
  placeholder,
  items,
  className,
  onChange,
  value,
  ...props
}: Props<T>) => {
  const selectItems = items.map((item) => {
    return typeof item === "string" ? { value: item, label: toHeaderCase(item) } : item;
  });

  return (
    <Select {...props} onValueChange={onChange} defaultValue={value}>
      <SelectTrigger className={className ?? ""}>
        <SelectValue placeholder={placeholder ?? "Select"} />
      </SelectTrigger>
      <SelectContent>
        {selectItems.map(({ value, label }) => (
          <SelectItem key={value} value={value}>
            {label}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
};
