import { useBase } from "./useBase";

export const useAppCheckToken = () => {
  const { value: appCheckToken, isLoading } = useBase().appCheckToken;

  if (isLoading) throw new Error("app check token is still loading");
  if (!appCheckToken) throw new Error("app check token is missing");

  return { appCheckToken };
};
