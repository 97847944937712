import { ReactNode } from "react";

import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";

export const AccordionUtil = ({ items }: { items: { title: string; content: ReactNode }[] }) => (
  <Accordion type="single" collapsible>
    {items.map(({ title, content }, index) => (
      <AccordionItem key={index} value={index.toString()}>
        <AccordionTrigger>
          <p className="text-left font-medium">{title}</p>
        </AccordionTrigger>
        <AccordionContent className="text-base">{content}</AccordionContent>
      </AccordionItem>
    ))}
  </Accordion>
);
