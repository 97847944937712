import { useMutation } from "@tanstack/react-query";
import { signInWithEmailLink } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { auth } from "@/lib/firebase/app";

import { EventName, useAnalytics } from "../../../hooks/useAnalytics";

export const useSignInWithEmailLink = () => {
  const { logEvent } = useAnalytics();
  const navigate = useNavigate();
  return useMutation({
    mutationFn: async ({ email, emailLink }: { email: string; emailLink: string; returnPath: string }) => {
      await signInWithEmailLink(auth, email, emailLink);
    },
    onSuccess: (_, { returnPath }) => {
      logEvent(EventName.CONSUME_EMAIL_LINK);
      navigate(returnPath);
    },
  });
};
