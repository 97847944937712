import { Check } from "lucide-react";

import { EventPage, useLogPageView } from "@/hooks/useAnalytics";
import { useQueryParams } from "@/hooks/useQueryParams";

import { CenterPage } from "../../components/layout/CenterPage";

export const EmailLinkSentPage = () => {
  useLogPageView(EventPage.EMAIL_LINK_SENT);

  const { email } = useQueryParams();

  return (
    <CenterPage className="space-y-4 text-center">
      <div className="m-auto flex h-32 w-32 items-center justify-center rounded-full bg-slate-100">
        <Check size={80} />
      </div>
      <h2>We sent you an email link</h2>
      <p>
        We sent the link to <span className="font-semibold">{email}</span>. Open it to access your account.
      </p>
    </CenterPage>
  );
};
