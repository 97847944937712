import { differenceInHours } from "date-fns";
import pluralize from "pluralize";

import { getPlanType, PlanTypeSource } from "@/routes/org/[organisationId]/settings/tabs/billing/getPlanType";

import { useUser } from "../../BaseContext/state/useUser";
import { useOrganisationNullable } from "./useOrganisationNullable";

export const useBillingBannerData = () => {
  const { freeTrialEndsAt } = useUser();
  const {
    organisationId,
    subscription: { value: subscription },
    organisation: { value: organisation },
  } = useOrganisationNullable();

  const hoursLeftInFreeTrial = differenceInHours(freeTrialEndsAt, new Date());
  const formattedTimeLeftInFreeTrial =
    hoursLeftInFreeTrial > 24
      ? `${pluralize("day", Math.round(hoursLeftInFreeTrial / 24), true)}`
      : `${pluralize("hour", hoursLeftInFreeTrial, true)}`;

  const planData = organisation ? getPlanType({ organisation, freeTrialEndsAt, subscription }) : undefined;

  const planTypeSource = planData?.planTypeSource;

  const shouldShowBanner =
    !!planTypeSource && [PlanTypeSource.FREE_TRIAL, PlanTypeSource.NONE].includes(planTypeSource);

  return { planTypeSource, formattedTimeLeftInFreeTrial, organisationId, shouldShowBanner };
};
