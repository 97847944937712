import { OauthIntegration } from "@fyxer-ai/shared";
import { Loader2 } from "lucide-react";

import { EventButton, useAnalytics } from "@/hooks/useAnalytics";

import { IntegrationDatum } from "./integrationData";

export const ConnectAnotherIntegrationRow = ({
  integrationDatum: integrationDatum,
  redirectingIntegration,
  handleRedirect,
}: {
  integrationDatum: IntegrationDatum;
  redirectingIntegration: OauthIntegration | undefined;
  handleRedirect: () => Promise<void>;
}) => {
  const { logButtonPress } = useAnalytics();
  const { logoUrl, title, integration, serviceType } = integrationDatum;
  const isLoading = redirectingIntegration === integration;
  return (
    <div
      key={`integration-row-${integration}`}
      className="flex cursor-pointer items-center gap-4 py-2 hover:opacity-60"
      onClick={() => {
        logButtonPress(EventButton.SELECT_INTEGRATION, { integration, serviceType, isInitialConnection: false });
        handleRedirect();
      }}
    >
      {isLoading ? (
        <div className="flex h-8 w-8 items-center justify-center">
          <Loader2 className="h-6 w-6 animate-spin" />
        </div>
      ) : (
        <img className="h-8 w-8 rounded-sm" src={logoUrl} alt={title} />
      )}
      {title}
    </div>
  );
};
