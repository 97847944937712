import { Loader2 } from "lucide-react";
import { MouseEventHandler, ReactNode } from "react";

import { useUpdateState } from "@/hooks/useUpdateState";

import { Promisify } from "../types/Promisify";

export const useClickProps = ({
  onClick,
  buttonText,
  spinnerColor = "white",
}: {
  onClick: Promisify<MouseEventHandler>;
  buttonText: string | ReactNode;
  spinnerColor?: string;
}) => {
  const [state, updateState] = useUpdateState({ isLoading: false });
  const handleClick: MouseEventHandler = async (...args) => {
    updateState({ isLoading: true });
    await onClick(...args);
    updateState({ isLoading: false });
  };
  return {
    onClick: handleClick,
    children: <>{state.isLoading ? <Loader2 className="animate-spin" color={spinnerColor} /> : buttonText}</>,
  };
};

export const useSubmitProps =
  () =>
  ({ isLoading, buttonText }: { isLoading: boolean; buttonText: string }) => ({
    type: "submit" as const,
    children: <>{isLoading ? <Loader2 className="animate-spin" color="white" /> : buttonText}</>,
  });
