import { useQuery } from "@tanstack/react-query";

import { useApi } from "@/hooks/useApi";

import { useOrganisation } from "./useOrganisation";

export const useBillingPeriodEndDate = (subscriptionId: string) => {
  const { organisationId } = useOrganisation();
  const api = useApi();

  return useQuery({
    queryKey: ["billingPeriodEndDate", organisationId, subscriptionId],
    queryFn: api.organisations.id(organisationId).subscriptions.id(subscriptionId).getBillingPeriodEndDate,
  });
};
