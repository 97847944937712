import { SubscriptionStatus } from "@fyxer-ai/shared";

import { Button } from "@/components/ui/button";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";
import { useClickProps } from "@/hooks/useClickProps";
import { redirectExternally } from "@/lib/redirectExternally";

export const InactiveSubscriptionView = () => {
  const { organisationId, subscription } = useOrganisation();
  const { logButtonPress } = useAnalytics();
  const api = useApi();

  if (!subscription) throw new Error("Must have a subscription to view this");
  const { status } = subscription.data();

  const subscriptionId = subscription.id;

  const handleGoToStripe = async () => {
    logButtonPress(EventButton.GO_TO_STRIPE, {
      subscriptionId,
      location: "DelinquentView",
    });
    const portalUrl = await api.organisations.id(organisationId).portalSessions.create();
    redirectExternally(portalUrl);
  };

  const goToStripeClickProps = useClickProps({
    onClick: handleGoToStripe,
    buttonText: "Manage subscription",
  });

  return (
    <div className="space-y-4">
      <p>
        {status === SubscriptionStatus.DELINQUENT
          ? "Your subscription payment method has failed. To keep using Fyxer AI, update your payment method. Once we get a successful payment, we will resume your subscription."
          : status === SubscriptionStatus.PAUSED
            ? "Your subscription is paused. When you want to continue with Fyxer AI, update it by managing your subscription below."
            : ""}
      </p>
      ;
      <Button {...goToStripeClickProps} />
    </div>
  );
};
