import { isNull } from "@fyxer-ai/shared";
import { useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";
import { EventName, EventPage, useAnalytics, useLogPageView } from "@/hooks/useAnalytics";
import { useApi } from "@/hooks/useApi";

export const AcceptInvitePage = () => {
  const { inviteId } = useParams();
  const { logEvent } = useAnalytics();
  useLogPageView(EventPage.ACCEPT_INVITE, { inviteId });
  if (!inviteId) throw new Error("inviteId is not defined");
  const api = useApi();

  const acceptInvite = useMutation({
    mutationFn: api.invites.id(inviteId).accept,
    onSuccess(organisationId) {
      logEvent(EventName.ACCEPT_INVITE, { inviteId, organisationId });
    },
  });

  const { data: organisationId, error } = acceptInvite;

  useEffect(() => {
    acceptInvite.mutate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    const errorMessage =
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      error instanceof AxiosError ? (error.response?.data.message ?? error.message) : (error as any)?.message;
    return <p>{errorMessage}</p>;
  }

  if (isNull(organisationId)) return <LoadingDashboard />;

  return <Navigate to={`/org/${organisationId}`} />;
};
