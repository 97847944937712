import { useCallRecordingSnippetNullable } from "./useCallRecordingSnippetNullable";

export const useCallRecordingSnippet = () => {
  const {
    callRecordingSnippetId: { value: callRecordingSnippetId, isLoading: isCallRecordingIdLoading },
    callRecordingSnippet: { value: callRecordingSnippet, isLoading: isCallRecordingLoading },
  } = useCallRecordingSnippetNullable();

  if (isCallRecordingIdLoading) throw new Error("call recording id is still loading");
  if (isCallRecordingLoading) throw new Error("call recording is still loading");

  if (callRecordingSnippetId === undefined) throw new Error("call recording id is missing");
  if (callRecordingSnippet === undefined) throw new Error("call recording is missing");

  return {
    callRecordingSnippetId,
    callRecordingSnippet,
  };
};
