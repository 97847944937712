import { SignupSource } from "@fyxer-ai/shared";
import { useEffect } from "react";
import { z } from "zod";

import { useQueryParams } from "@/hooks/useQueryParams";
import { LocalStorageEnumKey, LocalStorageStringKey, storage } from "@/lib/storage";

export const useSaveParamsToLocalStorageIfNeeded = () => {
  const { r, src } = useQueryParams();

  useEffect(() => {
    if (!r) return;
    storage.local.string(LocalStorageStringKey.REFERRER).set(r);
  }, [r]);

  useEffect(() => {
    if (!src) return;
    const parsed = z.nativeEnum(SignupSource).safeParse(src);
    if (!parsed.success) return;
    storage.local.enum<SignupSource>(LocalStorageEnumKey.SIGNUP_SOURCE).set(parsed.data);
  }, [src]);
};
