import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { useQueryParams } from "@/hooks/useQueryParams";

import { useBase } from "../state/useBase";

export const UnauthGuard = ({ children }: { children: ReactNode }) => {
  const { return_path } = useQueryParams();
  const returnPath = return_path ?? "/org";

  const { authUser, authIdToken } = useBase();

  const isAuthed = authUser.value && authIdToken.value;

  if (isAuthed) return <Navigate to={returnPath} />;

  return children;
};
