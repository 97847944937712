import { OauthServiceType } from "@fyxer-ai/shared";
import { ReactElement } from "react";

export type ServiceTypeDatum = {
  serviceType: OauthServiceType;
  status: "GENERAL_AVAILABILITY" | "BETA";
  strapline: string;
  detail: () => ReactElement;
};

export const serviceTypeData: ServiceTypeDatum[] = [
  {
    serviceType: OauthServiceType.EMAIL,
    status: "GENERAL_AVAILABILITY",
    strapline: "Connect your email so we can categorise it, and create draft responses where appropriate.",
    detail: () => (
      <div className="space-y-2">
        <p>When you connect your email, we'll:</p>
        <ul style={{ listStyleType: "initial" }} className="ml-4">
          <li>Create a set of 8 categories in your email client that emails will be sorted into.</li>
          <li>Categorise your latest 100 emails</li>
          <li>Listen for new emails and categorise them as they come in</li>
          <li>If we identify that an email needs a response from you, we'll create a draft in your email client.</li>
        </ul>
        <p className="font-semibold">We never send email on your behalf.</p>
        <p className="font-semibold">
          If you have an existing system for organising your inbox that you don't want changed, its best to turn off our
          Email Categorisation feature in the Preferences tab. You can always turn it back on later if you want to try
          it.
        </p>
      </div>
    ),
  },
  {
    serviceType: OauthServiceType.CALENDAR,
    status: "GENERAL_AVAILABILITY",
    strapline: "Connect your calendar to manage your schedule using AI.",
    detail: () => (
      <>
        <p>
          The calendar integration is supplementary to the email integration. When you connect, we'll use your calendar
          data when creating drafts to emails that require knowledge of your schedule to answer accurately.
        </p>
      </>
    ),
  },
  {
    serviceType: OauthServiceType.CRM,
    status: "BETA",
    strapline: "Connect your CRM and we'll update it based on your calls.",
    detail: () => (
      <>
        <p>
          When you connect your CRM, we can update it with your call recordings, transcripts and notes, and create new
          deals based on meetings you book.
        </p>
      </>
    ),
  },
  {
    serviceType: OauthServiceType.INSTANT_MESSAGING,
    status: "BETA",
    strapline: "Connect your instant messaging service to reply to messages using AI.",
    detail: () => (
      <p>
        When you connect your instant messaging tool, we'll listen for messages you need to reply to and leave a draft
        message for you to send or edit.
      </p>
    ),
  },
];
