import { useMutation } from "@tanstack/react-query";
import { signOut } from "firebase/auth";
import { useNavigate } from "react-router-dom";

import { useToast } from "@/components/ui/use-toast";
import { auth } from "@/lib/firebase/app";

export const useSignOut = () => {
  const navigate = useNavigate();
  const { toast } = useToast();
  return useMutation({
    mutationFn: () => signOut(auth),
    onSuccess: () => {
      toast({
        title: "Signed out",
        description: "You signed out successfully",
      });
      navigate("/");
    },
  });
};
