import { date, deduplicate, omit, sort } from "@fyxer-ai/shared";
import { addMinutes, differenceInMinutes, format, startOfDay } from "date-fns";
import { toZonedTime } from "date-fns-tz";

type TimeInterval = {
  startsAt: Date;
  endsAt: Date;
};

type CalendarEvent = TimeInterval & {
  title: string;
};

const formatTime = (date: Date) => format(date, "HH:mm");

const getDistanceMins = (timeInterval1: TimeInterval, timeInterval2: TimeInterval) => {
  const [firstTimeInterval, secondTimeInterval] = sort(
    [timeInterval1, timeInterval2],
    (timeInterval) => timeInterval.startsAt,
    "asc",
  );

  const doTimeIntervalsOverlap = secondTimeInterval.startsAt < firstTimeInterval.endsAt;

  if (doTimeIntervalsOverlap) return 0;

  return differenceInMinutes(secondTimeInterval.startsAt, firstTimeInterval.endsAt);
};

const EventView = ({
  title,
  startsAtLocal,
  endsAtLocal,
  isSuggestedSlot,
}: {
  title: string;
  startsAtLocal: Date;
  endsAtLocal: Date;
  isSuggestedSlot: boolean;
}) => (
  <div
    style={{
      paddingLeft: 8,
      paddingRight: 8,
      paddingBottom: 4,
      paddingTop: 4,
      borderRadius: 8,
      width: 96,
      backgroundColor: isSuggestedSlot ? "#1e293b" : "#f1f5f9",
    }}
  >
    <p
      style={{
        color: isSuggestedSlot ? "white" : "black",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        fontSize: "0.875rem",
        lineHeight: "1.25rem",
        marginBottom: 4,
        padding: 0,
      }}
    >
      {title}
    </p>
    <p
      style={{
        padding: 0,
        fontSize: "0.75rem",
        lineHeight: "1rem",
        color: isSuggestedSlot ? "#f1f5f9" : "#64748b",
      }}
    >
      {formatTime(startsAtLocal)} - {formatTime(endsAtLocal)}
    </p>
  </div>
);

type EventViewDatum = Parameters<typeof EventView>[0];

const DateView = ({ startOfDate, eventViewData }: { eventViewData: EventViewDatum[]; startOfDate: Date }) => (
  <td style={{ verticalAlign: "top" }}>
    <table style={{ borderCollapse: "separate", borderSpacing: 4 }}>
      <th>{format(startOfDate, "do MMM")}</th>
      {eventViewData.map((eventViewDatum) => (
        <tr key={eventViewDatum.startsAtLocal.toString()}>
          <td>
            <EventView {...eventViewDatum} />
          </td>
        </tr>
      ))}
      {eventViewData.filter((datum) => !datum.isSuggestedSlot).length === 0 ? (
        <tr>
          <td>
            <p style={{ width: 96, fontSize: "0.75rem", lineHeight: "1rem" }}>No other events scheduled</p>
          </td>
        </tr>
      ) : null}
    </table>
  </td>
);

const convertToLocal = <T extends TimeInterval>(slot: T, timeZone: string) => {
  return omit(
    {
      ...slot,
      startsAtLocal: toZonedTime(slot.startsAt, timeZone),
      endsAtLocal: toZonedTime(slot.endsAt, timeZone),
    },
    ["startsAt", "endsAt"],
  );
};

export const CalendarView = ({
  slots,
  calendarEvents,
  timeZone,
}: {
  slots: TimeInterval[];
  calendarEvents: CalendarEvent[];
  timeZone: string;
}) => {
  const localSlots = slots.map((slot) => convertToLocal(slot, timeZone));
  const localEvents = calendarEvents.map((event) => convertToLocal(event, timeZone));

  const slotStartOfDayEpochs = localSlots.map((slot) => startOfDay(slot.startsAtLocal).getTime());
  const eventStartOfDayEpochs = localEvents.map((event) => startOfDay(event.startsAtLocal).getTime());

  const startOfDayEpochsToDisplay = sort(deduplicate(slotStartOfDayEpochs), (epoch) => epoch, "asc");

  const displayData = startOfDayEpochsToDisplay.map((epoch) => {
    const slotsForDate = slotStartOfDayEpochs.flatMap((slotEpoch, index) =>
      slotEpoch === epoch ? [localSlots[index]] : [],
    );
    const eventsForDate = eventStartOfDayEpochs.flatMap((eventEpoch, index) =>
      eventEpoch === epoch ? [localEvents[index]] : [],
    );

    const eventViewData: Parameters<typeof EventView>[0][] = sort(
      [
        ...slotsForDate.map((slot) => ({ title: "Slot", isSuggestedSlot: true, ...slot })),
        ...sort(
          eventsForDate,
          (event) =>
            Math.min(
              ...slotsForDate.map((slot) =>
                getDistanceMins(
                  { startsAt: slot.startsAtLocal, endsAt: slot.endsAtLocal },
                  { startsAt: event.startsAtLocal, endsAt: event.endsAtLocal },
                ),
              ),
            ),
          "asc",
        )
          .slice(0, 5)
          .map((event) => ({ isSuggestedSlot: false, ...event })),
      ],
      (event) => event.startsAtLocal,
      "asc",
    );

    return {
      startOfDate: new Date(epoch),
      eventViewData,
    };
  });

  return (
    <table>
      <tr>
        {displayData.map((datum) => (
          <DateView {...datum} />
        ))}
      </tr>
    </table>
  );
};

const SlotProposalEmail = (props: { slots: TimeInterval[]; calendarEvents: CalendarEvent[]; timeZone: string }) => (
  <>
    <p>Here are the slots I've suggested in the context of your calendar:</p>
    <CalendarView {...props} />
  </>
);

const createTimeInterval = (startsAt: Date, durationMins: number) => ({
  startsAt,
  endsAt: addMinutes(startsAt, durationMins),
});

const createEvent = (...props: Parameters<typeof createTimeInterval>) => ({
  title: "Title that is very long to test the layout",
  ...createTimeInterval(...props),
});

const slots: TimeInterval[] = [
  createTimeInterval(date.init({ year: 2024, month: 1, day: 1, hour: 10 }), 30),
  createTimeInterval(date.init({ year: 2024, month: 1, day: 2, hour: 15 }), 30),
  createTimeInterval(date.init({ year: 2024, month: 1, day: 3, hour: 17 }), 30),
];

const calendarEvents: CalendarEvent[] = [
  createEvent(date.init({ year: 2024, month: 1, day: 2, hour: 10 }), 30),
  createEvent(date.init({ year: 2024, month: 1, day: 2, hour: 10 }), 15),
  createEvent(date.init({ year: 2024, month: 1, day: 2, hour: 14, minute: 30 }), 30),
  createEvent(date.init({ year: 2024, month: 1, day: 3, hour: 11 }), 90),
  createEvent(date.init({ year: 2024, month: 1, day: 3, hour: 12 }), 15),
  createEvent(date.init({ year: 2024, month: 1, day: 3, hour: 14, minute: 30 }), 60),
  createEvent(date.init({ year: 2024, month: 1, day: 3, hour: 14 }), 30),
  createEvent(date.init({ year: 2024, month: 1, day: 3, hour: 15, minute: 30 }), 15),
  createEvent(date.init({ year: 2024, month: 1, day: 3, hour: 16, minute: 30 }), 30),
  createEvent(date.init({ year: 2024, month: 1, day: 3, hour: 15 }), 30),
  createEvent(date.init({ year: 2024, month: 1, day: 3, hour: 18 }), 60),
];

export const SandboxPage = () => {
  return <SlotProposalEmail {...{ slots, calendarEvents, timeZone: "Europe/London" }} />;
};
