import { constructUrl, UrlConstructor } from "@fyxer-ai/shared";
import { useNavigate } from "react-router-dom";

export const useComplexNavigate = () => {
  const navigate = useNavigate();

  return (urlConstructor: Omit<UrlConstructor, "base"> | string) => {
    navigate(typeof urlConstructor === "string" ? urlConstructor : constructUrl(urlConstructor));
  };
};
