import { useParams } from "react-router-dom";

import { unwrap } from "@/lib/firebase/unwrap";

import { useBase } from "../BaseContext/state/useBase";

enum OrganisationIdValidationErrorCode {
  MISSING_FROM_URL_PARAMS = "MISSING_FROM_URL_PARAMS",
  MISSING_MEMBERSHIPS = "MISSING_MEMBERSHIPS",
  MISSING_FROM_USER_MEMBERSHIPS = "MISSING_FROM_USER_MEMBERSHIPS",
}

export const useValidateOrganisationId = () => {
  const { organisationId } = useParams();
  const {
    memberships: { value: memberships },
  } = useBase();
  if (!organisationId) return { error: OrganisationIdValidationErrorCode.MISSING_FROM_URL_PARAMS };
  if (!memberships) return { error: OrganisationIdValidationErrorCode.MISSING_MEMBERSHIPS };
  const isOrganisationIdInMemberships = memberships.some(
    unwrap((membership) => membership.organisationId === organisationId),
  );
  if (!isOrganisationIdInMemberships) return { error: OrganisationIdValidationErrorCode.MISSING_FROM_USER_MEMBERSHIPS };
  return { organisationId };
};
