import { useCallback, useEffect } from "react";

import { useUpdateState } from "./useUpdateState";

export const useWindowSize = () => {
  const [state, updateState] = useUpdateState({ width: window.innerWidth, height: window.innerHeight });

  const handleResize = useCallback(() => {
    updateState({ width: window.innerWidth, height: window.innerHeight });
  }, [updateState]);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return state;
};

export const useBreakpoint = () => {
  const { width } = useWindowSize();
  if (width < 640) return "xs";
  return "sm";
};
