import {
  EmailConnectionSetupStatus,
  first,
  Invite,
  Membership,
  OauthConnection,
  Organisation,
  RecallZoomConnection,
  sort,
  Subscription,
  SubscriptionStatus,
} from "@fyxer-ai/shared";
import { doc, onSnapshot, query, QueryDocumentSnapshot, where } from "firebase/firestore";
import { createContext, ReactNode, useEffect } from "react";

import { Collection } from "@/lib/firebase/Collection";
import { unwrap } from "@/lib/firebase/unwrap";

import { useUpdateState } from "../../hooks/useUpdateState";
import { emptyLoadedValue, LoadedValue } from "../../types/LoadedValue";
import { useBase } from "../BaseContext/state/useBase";
import { useValidateOrganisationId } from "./useValidateOrganisationId";

type OrganisationState = {
  organisationId?: string;
  organisation: LoadedValue<Organisation>;
  memberships: LoadedValue<QueryDocumentSnapshot<Membership>[]>;
  subscription: LoadedValue<QueryDocumentSnapshot<Subscription>>;
  invites: LoadedValue<QueryDocumentSnapshot<Invite>[]>;
  connections: LoadedValue<QueryDocumentSnapshot<OauthConnection>[]>;
  orgLevelConnections: LoadedValue<QueryDocumentSnapshot<OauthConnection>[]>;
  emailConnectionSetupStatuses: LoadedValue<QueryDocumentSnapshot<EmailConnectionSetupStatus>[]>;
  recallZoomConnections: LoadedValue<QueryDocumentSnapshot<RecallZoomConnection>[]>;
};

const initialOrganisationState: OrganisationState = {
  organisation: emptyLoadedValue,
  memberships: emptyLoadedValue,
  subscription: emptyLoadedValue,
  invites: emptyLoadedValue,
  connections: emptyLoadedValue,
  orgLevelConnections: emptyLoadedValue,
  emailConnectionSetupStatuses: emptyLoadedValue,
  recallZoomConnections: emptyLoadedValue,
};

export const OrganisationContext = createContext<OrganisationState>(initialOrganisationState);

export const OrganisationProvider = ({ children }: { children: ReactNode }) => {
  const { organisationId } = useValidateOrganisationId();

  const [state, updateState] = useUpdateState<OrganisationState>(initialOrganisationState);

  const userId = useBase().authUser.value?.uid;

  useEffect(() => {
    updateState({
      organisation: emptyLoadedValue,
      memberships: emptyLoadedValue,
      subscription: emptyLoadedValue,
      invites: emptyLoadedValue,
      connections: emptyLoadedValue,
      emailConnectionSetupStatuses: emptyLoadedValue,
    });

    if (!organisationId || !userId) return;

    const organisationUnsubscribe = onSnapshot(doc(Collection.Organisation, organisationId), (organisationDoc) => {
      const organisation = organisationDoc.data();
      updateState({ organisation: { value: organisation, isLoading: false } });
    });

    const membershipsUnsubscribe = onSnapshot(
      query(Collection.Membership, where("organisationId", "==", organisationId)),
      (snapshot) => {
        const memberships = snapshot.docs;
        updateState({ memberships: { value: memberships, isLoading: false } });
      },
    );

    const subscriptionsUnsubscribe = onSnapshot(
      query(Collection.Subscription, where("organisationId", "==", organisationId)),
      (snapshot) => {
        // we don't care about showing cancelled or deleted subscriptions - it'll just confuse the user as they expect to not see anything and have to start a new subscription
        // we also don't care about showing pending subscriptions as they are not active yet
        const subscriptions = snapshot.docs.filter(
          unwrap((subscription) =>
            [
              SubscriptionStatus.ACTIVE,
              SubscriptionStatus.DELINQUENT,
              SubscriptionStatus.PAUSED,
              SubscriptionStatus.TRIAL,
            ].includes(subscription.status),
          ),
        );
        const subscription = first(
          sort(
            subscriptions,
            unwrap((subscription) => subscription.createdAt),
            "desc",
          ),
        );
        updateState({ subscription: { value: subscription, isLoading: false } });
      },
    );

    const invitesUnsubscribe = onSnapshot(
      query(Collection.Invite, where("organisationId", "==", organisationId)),
      (snapshot) => {
        const invites = snapshot.docs;
        updateState({ invites: { value: invites, isLoading: false } });
      },
    );

    const connectionsUnsubscribe = onSnapshot(
      query(Collection.OauthConnection, where("organisationId", "==", organisationId), where("userId", "==", userId)),
      (snapshot) => {
        const connections = snapshot.docs.filter(unwrap((connection) => !connection.isOrgLevel));
        updateState({ connections: { value: connections, isLoading: false } });
      },
    );

    const orgLevelConnectionsUnsubscribe = onSnapshot(
      query(Collection.OauthConnection, where("organisationId", "==", organisationId), where("isOrgLevel", "==", true)),
      (snapshot) => {
        const connections = snapshot.docs;
        updateState({ orgLevelConnections: { value: connections, isLoading: false } });
      },
    );

    const emailConnectionSetupStatusesUnsubscribe = onSnapshot(
      query(
        Collection.EmailConnectionSetupStatus,
        where("organisationId", "==", organisationId),
        where("userId", "==", userId),
      ),
      (snapshot) => {
        const emailConnectionSetupStatuses = snapshot.docs;
        updateState({ emailConnectionSetupStatuses: { value: emailConnectionSetupStatuses, isLoading: false } });
      },
    );

    const recallZoomConnectionsUnsubscribe = onSnapshot(
      query(
        Collection.RecallZoomConnection,
        where("organisationId", "==", organisationId),
        where("userId", "==", userId),
      ),
      (snapshot) => {
        const recallZoomConnections = snapshot.docs;
        updateState({ recallZoomConnections: { value: recallZoomConnections, isLoading: false } });
      },
    );

    return () => {
      organisationUnsubscribe();
      membershipsUnsubscribe();
      subscriptionsUnsubscribe();
      invitesUnsubscribe();
      connectionsUnsubscribe();
      orgLevelConnectionsUnsubscribe();
      emailConnectionSetupStatusesUnsubscribe();
      recallZoomConnectionsUnsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organisationId, userId]);

  const value = {
    organisationId,
    ...state,
  };

  return <OrganisationContext.Provider value={value}>{children}</OrganisationContext.Provider>;
};
