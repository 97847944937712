import { useToast } from "@/components/ui/use-toast";
import { useOrganisationActions } from "@/context/OrganisationContext/actions/useOrganisationActions";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";
import { useComplexNavigate } from "@/hooks/useComplexNavigate";

import { ConfirmAlertDialog } from "../../../../../../components/ConfirmAlertDialog";

export const DangerZoneView = () => {
  const { delete: deleteOrg, leave } = useOrganisationActions();
  const { toast } = useToast();
  const { isAdmin } = useOrganisation();
  const navigate = useComplexNavigate();

  const handleLeaveOrganisation = async () => {
    await leave();
    navigate("/org");
    toast({ title: "Organisation left" });
  };

  const handleDeleteOrganisation = async () => {
    if (!isAdmin) return;
    await deleteOrg();
    navigate("/org");
    toast({ title: "Organisation deleted" });
  };

  return (
    <div className="max-sm:space-y-2 sm:flex sm:gap-4">
      <ConfirmAlertDialog
        title="Leave Organisation"
        warningText="If you leave the Organisation, you'll lose access and need an admin to add you back."
        fn={handleLeaveOrganisation}
      />
      {isAdmin && (
        <ConfirmAlertDialog
          title="Delete Organisation"
          warningText="If you delete your Organisation, all your team members will lose access to Fyxer AI, and all your recordings
                  will be lost. This action is not reversible."
          fn={handleDeleteOrganisation}
        />
      )}
    </div>
  );
};
