import { ReactNode } from "react";

import { LoadingDashboard } from "@/components/layout/LoadingDashboard";

import { useBase } from "../state/useBase";

export const BaseGuard = ({ children }: { children: ReactNode }) => {
  const { appCheckToken, remoteConfig } = useBase();

  const isLoading = appCheckToken.isLoading || remoteConfig.isLoading;
  if (isLoading) return <LoadingDashboard />;

  if (!appCheckToken.value) throw new Error("app check token is missing");
  if (!remoteConfig.value) throw new Error("remote config is missing");

  return children;
};
