import { toHeaderCase } from "js-convert-case";
import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";

import { Button } from "@/components/ui/button";
import { Command, CommandGroup, CommandInput, CommandItem } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";

type Item<T extends string> = { value: T; label: string } | T;

interface Props<T extends string> {
  placeholder?: string;
  items: Item<T>[];
  onChange: (value: T) => void;
  value?: T;
}

const getItemValue = <T extends string>(item: Item<T>) => (typeof item === "string" ? item : item.value);
const getItemLabel = <T extends string>(item: Item<T>) => (typeof item === "string" ? toHeaderCase(item) : item.label);

export const ComboboxUtil = <T extends string>({ value, onChange, items, placeholder }: Props<T>) => {
  const [open, setOpen] = React.useState(false);

  const selectedItem = items.find((item) => getItemValue(item) === value);
  const selectedLabel = selectedItem ? getItemLabel(selectedItem) : undefined;

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="w-[300px] justify-between border-slate-200"
        >
          {selectedLabel ?? placeholder ?? "Select..."}
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="h-[400px] w-[300px] p-0">
        <Command>
          <CommandInput placeholder="Search..." />
          <CommandGroup className="overflow-scroll">
            {items.map((item) => (
              <CommandItem
                key={getItemValue(item)}
                value={getItemValue(item)}
                onSelect={(currentValue) => {
                  onChange(currentValue as T);
                  setOpen(false);
                }}
              >
                <Check className={cn("mr-2 h-4 w-4", value === getItemValue(item) ? "opacity-100" : "opacity-0")} />
                {getItemLabel(item)}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
};
