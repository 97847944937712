import { constructUrl, UrlParams } from "@fyxer-ai/shared";
import { forwardRef } from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";

export const SwitchTabLink = forwardRef<
  HTMLAnchorElement,
  { tab: string; search?: UrlParams } & Omit<LinkProps, "to"> & React.RefAttributes<HTMLAnchorElement>
>(({ tab, search, ...props }, ref) => {
  const location = useLocation();
  return <Link {...props} ref={ref} to={constructUrl({ path: location.pathname, hash: { tab }, search })} />;
});
