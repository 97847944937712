import { Membership, Organisation, OrganisationRole } from "@fyxer-ai/shared";
import { deleteDoc, doc, updateDoc } from "firebase/firestore";

import { useApi } from "@/hooks/useApi";
import { Collection } from "@/lib/firebase/Collection";

import { useOrganisation } from "../state/useOrganisation";

export const useOrganisationActions = () => {
  const { userMembership, organisationId } = useOrganisation();
  const organisationRef = doc(Collection.Organisation, organisationId);
  const api = useApi();

  return {
    leave: () => deleteDoc(userMembership.ref),
    delete: () => deleteDoc(organisationRef),
    updateOrg: (data: Partial<Organisation>) => updateDoc(organisationRef, data),
    sendInvites: api.organisations.id(organisationId).invites.send,
    createOrganisationInvite: api.organisations.id(organisationId).organisationInvites.create,
    cancelInvite: (inviteId: string) => updateDoc(doc(Collection.Invite, inviteId), { isCancelled: true }),
    removeMember: (membershipId: string) => deleteDoc(doc(Collection.Membership, membershipId)),
    changeMemberRole: ({ membershipId, role }: { membershipId: string; role: OrganisationRole }) =>
      updateDoc(doc(Collection.Membership, membershipId), { role }),
    updateMembership: (data: Partial<Membership>) => updateDoc(userMembership.ref, data),
  };
};
