import { sort, SpeakerStatisticSet, SpeakerTimeSegment } from "@fyxer-ai/shared";

import { useSize } from "@/hooks/useSize";

export const TimelineView = ({
  callDurationMs,
  speakerStatisticSets,
  speakerTimeSegments,
}: {
  callDurationMs: number;
  speakerStatisticSets: SpeakerStatisticSet[];
  speakerTimeSegments: SpeakerTimeSegment[];
}) => {
  const [ref, size] = useSize();

  const { width } = size;

  const labelWidth = 112;
  const gapWidth = 8;

  const containerWidth = width - labelWidth - gapWidth;

  return (
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    <div ref={ref as any}>
      {sort(speakerStatisticSets, (set) => set.durationMs, "desc").map((set) => {
        const { speakerId } = set;
        const timeSegments = speakerTimeSegments.filter((segment) => segment.speakerId === speakerId);

        return (
          <div className="flex items-center" style={{ columnGap: gapWidth }}>
            <span
              style={{ width: labelWidth }}
              className="flex-shrink-0 flex-grow-0 overflow-hidden text-ellipsis whitespace-nowrap rounded-lg bg-slate-100 px-2 py-1 text-sm text-slate-500"
            >
              {set.speaker}
            </span>
            <div className="relative h-10 flex-shrink flex-grow" key={speakerId}>
              {timeSegments.map((segment) => {
                return (
                  <div
                    key={segment.startMs}
                    className="h-full bg-slate-950 bg-opacity-50"
                    style={{
                      position: "absolute",
                      top: 0,
                      left: (segment.startMs / callDurationMs) * containerWidth,
                      width: ((segment.endMs - segment.startMs) / callDurationMs) * containerWidth,
                    }}
                  ></div>
                );
              })}
            </div>
          </div>
        );
      })}
    </div>
  );
};
