import { useEffect } from "react";
import { useIntercom } from "react-use-intercom";

export const Intercom = () => {
  const { boot, shutdown } = useIntercom();

  useEffect(() => {
    boot();

    return () => {
      shutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};
