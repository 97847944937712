import { Copy, Download, MoreHorizontal } from "lucide-react";
import Markdown from "react-markdown";

import { useGetCopyProps } from "@/components/controls/CopyUtil";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { useBase } from "@/context/BaseContext/state/useBase";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { downloadFile } from "@/lib/downloadFile";

// import { CallRecordingTabName } from "../CallRecordingView";
// import { SwitchTabLink } from "./transcript/SwitchTabLink";

export const SummaryTab = () => {
  const { callSummary, title } = useCallRecording();
  const { authUser } = useBase();
  const { getCopyProps } = useGetCopyProps();
  const isAuthenticated = !!authUser.value;

  if (!callSummary) return <p>No summary found</p>;

  const { content } = callSummary.data();

  return (
    <div className="pb-12">
      <Markdown className="meeting-summary space-y-4">{content}</Markdown>
      {isAuthenticated && (
        <div className="absolute bottom-0 left-0 flex items-center gap-x-2 rounded-t-2xl border border-b-0 border-black bg-white p-2">
          {/* <SwitchTabLink
            tab={CallRecordingTabName.TRANSCRIPT}
            search={{ shouldCreateSnippet: true }}
            className="flex-shrink-0"
          >
            <Button variant="secondary">Create call snippet</Button>
          </SwitchTabLink> */}
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon">
                <MoreHorizontal size={24} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem className="flex items-center gap-x-2" {...getCopyProps({ value: content })}>
                <Copy size={16} />
                <span>Copy summary</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="flex items-center gap-x-2"
                onClick={() => downloadFile({ content, baseName: `Summary of ${title}` })}
              >
                <Download size={16} />
                <span>Download summary</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </div>
      )}
    </div>
  );
};
