import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

import { SpinnerPage } from "@/components/layout/SpinnerPage";
import { useValidateOrganisationId } from "@/context/OrganisationContext/useValidateOrganisationId";

import { useCalendarEventsNullable } from "../state/useCalendarEventsNullable";

export const CalendarEventsGuard = ({ children }: { children: ReactNode }) => {
  const { organisationId } = useValidateOrganisationId();
  const { calendarEvents } = useCalendarEventsNullable();

  if (!organisationId) return <Navigate to="/org" />;

  if (calendarEvents.isLoading) return <SpinnerPage />;

  if (calendarEvents.value === undefined) throw new Error("calendar events are missing");

  return children;
};
