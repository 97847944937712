import { CallRecordingSnippet, TranscriptSegment } from "@fyxer-ai/shared";
import { useMutation } from "@tanstack/react-query";
import { doc, onSnapshot, QueryDocumentSnapshot, setDoc } from "firebase/firestore";
import { ArrowDown, ArrowUp, Copy, Download, MoreHorizontal, X } from "lucide-react";
import { createRef, useCallback, useEffect, useMemo, useRef } from "react";
import { v4 as uuid } from "uuid";

import { useGetCopyProps } from "@/components/controls/CopyUtil";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Input } from "@/components/ui/input";
import { Progress } from "@/components/ui/progress";
import { useBase } from "@/context/BaseContext/state/useBase";
import { useCallRecording } from "@/context/CallRecordingContext/state/useCallRecording";
import { useQueryParams } from "@/hooks/useQueryParams";
import { useUpdateState } from "@/hooks/useUpdateState";
import { downloadFile } from "@/lib/downloadFile";
import { Collection } from "@/lib/firebase/Collection";
import { unwrap } from "@/lib/firebase/unwrap";
import { cn } from "@/lib/utils";
import { LoadedValue } from "@/types/LoadedValue";

import { CallRecordingTabName } from "../../CallRecordingView";
import { constructCallRecordingSnippetPath } from "./constructCallRecordingSnippetPath";
import { formatMilliseconds } from "./formatMilliseconds";
import { SwitchTabLink } from "./SwitchTabLink";

const getAllOccurenceIndexes = (searchTerm: string, valueToSearch: string) => {
  const regex = new RegExp(searchTerm, "g");
  return Array.from(valueToSearch.matchAll(regex), (match) => match.index);
};

const defaultSnippetTitle = "Snippet";

type State = {
  isCreatingCallSnippet: boolean;
  callSnippetStartTranscriptSegmentId?: string;
  callSnippetEndTranscriptSegmentId?: string;
  mouseOverIndex?: number;
  searchValue: string;
  searchIndex?: number;
  createdCallRecordingSnippetId?: string;
  createdCallRecordingSnippet: LoadedValue<CallRecordingSnippet>;
  snippetTitle: string;
  isSearchFocused: boolean;
};

const convertTranscriptSegmentsToText = (transcriptSegments: QueryDocumentSnapshot<TranscriptSegment>[]) =>
  transcriptSegments
    .map(
      unwrap((transcriptSegment) => {
        const { speaker, startMs, text } = transcriptSegment;
        return `[${formatMilliseconds(startMs)}] ${speaker}:\n${text}`;
      }),
    )
    .join("\n\n");

export const TranscriptTab = () => {
  const { transcriptSegments, callRecording, callRecordingId, title } = useCallRecording();
  const shouldCreateSnippet = !!useQueryParams().shouldCreateSnippet;
  const { authUser } = useBase();
  const userId = authUser.value?.uid;
  const { getCopyProps } = useGetCopyProps();
  const isAuthenticated = !!authUser.value;
  const [state, updateState] = useUpdateState<State>({
    isCreatingCallSnippet: shouldCreateSnippet,
    searchValue: "",
    createdCallRecordingSnippet: { value: undefined, isLoading: false },
    snippetTitle: defaultSnippetTitle,
    isSearchFocused: false,
  });
  const transcriptSegmentIds = transcriptSegments.map((transcriptSegment) => transcriptSegment.id);

  const cancelSnippetCreation = useCallback(() => {
    updateState({
      isCreatingCallSnippet: false,
      callSnippetEndTranscriptSegmentId: undefined,
      callSnippetStartTranscriptSegmentId: undefined,
      mouseOverIndex: undefined,
    });
  }, [updateState]);

  const startIndex = useMemo(
    () =>
      state.callSnippetStartTranscriptSegmentId
        ? transcriptSegmentIds.indexOf(state.callSnippetStartTranscriptSegmentId)
        : undefined,
    [state.callSnippetStartTranscriptSegmentId, transcriptSegmentIds],
  );

  const endIndex = useMemo(
    () =>
      state.callSnippetEndTranscriptSegmentId
        ? transcriptSegmentIds.indexOf(state.callSnippetEndTranscriptSegmentId)
        : state.mouseOverIndex
          ? state.mouseOverIndex
          : undefined,
    [state.callSnippetEndTranscriptSegmentId, state.mouseOverIndex, transcriptSegmentIds],
  );

  const transcriptRefs = useRef(transcriptSegments.map(() => createRef()));

  const scrollToIndex = useCallback((index: number) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (transcriptRefs.current[index].current as any)?.scrollIntoView({ behavior: "smooth", block: "center" });
  }, []);

  const matchedTranscriptSegmentIds = useMemo(() => {
    if (state.searchValue.length < 3) return [];
    const searchValue = state.searchValue.toLowerCase();
    return transcriptSegments
      .filter((transcriptSegment) => transcriptSegment.data().text.toLowerCase().includes(searchValue))
      .map((transcriptSegment) => transcriptSegment.id);
  }, [state.searchValue, transcriptSegments]);

  useEffect(() => {
    if (state.searchIndex === undefined) return;
    const index = transcriptSegmentIds.indexOf(matchedTranscriptSegmentIds[state.searchIndex]);
    if (index === -1) return;
    scrollToIndex(index);
  }, [state.searchIndex, scrollToIndex, matchedTranscriptSegmentIds, transcriptSegmentIds]);

  useEffect(() => {
    if (state.searchValue.length < 3) return updateState({ searchIndex: undefined });
    if (state.searchIndex !== undefined) return;
    updateState({ searchIndex: 0 });
  }, [state.searchValue, state.searchIndex, updateState]);

  const createSnippet = useMutation({
    mutationFn: async (title: string) => {
      const { callSnippetStartTranscriptSegmentId, callSnippetEndTranscriptSegmentId } = state;
      if (!callSnippetStartTranscriptSegmentId || !callSnippetEndTranscriptSegmentId || !userId) return;
      const startMs = transcriptSegments
        .find((transcriptSegment) => transcriptSegment.id === callSnippetStartTranscriptSegmentId)
        ?.data().startMs;
      const endMs = transcriptSegments
        .find((transcriptSegment) => transcriptSegment.id === callSnippetEndTranscriptSegmentId)
        ?.data().endMs;

      if (startMs === undefined || endMs === undefined || endMs < startMs) return;

      const { organisationId, accessSetting, emailsWithAccess } = callRecording;
      const callRecordingSnippetId = uuid();
      const storagePath = constructCallRecordingSnippetPath({ organisationId, callRecordingSnippetId });
      const callRecordingSnippet: CallRecordingSnippet = {
        startMs,
        endMs,
        title,
        createdAt: new Date(),
        organisationId,
        userId,
        callRecordingId,
        progress: 0,
        storagePath,
        accessSetting,
        emailsWithAccess,
      };

      await setDoc(doc(Collection.CallRecordingSnippet, callRecordingSnippetId), callRecordingSnippet);

      return callRecordingSnippetId;
    },
    onSuccess: (callRecordingSnippetId) => {
      updateState({ createdCallRecordingSnippetId: callRecordingSnippetId });
    },
    onError: (error) => console.error(error),
  });

  useEffect(() => {
    updateState({
      createdCallRecordingSnippet: { value: undefined, isLoading: !!state.createdCallRecordingSnippetId },
    });

    if (!state.createdCallRecordingSnippetId) return;

    const unsub = onSnapshot(doc(Collection.CallRecordingSnippet, state.createdCallRecordingSnippetId), (doc) => {
      const callRecordingSnippet = doc.data();
      updateState({ createdCallRecordingSnippet: { value: callRecordingSnippet, isLoading: false } });
    });
    return () => {
      unsub();
    };
  }, [state.createdCallRecordingSnippetId, updateState]);

  const isSearching = state.isSearchFocused || state.searchValue.length > 0;

  return (
    <>
      <div className="h-full">
        {transcriptSegments.map((transcriptSegment, index) => {
          const { startMs, speaker, text } = transcriptSegment.data();
          const isMatched = matchedTranscriptSegmentIds.includes(transcriptSegment.id);
          return (
            <div
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              ref={transcriptRefs.current[index] as any}
              className={cn(
                "items-top flex gap-x-4 py-2",
                state.isCreatingCallSnippet &&
                  !(state.callSnippetStartTranscriptSegmentId && state.callSnippetEndTranscriptSegmentId) &&
                  "cursor-pointer hover:bg-purple-100",
                startIndex !== undefined &&
                  endIndex !== undefined &&
                  index >= startIndex &&
                  index <= endIndex &&
                  "bg-purple-100",
                startIndex === index && "bg-purple-100",
                index === transcriptSegments.length - 1 && "pb-24",
              )}
              key={transcriptSegment.id}
              onClick={
                state.isCreatingCallSnippet &&
                !(state.callSnippetStartTranscriptSegmentId && state.callSnippetEndTranscriptSegmentId)
                  ? () => {
                      if (!state.callSnippetStartTranscriptSegmentId)
                        return updateState({ callSnippetStartTranscriptSegmentId: transcriptSegment.id });

                      const startIndex = transcriptSegmentIds.indexOf(state.callSnippetStartTranscriptSegmentId);
                      if (index < startIndex) return;

                      if (!state.callSnippetEndTranscriptSegmentId)
                        return updateState({ callSnippetEndTranscriptSegmentId: transcriptSegment.id });
                    }
                  : undefined
              }
              onMouseEnter={
                state.isCreatingCallSnippet &&
                state.callSnippetStartTranscriptSegmentId &&
                !state.callSnippetEndTranscriptSegmentId
                  ? () => {
                      updateState({ mouseOverIndex: index });
                    }
                  : undefined
              }
            >
              <div className="w-32 flex-shrink-0 flex-grow-0">
                <div className="flex justify-end">
                  <span
                    className="box-border inline-block overflow-hidden text-ellipsis whitespace-nowrap rounded-lg bg-slate-100 px-2 py-0.5 text-sm"
                    style={{ marginTop: 1 }}
                  >
                    {speaker}
                  </span>
                </div>
              </div>
              <p className="flex-shrink flex-grow">
                {isMatched
                  ? getAllOccurenceIndexes(state.searchValue.toLowerCase(), text.toLowerCase()).reduce<
                      (string | JSX.Element)[]
                    >((acc, occurenceIndex, index, arr) => {
                      const startIndex = index === 0 ? 0 : arr[index - 1] + state.searchValue.length;
                      const endIndex = occurenceIndex;
                      const isLast = index === arr.length - 1;

                      return acc.concat(
                        text.slice(startIndex, endIndex),
                        <span
                          className={cn(
                            matchedTranscriptSegmentIds.indexOf(transcriptSegment.id) === state.searchIndex
                              ? "bg-purple-500 text-white"
                              : "bg-purple-200",
                          )}
                        >
                          {text.slice(occurenceIndex, occurenceIndex + state.searchValue.length)}
                        </span>,
                        ...(isLast ? [text.slice(occurenceIndex + state.searchValue.length)] : []),
                      );
                    }, [])
                  : text}
              </p>
              <div className="w-10 flex-shrink-0 flex-grow-0">
                <p
                  className="overflow-hidden text-ellipsis whitespace-nowrap py-1 text-xs text-slate-500"
                  style={{ fontFamily: "DM Mono" }}
                >
                  {formatMilliseconds(startMs)}
                </p>
              </div>
            </div>
          );
        })}
      </div>
      <div className="absolute bottom-0 left-0 flex items-center gap-x-2 rounded-t-2xl border border-b-0 border-black bg-white p-2">
        {state.isCreatingCallSnippet ? (
          state.createdCallRecordingSnippet.value?.progress === 1 ? (
            <SwitchTabLink tab={CallRecordingTabName.RECORDING}>
              <Button>View snippet</Button>
            </SwitchTabLink>
          ) : createSnippet.isPending || state.createdCallRecordingSnippetId ? (
            <div className="flex items-center gap-x-2">
              <p className="text-sm text-slate-500">Creating snippet...</p>
              <Progress
                className="w-20"
                value={
                  createSnippet.isPending
                    ? 5
                    : !state.createdCallRecordingSnippetId
                      ? 10
                      : 15 + (state.createdCallRecordingSnippet.value?.progress ?? 0) * 85
                }
              />
            </div>
          ) : (
            <>
              {state.callSnippetStartTranscriptSegmentId && state.callSnippetEndTranscriptSegmentId ? (
                <>
                  <Input
                    placeholder="Title"
                    className="border-0 bg-slate-100"
                    value={state.snippetTitle}
                    onChange={(event) => updateState({ snippetTitle: event.target.value })}
                  />
                  <Button
                    className="flex-shrink-0"
                    onClick={() => {
                      createSnippet.mutate(state.snippetTitle);
                      updateState({ snippetTitle: defaultSnippetTitle });
                    }}
                  >
                    Create
                  </Button>
                </>
              ) : (
                <p className="text-sm text-slate-500">
                  Click on where the snippet should <b>{state.callSnippetStartTranscriptSegmentId ? "end" : "start"}</b>
                  .
                </p>
              )}

              <Button variant="secondary" onClick={cancelSnippetCreation}>
                Cancel
              </Button>
            </>
          )
        ) : // eslint-disable-next-line no-constant-condition
        isSearching || !isAuthenticated || true ? null : (
          <Button onClick={() => updateState({ isCreatingCallSnippet: true, searchValue: "" })}>
            Create call snippet
          </Button>
        )}
        {state.isCreatingCallSnippet ? null : (
          <Input
            placeholder="Search..."
            className="w-40 flex-shrink flex-grow border-0 bg-slate-100"
            value={state.searchValue}
            onChange={(event) => updateState({ searchValue: event.target.value })}
            onFocus={() => updateState({ isSearchFocused: true })}
            onBlur={() => updateState({ isSearchFocused: false })}
          />
        )}
        {isSearching ? (
          <div className="flex items-center">
            <p className="ml-2 mr-4 flex-shrink-0 flex-grow-0 text-sm text-slate-500">
              {state.searchIndex !== undefined
                ? Math.min(state.searchIndex + 1, matchedTranscriptSegmentIds.length)
                : 0}{" "}
              / {matchedTranscriptSegmentIds.length}
            </p>
            <Button
              size="icon"
              variant="ghost"
              disabled={state.searchIndex === 0 || state.searchIndex === undefined}
              onClick={() => {
                if (state.searchIndex! <= 0) return scrollToIndex(state.searchIndex!);
                const newIndex = state.searchIndex! - 1;
                updateState({ searchIndex: newIndex });
              }}
            >
              <ArrowUp className="stroke-slate-500" size={16} />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              disabled={
                state.searchIndex === matchedTranscriptSegmentIds.length - 1 || matchedTranscriptSegmentIds.length === 0
              }
              onClick={() => {
                if (state.searchIndex! >= matchedTranscriptSegmentIds.length - 1)
                  return scrollToIndex(state.searchIndex!);
                const newIndex = state.searchIndex! + 1;
                updateState({ searchIndex: newIndex });
              }}
            >
              <ArrowDown className="stroke-slate-500" size={16} />
            </Button>
            <Button
              size="icon"
              variant="ghost"
              onClick={() => updateState({ searchValue: "", isSearchFocused: false })}
            >
              <X className="stroke-slate-500" size={16} />
            </Button>
          </div>
        ) : null}
        {!state.isCreatingCallSnippet && !isSearching ? (
          <DropdownMenu>
            <DropdownMenuTrigger asChild>
              <Button variant="secondary" size="icon">
                <MoreHorizontal size={24} />
              </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent>
              <DropdownMenuItem
                className="flex items-center gap-x-2"
                {...getCopyProps({
                  value: convertTranscriptSegmentsToText(transcriptSegments),
                })}
              >
                <Copy size={16} />
                <span>Copy transcript</span>
              </DropdownMenuItem>
              <DropdownMenuItem
                className="flex items-center gap-x-2"
                onClick={() =>
                  downloadFile({
                    content: convertTranscriptSegmentsToText(transcriptSegments),
                    baseName: `Transcript of ${title}`,
                  })
                }
              >
                <Download size={16} />
                <span>Download transcript</span>
              </DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        ) : null}
      </div>
    </>
  );
};
