import { DocumentName, Environment, getDocument, getDocumentVersion } from "@fyxer-ai/shared";
// eslint-disable-next-line import/no-named-as-default
import { PostHog, usePostHog } from "posthog-js/react";
import { useCallback, useEffect } from "react";

import { getEnvironment } from "@/lib/getEnvironment";
import { ua } from "@/lib/userAgent";

export enum EventName {
  VIEW_PAGE = "VIEW_PAGE",
  CLICK_BUTTON = "CLICK_BUTTON",
  SUBMIT_FORM = "SUBMIT_FORM",
  CONSUME_EMAIL_LINK = "CONSUME_EMAIL_LINK",
  ACCEPT_INVITE = "ACCEPT_INVITES",
  ACCEPT_ORGANISATION_INVITE = "ACCEPT_ORGANISATION_INVITE",
  COMPLETE_OAUTH_CONNECTION = "COMPLETE_OAUTH_CONNECTION",
  MAKE_API_CALL = "MAKE_API_CALL",
  SIGN_DOCUMENT = "SIGN_DOCUMENT",
  SIGN_UP = "SIGN_UP",
  CALENDAR_SLOT_RATING = "CALENDAR_SLOT_RATING",
  MEETING_SUMMARY_RATING = "MEETING_SUMMARY_RATING",
}

export enum EventPage {
  HOME = "HOME",
  SIGN_UP = "SIGN_UP",
  CREATE_SUBSCRIPTION = "CREATE_SUBSCRIPTION",
  LOG_IN = "LOG_IN",
  EMAIL_LINK_SENT = "EMAIL_LINK_SENT",
  CREATE_ORGANISATION = "CREATE_ORGANISATION",
  LIST_ORGANISATIONS = "LIST_ORGANISATIONS",
  SETTINGS = "SETTINGS",
  EMAIL_LINK = "EMAIL_LINK",
  ACCEPT_INVITE = "ACCEPT_INVITE",
  ACCEPT_ORGANISATION_INVITE = "ACCEPT_ORGANISATION_INVITE",
  COMPLETE_PROFILE = "COMPLETE_PROFILE",
  PROFILE = "PROFILE",
  OAUTH = "OAUTH",
  OAUTH_PROVIDER = "OAUTH_PROVIDER",
  ERROR = "ERROR",
  CHECKOUT_SESSION = "CHECKOUT_SESSION",
  PRICING = "PRICING",
  MEETING_ASSISTANT = "MEETING_ASSISTANT",
  NO_EMAIL_CONNECTION = "NO_EMAIL_CONNECTION",
  NO_CALENDAR_CONNECTION = "NO_CALENDAR_CONNECTION",
  ONBOARDING = "ONBOARDING",
  NO_ZOOM_CONNECTION = "NO_ZOOM_CONNECTION",
  ADD_TEAM = "ADD_TEAM",
  UNSUPPORTED_BROWSER = "UNSUPPORTED_BROWSER",
}

export enum EventForm {
  EMAIL_LINK_CONFIRMATION = "EMAIL_LINK_CONFIRMATION",
  CREATE_ORGANISATION = "CREATE_ORGANISATION",
  UPDATE_ORGANISATION = "UPDATE_ORGANISATION",
  COMPLETE_PROFILE = "COMPLETE_PROFILE",
  UPDATE_PROFILE = "UPDATE_PROFILE",
  UPDATE_CONFIGURATION = "UPDATE_CONFIGURATION",
  START_SUBSCRIPTION = "START_SUBSCRIPTION",
  EDIT_SUBSCRIPTION = "EDIT_SUBSCRIPTION",
  CHANGE_MEMBER_ROLE = "CHANGE_MEMBER_ROLE",
  SEND_INVITES = "SEND_INVITES",
  CALENDAR_SLOT_FEEDBACK = "CALENDAR_SLOT_FEEDBACK",
  MEETING_SUMMARY_FEEDBACK = "MEETING_SUMMARY_FEEDBACK",
}

export enum EventButton {
  DELETE_ACCOUNT = "DELETE_ACCOUNT",
  DELETE_MEMBER = "DELETE_MEMBER",
  DELETE_INVITE = "DELETE_INVITE",
  CONNECT_INTEGRATION = "CONNECT_INTEGRATION",
  CANCEL_SUBSCRIPTION = "CANCEL_SUBSCRIPTION",
  DELETE_CONNECTION = "DELETE_CONNECTION",
  CONTINUE_FROM_CHECKOUT_SESSION = "CONTINUE_FROM_CHECKOUT_SESSION",
  COMPLETE_PROFILE = "COMPLETE_PROFILE",
  GO_TO_STRIPE = "GO_TO_STRIPE",
  PRICING_BACK = "PRICING_BACK",
  SELECT_PLAN = "SELECT_PLAN",
  START_AUTH_PROCESS = "START_AUTH_PROCESS",
  SELECT_INTEGRATION = "SELECT_INTEGRATION",
  CONFIRM_INTEGRATION = "CONFIRM_INTEGRATION",
  START_SUBSCRIPTION = "START_SUBSCRIPTION",
  UPGRADE = "UPGRADE",
  REQUEST_SAML_SSO = "REQUEST_SAML_SSO",
  UPGRADE_TO_PRO = "UPGRADE_TO_PRO",
}

type Properties = Record<string, unknown>;

export const useAnalytics = () => {
  const posthog = usePostHog() as PostHog | undefined;

  const logEvent = useCallback(
    (name: EventName, properties: Properties = {}) => {
      if (getEnvironment() !== Environment.PROD) return;
      const browserName = ua.browser.name ?? "NONE";
      const isWebView = ["Instagram", "Facebook", "LinkedIn"].includes(browserName);
      if (isWebView) return; // we force users out of webviews into another browser so counting these would be double counting
      const url = window.location.href;
      posthog?.capture(name, { ...properties, source: "frontend", url });
    },
    [posthog],
  );

  const logPageView = useCallback(
    (page: EventPage, properties?: Properties) => {
      logEvent(EventName.VIEW_PAGE, {
        page,
        ...(properties ?? {}),
      });
    },
    [logEvent],
  );

  const logDocumentSign = useCallback(
    (userId: string, documentName: DocumentName) => {
      const { lastUpdatedAt } = getDocument(documentName);
      const version = getDocumentVersion(lastUpdatedAt);
      logEvent(EventName.SIGN_DOCUMENT, {
        documentName,
        lastUpdatedAt,
        version,
        userId,
      });
    },
    [logEvent],
  );

  const logButtonPress = useCallback(
    (button: EventButton, properties?: Properties) => {
      logEvent(EventName.CLICK_BUTTON, {
        button,
        ...properties,
      });
    },
    [logEvent],
  );

  const logFormSubmit = useCallback(
    (form: EventForm, properties?: Properties) => {
      logEvent(EventName.SUBMIT_FORM, {
        form,
        ...properties,
      });
    },
    [logEvent],
  );

  const identify = useCallback(
    (userId?: string) => {
      posthog?.identify(userId);
    },
    [posthog],
  );

  return {
    logEvent,
    logPageView,
    logDocumentSign,
    logButtonPress,
    logFormSubmit,
    identify,
  };
};

export const useLogPageView = (page: EventPage, properties: Properties = {}) => {
  const { logPageView } = useAnalytics();

  useEffect(() => {
    logPageView(page, properties);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
