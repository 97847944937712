// basename is filename without extension

export const downloadFile = ({
  content,
  baseName,
  extensionName = "text",
}: {
  content: string;
  baseName: string;
  extensionName?: "text";
}) => {
  const blob = new Blob([content], { type: "text/plain" });
  const fileName = `${baseName}.${extensionName}`;
  const url = URL.createObjectURL(blob);
  const link = document.createElement("a"); // Create a new anchor element
  link.href = url; // Set the href to the Blob URL
  link.download = fileName; // Set the desired file name
  document.body.appendChild(link); // Append the link to the body (required for Firefox)
  link.click(); // Programmatically click the link to trigger the download
  document.body.removeChild(link);
};
