import React, { ReactNode } from "react";

import { Button } from "../ui/button";

export const TitleBody = (props: {
  title: string;
  titleSize?: "h1" | "h2" | "h3";
  bodyLines: string[];
  topItems?: ReactNode;
  actions?: {
    variant?: "default" | "secondary";
    title: string;
    fn: () => void | Promise<void>;
  }[];
}) => {
  return (
    <div className="space-y-8">
      {props.topItems}
      {React.createElement(props.titleSize ?? "h2", null, props.title)}
      <div className="space-y-2">
        {props.bodyLines.map((line, index) => (
          <p key={index} className="text-slate-500">
            {line}
          </p>
        ))}
      </div>
      {!!props.actions && props.actions.length > 0 && (
        <div className="space-y-2">
          {props.actions.map((action) => (
            <Button variant={action.variant ?? "default"} key={action.title} onClick={action.fn} className="w-full">
              {action.title}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};
