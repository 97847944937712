import { useCallback, useState } from "react";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useUpdateState = <T extends { [key: string]: any }>(initialState: T) => {
  const [state, setState] = useState(initialState);

  const updateState = useCallback((update: Partial<T>) => setState((prevState) => ({ ...prevState, ...update })), []);

  return [state, updateState] as const;
};
