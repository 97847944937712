import { constructUrl, OauthIntegration } from "@fyxer-ai/shared";
import { toTextCase } from "js-convert-case";
import { Loader2 } from "lucide-react";
import { Link, useParams } from "react-router-dom";

import { Button } from "@/components/ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from "@/components/ui/dialog";
import { Tooltip, TooltipContent, TooltipTrigger } from "@/components/ui/tooltip";
import { EventButton, useAnalytics } from "@/hooks/useAnalytics";

import { IntegrationDatum } from "./integrationData";
import { ServiceTypeDatum } from "./serviceTypeData";

export const ConnectIntegrationForFirstTimeRow = ({
  integrationDatum: integrationDatum,
  serviceTypeDatum: serviceTypeDatum,
  hasNoPlan,
  handleRedirect,
  redirectingIntegration,
}: {
  serviceTypeDatum: ServiceTypeDatum;
  integrationDatum: IntegrationDatum;
  hasNoPlan: boolean;
  handleRedirect: () => Promise<void>;
  redirectingIntegration: OauthIntegration | undefined;
}) => {
  const organisationId = useParams().organisationId as string;
  const { logButtonPress } = useAnalytics();
  const { title, logoUrl, disclosure, integration } = integrationDatum;
  const { serviceType, detail } = serviceTypeDatum;
  const isLoading = redirectingIntegration === integration;

  if (hasNoPlan)
    return (
      <Tooltip delayDuration={0}>
        <TooltipTrigger asChild>
          <div className="space-y-2">
            <img src={logoUrl} alt={title} className="h-16 w-16 cursor-pointer rounded-lg opacity-70 grayscale" />
            <p className="text-center text-sm text-gray-500">{title}</p>
          </div>
        </TooltipTrigger>
        <TooltipContent>
          <p className="max-w-64">
            You need to{" "}
            <Link
              className="font-bold text-purple-600"
              to={constructUrl({
                path: `/org/${organisationId}/settings`,
                hash: { tab: "billing" },
              })}
            >
              subscribe
            </Link>{" "}
            to connect this.
          </p>
        </TooltipContent>
      </Tooltip>
    );

  return (
    <Dialog key={integration}>
      <DialogTrigger
        asChild
        onClick={() =>
          logButtonPress(EventButton.SELECT_INTEGRATION, { integration, serviceType, isInitialConnection: true })
        }
      >
        <div className="space-y-2">
          <img src={logoUrl} alt={title} className="h-16 w-16 cursor-pointer rounded-lg hover:opacity-70" />
          <p className="text-center text-sm text-gray-500">{title}</p>
        </div>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>How the {toTextCase(serviceType)} integration works</DialogTitle>
        </DialogHeader>
        <div className="text-sm">{detail()}</div>
        <Button
          disabled={isLoading}
          onClick={() => {
            logButtonPress(EventButton.CONFIRM_INTEGRATION, { integration, serviceType });
            handleRedirect();
          }}
        >
          {isLoading ? <Loader2 className="animate-spin" color="white" /> : "Connect"}
        </Button>
        {disclosure?.()}
      </DialogContent>
    </Dialog>
  );
};
