import { useBase } from "./useBase";

export const useMemberships = () => {
  const { value: memberships, isLoading } = useBase().memberships;

  if (isLoading) throw new Error("memberships are still loading");
  if (memberships === undefined) throw new Error("memberships are missing");

  return { memberships };
};
