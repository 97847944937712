"use client";

import { isNull } from "@fyxer-ai/shared";
import { zodResolver } from "@hookform/resolvers/zod";
import { ReactNode } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { z, ZodTypeAny } from "zod";

import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { useToast } from "@/components/ui/use-toast";
import { useSubmitProps } from "@/hooks/useClickProps";
import { cn } from "@/lib/utils";

export interface FormUtilProps<Schema extends ZodTypeAny> {
  schema: Schema;
  className?: string;
  defaultValues: z.infer<Schema>;
  onSubmit: (data: z.infer<Schema>) => Promise<void> | void;
  render: (form: UseFormReturn<z.TypeOf<Schema>, unknown, undefined>) => ReactNode;
  isLoading?: boolean;
  submitTitle?: string;
  successMessage?: string;
  errorMessage?: (error: unknown) => string;
  renderSubmitButton?: (submitProps: { type: "submit"; children: JSX.Element; disabled: boolean }) => ReactNode;
  disabled?: boolean;
}

export const FormUtil = <Schema extends ZodTypeAny>({
  schema,
  defaultValues,
  onSubmit,
  render,
  className = "",
  submitTitle = "Submit",
  errorMessage = () => "An error occured",
  renderSubmitButton,
  successMessage,
  isLoading = false,
  disabled = false,
}: FormUtilProps<Schema>) => {
  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  });
  const submitProps = useSubmitProps();

  const { toast } = useToast();

  const onSubmitWrapper = async (data: z.infer<Schema>) => {
    try {
      await onSubmit(data);

      if (isNull(successMessage)) return;

      toast({
        description: successMessage,
      });
    } catch (err) {
      console.error(err);
      toast({
        variant: "destructive",
        description: errorMessage(err),
      });
    }
  };

  const buttonProps = {
    ...submitProps({
      isLoading: isLoading || form.formState.isSubmitting,
      buttonText: submitTitle,
    }),
    disabled,
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmitWrapper)} className={cn("max-w-[540px] space-y-4", className)}>
        {render(form)}
        {renderSubmitButton?.(buttonProps) ?? <Button {...buttonProps} className="w-full" />}
      </form>
    </Form>
  );
};
