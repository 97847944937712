import { forwardRef } from "react";

import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/components/ui/breadcrumb";

export const BreadcrumbUtil = forwardRef<
  HTMLElement,
  React.ComponentPropsWithoutRef<"nav"> & {
    separator?: React.ReactNode;
    currentPageTitle: string;
    parentPages: { title: string; href: string }[];
  }
>(({ currentPageTitle, parentPages, ...props }, ref) => (
  <Breadcrumb {...props} ref={ref}>
    <BreadcrumbList>
      {parentPages.map(({ href, title }) => (
        <>
          <BreadcrumbItem>
            <BreadcrumbLink href={href}>{title}</BreadcrumbLink>
          </BreadcrumbItem>
          <BreadcrumbSeparator />
        </>
      ))}
      <BreadcrumbItem>
        <BreadcrumbPage>{currentPageTitle}</BreadcrumbPage>
      </BreadcrumbItem>
    </BreadcrumbList>
  </Breadcrumb>
));
