import { constructReferralLink } from "@fyxer-ai/shared";

import { Container } from "@/components/layout/Container";
import { RecentReferralsView, ReferralTermsView } from "@/components/layout/TopBar";
import { Separator } from "@/components/ui/separator";
import { useUser } from "@/context/BaseContext/state/useUser";

export const RewardsPage = () => {
  const { user } = useUser();
  const { slug } = user;
  const referralLink = slug ? constructReferralLink(slug) : undefined;

  return (
    <Container className="space-y-8">
      <h1>Rewards</h1>
      <ReferralTermsView referralLink={referralLink} />
      <Separator className="bg-slate-200" />
      <RecentReferralsView {...{ referralLink }} />
    </Container>
  );
};
