import { OauthServiceType } from "@fyxer-ai/shared";

import { useRemoteConfig } from "@/context/BaseContext/state/useRemoteConfig";
import { useOrganisation } from "@/context/OrganisationContext/state/useOrganisation";

import { ConnectionFaqsSection } from "./ConnectionFaqsSection";
import { serviceTypeData } from "./serviceTypeData";
import { ServiceTypeRow } from "./ServiceTypeRow";
import { ZoomIntegrationsSection } from "./ZoomIntegrationsSection";

export const IntegrationsTab = () => {
  const { organisationId } = useOrganisation();
  const { organisationIdsWhereInstantMessagingIsEnabled } = useRemoteConfig();
  const isInstantMessagingEnabled = organisationIdsWhereInstantMessagingIsEnabled?.includes(organisationId) ?? false;

  return (
    <div className="space-y-12">
      <div className="space-y-4">
        {serviceTypeData
          .filter((datum) => {
            if (datum.serviceType === OauthServiceType.INSTANT_MESSAGING) return isInstantMessagingEnabled;
            return true;
          })
          .map(ServiceTypeRow)}
        <ZoomIntegrationsSection />
      </div>

      <ConnectionFaqsSection />
    </div>
  );
};
