import { Link } from "react-router-dom";

import jasonNvidia from "@/assets/people/jason-nvidia.png";
import { Logo } from "@/components/layout/TopBar";
import { config } from "@/lib/config";

import { AuthForm } from "./AuthForm";
import { AuthType } from "./types/AuthType";

export const AuthPageShell = ({ authType }: { authType: AuthType }) => {
  return (
    <div className="flex h-screen flex-col">
      <div className="border border-slate-100 bg-white px-4 py-2">
        <Link to={config().MARKETING_URL} target="_blank" rel="noreferrer">
          <Logo />
        </Link>
      </div>

      <div className="flex-grow max-sm:mt-2 max-sm:p-4 sm:flex sm:items-center sm:justify-center">
        <div className="space-y-12">
          <AuthForm authType={authType} />
          <div className="max-w-md space-y-2 rounded-3xl border border-black bg-slate-50 px-8 py-6">
            <img className="m-auto h-8 w-8 rounded-full" src={jasonNvidia} />
            <p className="text-center text-xl font-bold">
              ”Fyxer.ai is a masterpiece. They’ve built an incredibly useful product that just might fix email forever.”
            </p>
            <p className="text-center">Jason La Barbera</p>
            <p className="text-center">AI Ambassador @ NVIDIA</p>
          </div>
        </div>
      </div>
    </div>
  );
};
